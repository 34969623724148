<div id="error-view">
  <h2 class="shopping-cart-customerinfo-headline h2 bold">
    {{ 'error.header' | i18next }}
  </h2>
  <div class="shopping-cart-customerinfo-stage" id="webshop-cart-error-info">
    <div class="shopping-cart-customerinfo-stage-left">
      <app-icon
        name="errorCart"
        class="shopping-cart-customerinfo-stage-img"
      ></app-icon>
    </div>

    <div class="shopping-cart-customerinfo-stage-right">
      <h3 class="h3 shopping-cart-customerinfo-stage-caption">
        {{ 'error.tryLater' | i18next }}
      </h3>

      <div
        *ngIf="errorState == stateEnum.loadconfig"
        class="shopping-cart-customerinfo-stage-description"
      >
        {{ 'error.loadConfig' | i18next }}
      </div>

      <div
        *ngIf="errorState == stateEnum.configuration"
        class="shopping-cart-customerinfo-stage-description"
      >
        {{ 'error.configuration' | i18next }}
      </div>

      <div
        *ngIf="errorState == stateEnum.leadcreation"
        class="shopping-cart-customerinfo-stage-description"
      >
        {{ 'error.leadCreation' | i18next }}
      </div>

      <div
        *ngIf="errorState == stateEnum.channelpartners"
        class="shopping-cart-customerinfo-stage-description"
      >
        {{ 'error.channelpartners' | i18next }}
      </div>

      <div
        *ngIf="errorState == stateEnum.review"
        class="shopping-cart-customerinfo-stage-description"
      >
        {{ 'error.review' | i18next }}
      </div>

      <h5
        *ngIf="
          errorState != stateEnum.configuration &&
          errorState != stateEnum.loadconfig
        "
        class="h5 shopping-cart-customerinfo-stage-caption"
      >
        {{ configurationId }}
      </h5>

      <div class="shopping-cart-customerinfo-stage-button">
        <a class="button-secondary" (click)="close()">{{
          'general.close' | i18next
        }}</a>
      </div>
    </div>
  </div>
</div>
