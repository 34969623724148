import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  constructor() {}

  @Input() items = null;
  @ViewChild('tooltipWrapper') tooltipWrapper: ElementRef;

  public tooltipValue;
  public arrowStyleLeft = '50px';
  private smDown = window.matchMedia('(max-width: 768px)');

  setTooltipValue() {
    if (this.items !== null) {
      if (!Array.isArray(this.items)) this.items = [this.items];
      this.tooltipValue = this.items[0];
    }
  }

  tooltipSelect(event) {
    this.tooltipValue = event.target.title;
  }

  setArrow(event) {
    if (this.smDown.matches) {
      this.arrowStyleLeft =
        event.target.parentNode.getBoundingClientRect().left +
        22 -
        this.tooltipWrapper.nativeElement.getBoundingClientRect().left +
        'px';
    } else {
      this.arrowStyleLeft = '50px';
    }
  }

  ngOnInit(): void {
    this.setTooltipValue();
  }
}
