<div *ngIf="conflict">
  <app-conflict-notification
    [add]="true"
    [remove]="false"
    [widget]="widget"
  ></app-conflict-notification>
</div>
<div
  *ngIf="!widget.layout || widget.layout.type !== 'Grid'"
  class="module module-product-list-table module-single-product-selector"
  style="margin: 5px"
>
  <div
    [class]="
      widget.isExpanded === 1
        ? 'module module-accordion initial-active no-padding active'
        : 'module module-accordion initial-active no-padding'
    "
    data-view="accordion-item"
  >
    <div
      class="title"
      [id]="widget.isExpanded === 1 ? 'expand' : 'reduce'"
      (click)="expandOrCollapse($event)"
      style="display: flex; justify-content: space-between"
      [style.color]="!widget.isValid ? 'red' : ''"
    >
      <div class="heading">
        {{ widget.variable.naming }}
      </div>

      <div class="icon-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7.002"
          height="13.001"
          viewBox="0 0 7.002 13.001"
          [ngStyle]="accordionOpen == true && { transform: 'rotate(-90deg)' }"
        >
          <path
            id="chevron-right"
            d="M4.646,1.646a.5.5,0,0,1,.708,0l6,6a.5.5,0,0,1,0,.708l-6,6a.5.5,0,1,1-.708-.708L10.293,8,4.646,2.354a.5.5,0,0,1,0-.708Z"
            transform="translate(-4.499 -1.499)"
            fill="#009BEA"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="content">
      <app-cui-widget
        [widget]="widget"
        *ngFor="let widget of widget.widgets; let i = index"
      ></app-cui-widget>
    </div>
  </div>
</div>

<div
  *ngIf="widget.layout && widget.layout.type === 'Grid'"
  class="module module-product-list-table module-single-product-selector"
  style="margin: 5px"
>
  <div
    [class]="
      widget.isExpanded === 1
        ? 'module module-accordion initial-active no-padding active'
        : 'module module-accordion initial-active no-padding'
    "
    data-view="accordion-item"
  >
    <div
      class="title"
      [id]="widget.isExpanded === 1 ? 'expand' : 'reduce'"
      (click)="expandOrCollapse($event)"
      style="display: flex; justify-content: space-between"
      [style.color]="!widget.isValid ? 'red' : ''"
    >
      <div class="heading">
        {{ widget.variable.naming }}
      </div>

      <div class="icon-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7.002"
          height="13.001"
          viewBox="0 0 7.002 13.001"
          [ngStyle]="accordionOpen == true && { transform: 'rotate(-90deg)' }"
        >
          <path
            id="chevron-right"
            d="M4.646,1.646a.5.5,0,0,1,.708,0l6,6a.5.5,0,0,1,0,.708l-6,6a.5.5,0,1,1-.708-.708L10.293,8,4.646,2.354a.5.5,0,0,1,0-.708Z"
            transform="translate(-4.499 -1.499)"
            fill="#009BEA"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div class="content">
      <div
        class="grid-container"
        [style]="'grid-template-columns: ' + columnsAuto + ';'"
      >
        <div
          *ngFor="let widget of widget.widgets; let i = index"
          [style]="
            'grid-column-start: ' +
            widget.grid.column +
            '; ' +
            'grid-column-end: ' +
            (widget.grid.column + widget.grid.columnSpan) +
            '; ' +
            'grid-row-start: ' +
            widget.grid.row +
            '; ' +
            'grid-row-end: ' +
            (widget.grid.row + widget.grid.rowSpan) +
            ';'
          "
        >
          <app-cui-widget [widget]="widget"></app-cui-widget>
        </div>
      </div>
    </div>
  </div>
</div>
