import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { SelectNavinode } from 'src/app/store/ui/ui.actions';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';
import { UIState } from 'src/app/store/ui/ui.state';
import { CuiNaviNodesComponent } from '../cui-navi-nodes/cui-navi-nodes.component';

@Component({
  selector: 'app-cui-navi-node',
  templateUrl: './cui-navi-node.component.html',
  styleUrls: ['./cui-navi-node.component.scss']
})
export class CuiNaviNodeComponent implements OnInit, OnDestroy {
  @Input() naviNode: NaviNode;
  @Input() index: number;

  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;
  currentNaviNode: number = 0;

  @Select(UIState.widgets) widgets$: Observable<Widget[]>;
  widgets: Widget[];

  disabled = true;
  conflict = false;
  private sub: Subscription;

  constructor(
    private store: Store,
    private camosConfigurator: CamosConfiguratorComponent,
    private cuiNaviNodes: CuiNaviNodesComponent
  ) {}

  ngOnInit(): void {
    this.cuiNaviNodes.setActiveOnStart();
    this.sub = this.currentNaviNode$.subscribe((num) => {
      if (!this.naviNode.isValid && this.index != num) {
        this.conflict = true;
      }
      this.currentNaviNode = num;
    });
    // *
    this.widgets$.subscribe((_widgets) => {
      this.widgets = _widgets;
      this.disabled = true;
      let basemodel = false;
      if (this.widgets && this.widgets.length) {
        for (let i = 0; i < this.widgets.length; i++) {
          if (this.widgets[i].widgets && this.widgets[i].widgets.length) {
            for (let j = 0; j < this.widgets[i].widgets.length; j++) {
              if (this.widgets[i].widgets[j].xtIsBaseList) {
                basemodel = true;
              }
            }
          } else {
            if (this.widgets[i].xtIsBaseList) {
              basemodel = true;
            }
          }
        }
      }
      if (this.widgets && this.widgets.length > 0 && basemodel) {
        this.widgets.forEach((widget) => {
          if (widget.values && widget.values.length > 0) {
            widget.values.forEach((value) => {
              if (value.isSelected && value.name) {
                this.disabled = false;
              }
            });
          }
        });
      } else {
        this.disabled = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  select() {
    if (!this.disabled) {
      this.store.dispatch(new SelectNavinode(this.naviNode.id)).subscribe();
      this.camosConfigurator.goToTop();
      // this.cuiNaviNodes.setActiveOnStart()
    }
  }
}
