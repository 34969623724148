import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product, UIModel } from '../store/ui/ui.model';
// import { environment } from 'src/environments/environment';
// import { EnvService } from './../env.service';
import { SalesTextResponse } from 'src/dtos/sales-text-response';
import { Store } from '@ngxs/store';
import { ConfiguratorSetup, ValidationSetup } from '../store/lead/lead.model';
import { environment } from 'src/environments/environment';
import { bussinesData } from '../models/bussinesData.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CamosService {
  baseUrl: string;
  // todo maybe move this a local var in runnerStart
  apiVersion = '/camos/sessions/';
  session: string;
  public language: string;

  constructor(
    private http: HttpClient,
    // private env: EnvService,
    private store: Store
  ) {}

  async check(configuratorSetup: ConfiguratorSetup): Promise<boolean> {
    const response = await firstValueFrom(
      this.http.post<boolean>(environment.apiUrl + '/check', {
        setup: configuratorSetup
      })
    );
    return response;
  }

  async checkValidationSetup(
    validationSetup: ValidationSetup
  ): Promise<boolean> {
    const response = await firstValueFrom(
      this.http.post<boolean>(environment.apiUrl + '/check', {
        setup: validationSetup
      })
    );
    return response;
  }

  async getBaseID(alias: string): Promise<string> {
    try {
      const response = await firstValueFrom(
        this.http.get(environment.apiUrl + '/baseID/' + alias, {
          responseType: 'text'
        })
      );
      return response;
    } catch (error) {
      return '0000.0000.base';
    }
  }

  async startSession(
    knb: string,
    configCode: string,
    ver?: string,
    verproduct?: string,
    environments?: string
  ): Promise<void> {
    // throw 'error';
    // console.log('session url: ', environment.apiUrl + this.apiVersion)
    const response = await firstValueFrom(
      this.http.post(
        environment.apiUrl + this.apiVersion,
        {
          knb: knb,
          configCode: configCode,
          ver: ver,
          verproduct: verproduct,
          environment: environments
        },
        {
          observe: 'response'
        }
      )
    );
    this.session = response.body['sessionId'];
    this.baseUrl =
      environment.apiUrl + this.apiVersion + response.body['sessionId'];
    //console.log('base url: ', this.baseUrl)
    const mode = this.store.selectSnapshot((state) => state.ui);
    // console.log(mode);
    // this.adobeAnalyticsManager.trackOpenConfigurator(widget.values.filter(value => value.name != null && value.naming != "Ø")[0].name);
  }

  async startValidationSession(configCode: string): Promise<void> {
    const response = await firstValueFrom(
      this.http.post(
        environment.apiUrl + '/camos/validationSessions/',
        {
          configCode: configCode
        },
        {
          observe: 'response'
        }
      )
    );
    this.session = response.body['sessionId'];
    this.baseUrl =
      environment.apiUrl + this.apiVersion + response.body['sessionId'];
  }

  stopSession(session: string) {
    console.log(
      'session url: ',
      environment.apiUrl + this.apiVersion + session
    );
    const response = this.http.delete(
      environment.apiUrl + this.apiVersion + session
    );
    console.log(response);
    return response;
  }

  getUis(country: string, language: string): Promise<UIModel[]> {
    return firstValueFrom(
      this.http.get<UIModel[]>(
        this.baseUrl + '/uis/' + country + '/' + language
      )
    );
  }

  getProducts(language: string): Promise<Product[]> {
    if (!this.session) return;
    return firstValueFrom(
      this.http.get<Product[]>(this.baseUrl + '/' + language + '/products')
    );
  }

  getSalesText(materialIds: string[]) {
    if (!this.session) return;
    return firstValueFrom(
      this.http.post<SalesTextResponse[]>(
        this.baseUrl + '/loadSalesText',
        materialIds
      )
    );
  }

  async selectNavinode(navinodeId: number, country: string): Promise<UIModel> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.patch<UIModel>(this.baseUrl + `/navinodes/${navinodeId}`, {
        isSelected: 1,
        country: country
      })
    );
    return response;
  }

  async selectItemForWidget(
    itemName: string,
    widgetid: number,
    itemQuantity: number,
    country: string
  ): Promise<UIModel> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.patch<UIModel>(
        `${this.baseUrl}/${this.language}/widgets/select/${widgetid}`,
        {
          value: {
            name: itemName,
            quantity: itemQuantity
          },
          country: country
        }
      )
    );
    return response;
  }
  async getItemForWidget(
    itemName: string,
    widgetid: number,
    itemQuantity: number,
    country: string
  ): Promise<UIModel> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.patch<UIModel>(
        `${this.baseUrl}/${this.language}/widgets/select/getItem/${widgetid}`,
        {
          value: {
            name: itemName,
            quantity: itemQuantity
          },
          country: country
        }
      )
    );
    return response;
  }

  async expandOrCollapseWidget(
    widgetId: number,
    target: 'expand' | 'collapse',
    country: string
  ): Promise<UIModel> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.patch<UIModel>(
        `${this.baseUrl}/${this.language}/widgets/accordeons/${widgetId}`,
        {
          isExpanded: target === 'expand' ? 1 : 0,
          country: country
        }
      )
    );
    return response;
  }

  async getCountrySpecificData(country: string): Promise<bussinesData> {
    if (!this.session) return;
    const response: any = await firstValueFrom(
      this.http.get(`${environment.apiUrl}/countryData/${country}`)
    );
    return response;
  }

  async getAllSelectedProducts(uiId: number, country: string): Promise<any> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get(`${this.baseUrl}/${this.language}/selected/uis/${uiId}`, {
        params: {
          country: country
        }
      })
    );
    return response;
  }

  async saveConfiguration() {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get(`${this.baseUrl}/${this.language}/config/save`)
    );
    return response;
  }

  async loadConfiguration(configId: string) {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get(`${this.baseUrl}/${this.language}/config/load`, {
        params: {
          configUUID: configId
        }
      })
    );
    return response;
  }
  async getCamosInfo() {
    try {
      if (!this.session) return;
      const response = await firstValueFrom(
        this.http.get(`${this.baseUrl}/${this.language}/config/info`)
      );
      return response;
    } catch (error) {
      console.log('getCamosInfo error', error);
    }
  }
  async getStructure(country: string): Promise<any[]> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get<any[]>(
        `${this.baseUrl}/${this.language}/config/structure`,
        {
          params: {
            country: country
          }
        }
      )
    );
    return response;
  }

  async getPartnerStructure(country: string): Promise<any> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get<any>(
        `${this.baseUrl}/${this.language}/config/partnerStructure`,
        {
          params: {
            country: country
          }
        }
      )
    ).catch((error) => {
      return undefined;
    });
    return response;
  }

  async getReviewStructure(country: string): Promise<any> {
    if (!this.session) return;
    const response = await firstValueFrom(
      this.http.get<any>(
        `${this.baseUrl}/${this.language}/config/reviewStructure`,
        {
          params: {
            country: country
          }
        }
      )
    ).catch((error) => {
      return undefined;
    });
    return response;
  }

  getStage(): Promise<{ stage: string }> {
    return firstValueFrom(
      this.http.get<{ stage: string }>(environment.apiUrl + '/stage')
    );
  }

  async checkCountry(alias: string, countryCode: string) {
    return await firstValueFrom(
      this.http.get(
        environment.apiUrl + '/check/alias/' + alias + '/country/' + countryCode
      )
    );
  }

  async getPromotionUi(promo: string, country: string): Promise<UIModel> {
    // console.log(this.baseUrl + '/promotion/' + promo + '/' + country);
    let resp = await firstValueFrom(
      this.http.get(environment.apiUrl + '/promotion/' + promo + '/' + country)
    );
    return resp as UIModel;
  }

  async getPromotioPartners(product: string, country: string): Promise<any> {
    let resp = await firstValueFrom(
      this.http.get(
        environment.apiUrl + '/distributionpartner/' + product + '/' + country
      )
    );
    return resp;
  }
}
