export class ConfiguratorSetup {
  website?: string;
  pagetype?: string;
  product: string;
  msku?: string;
  malias?: string;
  country: string;
  topic?: string;
  uid?: string;
  RUS_Department?: string;
  m4c_productInterest1?: string;
  m4c_purpose?: string;
  m4c_ioi: string;
  m4c_title?: string;
  m4c_interactionType?: string;
  m4c_campaignId: string;
  leadMode?: string;
  bundleMode?: string;
  configId?: string;
  ver?: string;
  verproduct?: string;
  environment?: string;
  uis?: string[];
  language?: string;
  dualChannel?: boolean;
  partnerId?: string;
  default?: boolean;
  baseModel?: string;
  priceDisclaimer?: boolean;
}

export class PromotionSetup {
  website?: string;
  pagetype?: string;
  product: string;
  country: string;
  topic?: string;
  uid?: string;
  RUS_Department?: string;
  m4c_productInterest1?: string;
  m4c_purpose?: string;
  m4c_ioi: string;
  m4c_title?: string;
  m4c_interactionType?: string;
  m4c_campaignId: string;
  ver?: string;
  verproduct?: string;
  environment?: string;
  bundleMode: string;
}

export class StopSessionSetup {
  session?: string;
}

export class ValidationSetup {
  configCode: string;
}
