export class Start {
  static readonly type = '[Promotions] Start';
  constructor() {}
}

export class SelectPromoItem {
  static readonly type = '[Widget] Select Promo Item';
  constructor(
    public itemName: string,
    public widgetId: number,
    public quantity: number = 1,
    public country: string = 'de'
  ) {}
}

export class StartPromo {
  static readonly type = '[Promotions] Promo Start';
  constructor(
    public country: string,
    public product: string
  ) {}
}

export class SelectPromotionItem {
  static readonly type = '[Widget] Select Promotion Item';
  constructor(
    public product: string,
    public country: string,
    public widgetId: number,
    public name: string
  ) {}
}
