import { Component, OnInit } from '@angular/core';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';

@Component({
  selector: 'app-preconfigured-bundles',
  templateUrl: './preconfigured-bundles.component.html',
  styleUrls: ['./preconfigured-bundles.component.scss']
})
export class PreconfiguredBundlesComponent implements OnInit {
  public testObject = [];
  public review: boolean = false;

  constructor(public camosConfigurator: CamosConfiguratorComponent) {}

  ngOnInit(): void {
    this.testObject = [
      {
        title: 'Solution A',
        list: '["Lorem ipsum dolor sit", "amet,consectetuer" , "adipiscing elit enean commodo ligula eget"]'
      },
      {
        title: 'Solution B',
        list: '["Lorem ipsum dolor sit", "amet,consectetuer" , "adipiscing elit enean commodo ligula eget"]'
      },
      {
        title: 'Solution C',
        list: '["Lorem ipsum dolor sit", "amet,consectetuer" , "adipiscing elit enean commodo ligula eget"]'
      },
      {
        title: 'Solution D',
        list: '["Lorem ipsum dolor sit", "amet,consectetuer" , "adipiscing elit enean commodo ligula eget"]'
      }
    ];
  }

  loadReview() {
    this.review = true;
  }
}
