import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-element',
  templateUrl: './card-element.component.html',
  styleUrls: ['./card-element.component.scss']
})
export class CardElementComponent implements OnInit {
  @Input() type: string;
  @Input() data: any;
  @Input() myMethodFunc: Function;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.type)
    try {
      this.data.list = JSON.parse(this.data.list);
    } catch (error) {}
  }

  public onClick(type: string) {
    this.myMethodFunc(type);
  }
}
