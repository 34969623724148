<div class="web">
  <a class="button-tertiary" *ngIf="prevNavNode" (click)="prev()"
    >{{ 'navigation.back' | i18next }} {{ prevNavNode.naming }}</a
  >

  <a
    class="button-primary"
    [id]="disabled ? 'noHover' : 'hover'"
    *ngIf="nextNavNode"
    [style.opacity]="disabled ? 0.3 : 1"
    [style.cursor]="disabled ? 'not-allowed' : 'pointer'"
    (click)="next()"
    >{{ 'navigation.continue' | i18next }} {{ nextNavNode.naming }}</a
  >
  <a
    class="button-primary"
    [id]="review ? 'normal' : 'disabled'"
    *ngIf="!nextNavNode && review"
    (click)="camosConfiguratorComponent.loadComponent('review')"
    >{{ 'navigation.review' | i18next }}</a
  >
</div>

<div class="mobile">
  <a
    class="button-primary"
    [id]="disabled ? 'noHover' : 'hover'"
    *ngIf="nextNavNode && review"
    [style.opacity]="disabled ? 0.3 : 1"
    [style.cursor]="disabled ? 'not-allowed' : 'pointer'"
    (click)="next()"
    >{{ 'navigation.continue' | i18next }} {{ nextNavNode.naming }}</a
  >
  <a
    class="button-primary"
    [id]="review ? 'normal' : 'disabled'"
    *ngIf="!nextNavNode && review"
    (click)="camosConfiguratorComponent.loadComponent('review')"
    >{{ 'navigation.review' | i18next }}</a
  >

  <div style="text-align: center">
    <a
      class="button-tertiary"
      *ngIf="!prevNavNode && !subConfig"
      (click)="camosConfiguratorComponent.goToConfigType()"
      >{{ 'general.backToConfiguration' | i18next }}</a
    >
    <a class="button-tertiary" *ngIf="prevNavNode" (click)="prev()"
      >{{ 'navigation.back' | i18next }} {{ prevNavNode.naming }}</a
    >
  </div>
</div>
