<div class="table-body">
  <div class="parent header" [ngStyle]="style">
    <div class="div1">
      <p>{{ 'general.table.header.modelName' | i18next }}</p>
    </div>
    <div *ngFor="let description of properties" class="div2">
      <p>{{ description.description }}</p>
    </div>

    <div class="div3">
      <p>{{ 'general.table.header.price' | i18next }}</p>
    </div>
    <div class="div4">
      <a href="" style="visibility: hidden" class="button-secondary">{{
        'general.table.header.configure' | i18next
      }}</a>
    </div>
  </div>
  <div *ngFor="let item of baseValues" class="parent" [ngStyle]="style">
    <div class="div1">
      <p class="title">{{ 'general.table.header.modelName' | i18next }}</p>
      <p class="item-type-header">{{ item.cType }}</p>
      <p>
        {{ 'widgets.bodyOrderNumber' | i18next }}
        <span class="order-no">{{ item.name }}</span>
      </p>
    </div>
    <div *ngFor="let properites of properties" class="div2">
      <p class="title">{{ properites.description }}</p>
      <p>{{ item[properites.name] }}</p>
    </div>
    <div class="div3">
      <p class="title">{{ 'general.table.header.price' | i18next }}</p>
      <p *ngIf="appComponent.isPrice">
        {{ camosConfiguratorComponent.numberWithCommas(item.price) }}
      </p>
      <p *ngIf="!appComponent.isPrice">On request</p>
    </div>
    <div class="div4">
      <a
        id="{{ item.name }}"
        (click)="tableComponent.selectItem(item.name)"
        [ngClass]="item.isSelected ? 'selected' : ''"
        class="button-secondary"
        style="white-space: nowrap; padding-left: 15px"
      >
        <svg
          *ngIf="item.isSelected"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          style="margin-bottom: 3px"
          fill="currentColor"
          class="bi bi-check"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
        <svg
          *ngIf="!item.isSelected"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          style="margin-bottom: 3px"
          fill="currentColor"
          class="bi bi-gear"
          viewBox="0 0 16 16"
        >
          <path
            d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
          />
          <path
            d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
          />
        </svg>
        {{ 'general.table.header.configure' | i18next }}</a
      >
    </div>
  </div>
</div>
