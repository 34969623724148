<h2>Preconfiguration R&S RTM3000 oscilloscope Solution A</h2>
<h3>Options</h3>
<app-review-preconfigured-table></app-review-preconfigured-table>
<app-review-preconfigured-table></app-review-preconfigured-table>
<h3>Probes</h3>
<app-review-preconfigured-table></app-review-preconfigured-table>
<app-review-preconfigured-table></app-review-preconfigured-table>
<h3>Accessories</h3>
<app-review-preconfigured-table></app-review-preconfigured-table>
<h3>Calibration and Service</h3>
<app-review-preconfigured-table></app-review-preconfigured-table>
<app-review-preconfigured-table></app-review-preconfigured-table>
<div class="footer-btns">
  <div class="">
    <a
      _ngcontent-idc-c62=""
      class="button-tertiary ng-star-inserted backTo-btn"
      >{{ 'general.backToConfiguration' | i18next }}</a
    >
  </div>
  <div class="btns">
    <a class="button-secondary" href="#">{{ 'general.continue' | i18next }}</a>
    <a class="button-primary" href="#">Request a quote</a>
  </div>
</div>
