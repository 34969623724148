import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { CamosService } from './camos.service';

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private priceTotal: BehaviorSubject<any> = new BehaviorSubject<any[]>(null);
  public priceTotal$: Observable<any> = this.priceTotal.asObservable();
  public structure;
  public TotalPrice;
  public price: string = '0';

  updatePriceTotal(TotalPrice) {
    this.priceTotal.next(TotalPrice);
  }

  checkCurrency(): string {
    try {
      const currency = this.store
        .snapshot()
        .ui.widgets[0].values[1].price.split(' ')[1];
      this.price = this.price + currency;
      return currency;
    } catch (error) {
      console.log(error.message);
    }
  }

  async setPriceTotal() {
    this.structure = await this.camos.getReviewStructure(
      this.store.snapshot().lead.configuratorSetup.country
    );
    if (!this.structure) {
      this.structure = await this.camos.getReviewStructure(
        this.store.snapshot().lead.configuratorSetup.country
      );
    }
    this.price = this.calculateTotalPrice(this.structure);
    return this.price;
  }

  public calcPrice(values, isPrice, currency, price) {
    values.forEach((value) => {
      if (value.price) {
        const priceAndCurrecny = value.price.split(' ', 2);
        if (priceAndCurrecny[0] === 'price') {
          isPrice = false;
        }
        currency = priceAndCurrecny[1];
        if (priceAndCurrecny[0].includes(',')) {
          priceAndCurrecny[0] = priceAndCurrecny[0].replace(',', '.');
        }
        price += Number.parseFloat(priceAndCurrecny[0]);
      }
    });

    values.forEach((value) => {
      if (!value.price && value.values.length > 0) {
        let res = this.calcPrice(value.values, isPrice, currency, price);
        if (!res.isPrice) {
          isPrice = res.isPrice;
        }
        currency = res.currency;
        price = res.price;
      } else {
        return { isPrice: true, currency: '', price: 0 };
      }
    });

    return { isPrice: isPrice, currency: currency, price: price };
  }

  public calculateTotalPrice(structure) {
    for (let i = 0; i < structure?.values.length; i++) {
      if (structure.values[i].values) {
        let res = this.calcPrice(structure.values[i].values, true, '', 0);
        if (res.isPrice) {
          structure.values[i].price = res.price + ' ' + res.currency;
        } else {
          structure.values[i].price = 'price on request';
        }
      }
    }
    let price = 0;
    let currency = '';
    let isPrice = true;
    if (structure) {
      for (let i = 0; i < structure?.values.length; i++) {
        if (structure.values[i].price == 'price on request') {
          isPrice = false;
        } else {
          price = price + parseFloat(structure.values[i].price.split(' ')[0]);
          currency = structure.values[i].price.split(' ')[1];
        }
      }
    } else {
      isPrice = false;
    }

    if (isPrice) {
      let newPrice;
      if (price % 1 != 0) {
        newPrice = price.toFixed(2).replace('.', ',');
      } else {
        newPrice = price.toString().replace('.', ',');
      }
      if (currency == '') {
        currency = this.checkCurrency();
      }
      if (this.TotalPrice && this.TotalPrice.split(' ')[1]) {
        this.TotalPrice = this.TotalPrice.split(' ')[0];
      }
      this.TotalPrice = newPrice + ' ' + currency;
    } else {
      this.TotalPrice = 'price on request';
    }

    // (this.TotalPrice == '0') ? this.TotalPrice = "0 EUR" : this.TotalPrice;
    return this.TotalPrice;
  }

  constructor(
    public store: Store,
    public camos: CamosService
  ) {}
}
