import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import JSON from '../../data/countries.json';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { RequestQuoteSelectedComponent } from 'src/app/request-quote-selected/request-quote-selected.component';

@Component({
  selector: 'app-country-selector-menu',
  templateUrl: './country-selector-menu.component.html',
  styleUrls: ['./country-selector-menu.component.scss']
})
export class CountrySelectorMenuComponent implements OnInit, AfterViewInit {
  @Input() comSelector?: boolean;

  public countries: any = {};
  public activeIndex: number = 0;
  public displayedCountries = [];
  public displaySelector = true;
  public mobile = false;

  constructor(
    private camosConfigurationComponent: CamosConfiguratorComponent,
    public RequestQuoteSelectedComponent: RequestQuoteSelectedComponent
  ) {}

  ngOnInit(): void {
    this.countries = JSON.country;
    this.displayedCountries = this.countries[this.activeIndex].countries;
    if (window.screen.width <= 960) this.displaySelector = false;
    this.onResize();
  }
  onResize() {
    window.screen.width <= 960 ? (this.mobile = true) : (this.mobile = false);
  }

  ngAfterViewInit() {}

  changeContinent(i: number) {
    this.activeIndex = i;
    this.displayedCountries = this.countries[this.activeIndex].countries;
    this.displaySelector = true;
  }

  changeCountry(language: string) {
    let country = language.toLowerCase();
    if (language.toLowerCase() == 'be') country = 'nl';
    if (this.comSelector) {
      this.camosConfigurationComponent.setSelectedCountry(country);
    } else {
      this.camosConfigurationComponent.setSelectedCountry(country, true);
    }
  }

  countryNotListed() {
    if (this.comSelector) {
      this.camosConfigurationComponent.openWithoutPartnerForm();
    } else {
      this.camosConfigurationComponent.lastCountryValue = 'international';
      this.camosConfigurationComponent.partnerPage = false;
      this.camosConfigurationComponent.formWithoutPartner = true;
    }
  }
}
