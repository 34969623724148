import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-service-selection-checkbox',
  templateUrl: './service-selection-checkbox.component.html',
  styleUrls: ['./service-selection-checkbox.component.scss']
})
export class ServiceSelectionCheckboxComponent implements OnInit {
  uuidNum = uuidv4();
  constructor() {}

  ngOnInit(): void {}
}
