<div *ngIf="!review" class="module module-card-selector">
  <app-slider
    [type]="'preconfigured'"
    [sliderContent]="testObject"
  ></app-slider>

  <div class="module-card-selector" style="width: 100%">
    <div class="divider-or">
      <span></span><span>{{ 'requestQuote.or' | i18next }}</span>
    </div>
    <div class="button-wrapper">
      <a
        class="button tap-configuration-button"
        (click)="camosConfigurator.loadComponent('code-configuration')"
      >
        <span class="rsi-inline icon-settings"></span>
        <span>{{ 'startPage.configCode' | i18next }}</span>
      </a>
    </div>
  </div>
</div>
<div *ngIf="review">
  <app-preconfigured-review-ui></app-preconfigured-review-ui>
</div>
