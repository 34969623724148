import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetFields } from './lead.actions';
import { ConfiguratorSetup } from './lead.model';

@State<ConfiguratorSetup>({
  name: 'lead'
})
@Injectable()
export class ConfiguratorHiddenState {
  constructor() {}

  ngxsOnInit(ctx: StateContext<ConfiguratorSetup>) {
    console.log('start lead ngxs');
  }

  @Selector()
  public static configuratorFields(
    setup: ConfiguratorSetup
  ): ConfiguratorSetup {
    return setup;
  }

  @Action(SetFields)
  setFields(ctx: StateContext<ConfiguratorSetup>, action: ConfiguratorSetup) {
    const nextState = action;
    console.log('next state: ', action);
    console.log('next state: ', nextState);
    ctx.setState(nextState);
  }
}
