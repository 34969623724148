import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';

@Component({
  selector: 'app-cui-comp-single-combobox',
  templateUrl: './cui-comp-single-combobox.component.html',
  styleUrls: ['./cui-comp-single-combobox.component.scss']
})
export class CuiCompSingleComboboxComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  selected;

  constructor(public camosConfiguratorComponent: CamosConfiguratorComponent) {}

  ngOnInit(): void {
    for (let i = 0; i < this.widget.values.length; i++) {
      if (this.widget.values[i].isSelected) {
        this.selected = this.widget.values[i];
      }
    }
  }
}
