<div class="single-icon">
  <div
    (click)="setArrow($event)"
    (mouseenter)="setArrow($event)"
    class="icon-wrapper"
  >
    <ng-content></ng-content>
    <p style="font-size: 12px; color: #009fe3; pointer-events: none">
      {{ tooltipValue }}
    </p>
  </div>

  <div
    *ngIf="items && items.length > 1"
    class="tooltip-wrapper tooltip-small"
    #tooltipWrapper
  >
    <div class="tooltip-wrapper-arrow" [style.left]="arrowStyleLeft"></div>
    <div>
      <button
        *ngFor="let item of items"
        class="tooltip-text"
        [class.selected]="tooltipValue === item"
        [title]="item"
        (click)="tooltipSelect($event)"
      >
        <span></span> {{ item }}
      </button>
    </div>
  </div>
</div>
