import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PromotionUIState } from '../store/promotionUi/promotionUi.state';
import { Widget } from '../store/ui/ui.model';
import { UIState } from '../store/ui/ui.state';

@Component({
  selector: 'app-camos-widgets',
  templateUrl: './camos-widgets.component.html',
  styleUrls: ['./camos-widgets.component.scss']
})
export class CamosWidgetsComponent implements OnInit {
  @Input() promotion?: boolean = false;

  @Select(UIState.widgets) widgets$: Observable<Widget[]>;
  widgets: Widget[];
  @Select(PromotionUIState.widgets) promoWidgets$: Observable<Widget[]>;
  promoWidgets: Widget[];

  constructor() {}

  ngOnInit(): void {
    if (this.promotion) {
      this.promoWidgets$.subscribe(
        (_promoWidgets) => (this.promoWidgets = _promoWidgets)
      );
    } else {
      this.widgets$.subscribe((_widgets) => (this.widgets = _widgets));
    }
  }
}
