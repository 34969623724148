import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { CuiCompSingleTableComponent } from 'src/app/camos-widgets/cui-comp-single-table/cui-comp-single-table.component';

@Component({
  selector: 'app-base-product-table',
  templateUrl: './base-product-table.component.html',
  styleUrls: ['./base-product-table.component.scss']
})
export class BaseProductTableComponent implements OnInit {
  @Input() baseValues: object;
  @Input() properties: any;
  public style;
  constructor(
    public tableComponent: CuiCompSingleTableComponent,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public appComponent: AppComponent
  ) {}

  ngOnInit(): void {
    const propertiesLenght = this.properties.length;
    this.style = {
      'grid-template-columns': `${
        propertiesLenght == 0
          ? '2fr 1fr 1fr'
          : `2fr repeat(${propertiesLenght}, 1fr 1fr) auto`
      }`
    };
  }
}
