<ngx-spinner
  name="spinnerConf"
  type="ball-clip-rotate"
  color="#009bea"
></ngx-spinner>
<div *ngIf="show" style="margin: 20px">
  <div class="headline-container">
    <h3>{{ 'configurationCode.enter.header' | i18next }}</h3>
  </div>

  <label>
    {{ 'configurationCode.enter.description' | i18next }}
  </label>
  <p style="margin: 50px 0px 50px 0px">
    <label for="configCode">{{
      'configurationCode.enter.label' | i18next
    }}</label>
    <input
      #configInput
      type="text"
      style="width: 100%; max-width: 350px"
      id="configCode"
      placeholder="123E456D789C"
      required
    />
    <br /><span *ngIf="notFound" style="color: red; font-size: 11px">{{
      'configurationCode.enter.notFound' | i18next
    }}</span>
    <span *ngIf="empty" style="color: red; font-size: 11px">{{
      'configurationCode.enter.empty' | i18next
    }}</span>
  </p>
  <a
    class="button-primary"
    [style]="disable ? 'pointer-events: none; opacity:0.5;' : ''"
    (click)="loadConfig(configInput.value)"
    >{{ 'configurationCode.enter.load' | i18next }}</a
  >
  <br />
  <a
    class="button-tertiary"
    *ngIf="!(!this.startSession && this.startSession.length == 0)"
    (click)="camosConfiguratorComponent.goToConfigType()"
    style="cursor: pointer"
    >{{ 'general.backToConfiguration' | i18next }}</a
  >
</div>
