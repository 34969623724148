import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { FilteredStructure } from 'src/app/models/review.model';
import { ReviewComponent } from 'src/app/review/review.component';

@Component({
  selector: 'app-review-preconfigured-table',
  templateUrl: './review-preconfigured-table.component.html',
  styleUrls: ['./review-preconfigured-table.component.scss']
})
export class ReviewPreconfiguredTableComponent implements OnInit {
  @Input() public items: FilteredStructure;
  @Input() type: string;

  public tableHeight = document.getElementsByClassName('accordion-table');
  public AccordionHeight: string = 'auto';
  public parentuuid: string;
  public show: boolean = false;
  public showConfig: boolean = false;

  constructor(
    public next_prev: ReviewComponent,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public appComponent: AppComponent
  ) {}

  ngOnInit() {
    this.parentuuid = this.items.uuid;
    this.items.values.forEach((item) => {
      if (item.parentuuid == this.parentuuid && !item.values) {
        this.show = true;
      }
      if (item.parentuuid == this.parentuuid && item.values) {
        this.showConfig = true;
      }
    });
  }

  public expandOrCollapse(uuid) {
    let newItems;
    if (this.items.uuid == uuid) {
      newItems = {
        name: this.items.name,
        uuid: this.items.uuid,
        parentuuid: this.items.parentuuid,
        isExpanded: !this.items.isExpanded,
        values: this.items.values
      };
    } else {
      newItems = {
        name: this.items.name,
        uuid: this.items.uuid,
        parentuuid: this.items.parentuuid,
        isExpanded: this.items.isExpanded,
        values: []
      };
      for (let i = 0; i < this.items.values.length; i++) {
        if (this.items.values[i].uuid == uuid) {
          newItems.values.push({
            name: this.items.values[i].name,
            uuid: this.items.values[i].uuid,
            parentuuid: this.items.values[i].parentuuid,
            isExpanded: !this.items.values[i].isExpanded,
            visible: this.items.values[i].visible,
            productID: this.items.values[i].productID,
            productType: this.items.values[i].productType,
            quantity: this.items.values[i].quantity,
            price: this.items.values[i].price,
            values: this.items.values[i].values
          });
        } else {
          newItems.values.push({
            name: this.items.values[i].name,
            uuid: this.items.values[i].uuid,
            parentuuid: this.items.values[i].parentuuid,
            isExpanded: this.items.values[i].isExpanded,
            visible: this.items.values[i].visible,
            productID: this.items.values[i].productID,
            productType: this.items.values[i].productType,
            quantity: this.items.values[i].quantity,
            price: this.items.values[i].price,
            values: this.items.values[i].values
          });
        }
      }
    }
    this.items = newItems;
  }

  public test(e: any) {
    const parent = e.target;
    const child = parent.nextElementSibling;
    if (child.clientHeight > 0) {
      this.AccordionHeight = '0px';
    } else {
      this.AccordionHeight = `${child.scrollHeight}px`;
    }
  }
}
