<div class="module tap-category-selector">
  <div class="wrapper">
    <div
      [ngClass]="
        naviNodesCount > 4 ? 'content mobile tablet' : 'content mobile'
      "
      style="max-height: unset"
    >
      <div
        class="button-left"
        *ngIf="transformValue < 0 && isSlider"
        (click)="changeSlide('prev')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </div>
      <div
        class="button-right"
        *ngIf="transformValue !== maxTransformValue && isSlider"
        (click)="changeSlide('next')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
      <div
        class="categories overflow"
        [style.transform]="'translateX(' + transformValue + 'px)'"
        style="width: 100%"
        #categories
      >
        <app-cui-navi-node
          [naviNode]="naviNode"
          [index]="i"
          *ngFor="let naviNode of naviNodes; let i = index"
        ></app-cui-navi-node>
      </div>
    </div>
  </div>
</div>
