<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <div class="module-table-card table-striped module-tap-multi-select-quantity">
    <div class="table-body">
      <div class="product-list-container">
        <div class="table-row">
          <div class="table-col col-5" style="padding-left: 0px">
            <div class="table-col-content">
              +
              {{ camosConfiguratorComponent.numberWithCommas(selected.price) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
