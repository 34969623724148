<div class="table-body">
  <div class="parent header">
    <div class="div1">
      <p>{{ 'general.table.header.product' | i18next }}</p>
    </div>
    <div class="div2">
      <p>{{ 'general.table.header.description' | i18next }}</p>
    </div>
    <div class="div3">
      <p>{{ 'general.table.header.price' | i18next }}</p>
    </div>
    <div class="div4">
      <p *ngIf="type === 'option'">
        {{ 'general.table.header.oneOptionPossible' | i18next }}
      </p>
      <p *ngIf="type === 'multi'">
        {{ 'general.table.header.multiSelectPossible' | i18next }}
      </p>
    </div>
  </div>
  <div
    class="parent"
    [ngClass]="{
      optionDisabled:
        item.validity.type === 'MayNot' ||
        widget.variable.validity.type === 'Must'
    }"
    *ngFor="let item of values; let i = index"
    id="table-group-row-{{ item.name }}"
  >
    <div class="div1">
      <p class="title-small">{{ 'general.table.header.product' | i18next }}</p>

      <div class="item-name">{{ item.cType }}</div>
    </div>
    <div class="div2 tooltip-salesText">
      <div class="tooltip">
        <p class="title-small">
          {{ 'general.table.header.description' | i18next }}
        </p>
        {{ item.naming }}
        <div *ngIf="item.salestext">
          <app-sales-text
            [salesText]="item.salestext"
            [showText]="'Show option details'"
            [hideText]="'Hide option details'"
          ></app-sales-text>
        </div>
      </div>
    </div>
    <div class="div3" *ngIf="appComponent.isPrice">
      <p class="title-small">{{ 'general.table.header.price' | i18next }}</p>
      + {{ camosConfiguratorComponent.numberWithCommas(item.price) }}
    </div>
    <div class="div3" *ngIf="!appComponent.isPrice">
      <p class="title-small">{{ 'general.table.header.price' | i18next }}</p>
      On request
    </div>
    <div class="div4">
      <p *ngIf="type === 'option'" class="title-small">
        {{ 'general.table.header.oneOptionPossible' | i18next }}
      </p>
      <p *ngIf="type === 'multi'" class="title-small">
        {{ 'general.table.header.multiSelectPossible' | i18next }}
      </p>
      <div class="tooltip-wrapper" style="position: relative">
        <div style="position: absolute" class="spinner-option">
          <ngx-spinner
            name="spinner-{{ item.name }}"
            type="ball-clip-rotate"
            bdColor="rgba(0, 0, 0, 0)"
            color="#009bea"
            [fullScreen]="false"
            size="small"
          >
          </ngx-spinner>
        </div>

        <div
          class=""
          style="display: flex; align-items: center"
          *ngIf="type === 'radio' && widget.isReadonly && item.isSelected"
        >
          <div style="margin-top: 0px; top: 22px; display: inline-block">
            <label
              *ngIf="item.isSelected"
              for="single-radio-{{ item.name }}"
              style="
                display: inline-block;
                margin: 0px 0px 0px 10px;
                vertical-align: middle;
              "
              ><a class="tap-tertiary-btn checked"
                ><div class="rsi rsi-close"></div>
                {{ 'widgets.added' | i18next }}</a
              ></label
            >
          </div>
        </div>

        <div
          class=""
          style="display: flex; align-items: center"
          *ngIf="type === 'radio' && !widget.isReadonly"
        >
          <input
            id="single-radio-{{ item.name }}"
            type="radio"
            name="checkbox_{{ widget.id }}"
            [checked]="item.isSelected"
            (click)="cuiComponent.selectItem(item.name, $event)"
            [disabled]="
              item.validity.type === 'MayNot' || oneMust ? true : false
            "
          />
          <label
            style="display: inline-block"
            (click)="cuiComponent.selectItem(item.name, $event)"
          ></label>
          <div style="margin-top: 0px; top: 22px; display: inline-block">
            <label
              *ngIf="item.isSelected"
              for="single-radio-{{ item.name }}"
              style="
                display: inline-block;
                margin: 0px 0px 0px 10px;
                vertical-align: middle;
              "
              ><a class="tap-tertiary-btn checked"
                ><div class="rsi rsi-close"></div>
                {{ 'widgets.added' | i18next }}</a
              ></label
            >
            <label
              *ngIf="!item.isSelected"
              for="single-radio-{{ item.name }}"
              style="
                display: inline-block;
                margin: 0px 0px 0px 10px;
                vertical-align: middle;
              "
              ><a class="tap-tertiary-btn" style="text-align: center">{{
                'widgets.addToConfiguration' | i18next
              }}</a></label
            >
          </div>
        </div>
        <!-- Multi Select -->
        <div
          *ngIf="type === 'multi'"
          class="product-text-controls"
          style="
            margin: 0px;
            position: static;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
          "
        >
          <div *ngIf="item.quantity || item.quantity == 0">
            <div class="counter-quantity" style="margin-top: 5px">
              <div
                class="counter-quantity"
                [style]="
                  item.validity.type === 'MayNot' ||
                  widget.variable.validity.type === 'Must'
                    ? 'pointer-events: none; cursor: default;display:flex;'
                    : 'cursor: pointer;display:flex;transition:500ms all;'
                "
              >
                <span
                  class="minus rsi rsi-minus"
                  (click)="
                    cuiComponentMulti.subQuantity(item.name, item.quantity)
                  "
                ></span>
                <input
                  id="select_{{ item.name }}"
                  class="counter"
                  type="text"
                  style="min-width: 30px; height: 33px"
                  [value]="item.quantity"
                  (change)="
                    cuiComponentMulti.changeQuantity(item.name, item.quantity)
                  "
                />
                <span
                  class="plus rsi rsi-plus"
                  (click)="
                    cuiComponentMulti.addQuantity(item.name, item.quantity)
                  "
                ></span>
              </div>
            </div>
          </div>
          <div
            *ngIf="!item.quantity && item.quantity != 0"
            style="margin-top: 0px"
            id="plus_{{ item.name }}"
          >
            <a
              *ngIf="!item.isSelected"
              class="tap-tertiary-btn"
              (click)="cuiComponentMulti.selectItem(item.name)"
              [style]="
                item.validity.type === 'MayNot' ||
                widget.variable.validity.type === 'Must'
                  ? 'pointer-events: none;font-size: 14px; cursor: default;'
                  : 'cursor: pointer; font-size: 14px;'
              "
            >
              <div class="rsi rsi-plus"></div>
              {{ 'widgets.addToConfiguration' | i18next }}
            </a>
            <a *ngIf="item.isSelected" class="tap-tertiary-btn checked">
              <div class="rsi rsi-plus"></div>
              {{ 'widgets.added' | i18next }}
            </a>
            <a
              *ngIf="item.isSelected"
              class="tap-tertiary-btn"
              style="cursor: pointer; display: inline-block; margin-left: 10px"
              (click)="cuiComponentMulti.selectItem(item.name)"
            >
              <div class="rsi rsi-close"></div>
              {{ 'widgets.remove' | i18next }}
            </a>
          </div>
        </div>

        <!--  -->
        <div
          [ngClass]="
            item.validity.type === 'MayNot' ||
            widget.variable.validity.type === 'Must'
              ? selectableOption
              : disabledOption
          "
          style="display: flex; align-items: center; margin-left: 5px"
        >
          <div class="table-row tooltip-salesText">
            <div class="table-col col-2">
              <div class="table-col-header"></div>
              <div class="table-col-content">
                <app-validtype-tooltip
                  [validExplanation]="item.validity.explanation"
                  [validType]="item.validity.type"
                  [tooltipPosition]="'left'"
                ></app-validtype-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
