import { UIModel } from './ui.model';

export class StartSession {
  static readonly type = '[Session] Start Session';
  constructor(
    public country: string,
    public product: string,
    public configId: string,
    public language: string,
    public baseModel: string,
    public ver?: string,
    public verproduct?: string,
    public environment?: string
  ) {}
}

export class StopSession {
  static readonly type = '[Session] Stop Session';
  constructor(public session: string) {}
}

export class LoadConfig {
  static readonly type = '[Config] Load Config';
  constructor(
    public configuuid: string,
    public country: string,
    public language: string
  ) {}
}

export class SelectNavinode {
  static readonly type = '[Navigation] Select Navinode';
  constructor(public navinodeId: number) {}
}

//! SelectItem NOT IN USE, COULD BE DELETED
export class SelectItem {
  static readonly type = '[Widget] Select Item';
  constructor(
    public itemName: string,
    public widgetId: number,
    public quantity: number = 1,
    public previousQuantity: number = 1,
    public widgetType?: string,
    public prevSelected?: string
  ) {}
}
export class SelectItemState {
  static readonly type = '[Widget] Select Item Test';
  constructor(
    public nextState: UIModel,
    public itemName: string,
    public widgetId: number,
    public quantity: number = 1,
    public previousQuantity: number = 1,
    public widgetType?: string,
    public prevSelected?: string
  ) {}
}

export class Expand {
  static readonly type = '[Widget] Expand Widget';
  constructor(public widgetId: number) {}
}

export class Collapse {
  static readonly type = '[Widget] Collapse Widget';
  constructor(public widgetId: number) {}
}

export class RevertSelectItem {
  static readonly type = '[Widget] Select Revert';
  constructor() {}
}

// export class GetReviewList {
//   static readonly type = '[ReviewProduct] Get Product List';
//   constructor(public uiId: number) {}
// }
