<div
  *ngIf="validType === 'MayNot' || validType === 'Must'"
  class="wrapper tooltip-{{ tooltipPosition }}"
  style="width: fit-content; margin: auto"
>
  <div class="tooltip" (mouseenter)="tooltipMouseEnter()" #tooltip>
    <div class="tooltip-circle-wrapper">
      <div class="rsi rsi-info-circle"></div>
    </div>
    <span *ngIf="validType === 'MayNot'" class="tooltiptext" [style]="style">
      <p class="h5">{{ 'tooltip.notAvailable.header' | i18next }}</p>
      <div class="text" style="width: 100%">
        {{ 'tooltip.notAvailable.text' | i18next }} <br />
        {{ validExplanation }}
      </div>
    </span>
    <span *ngIf="validType === 'Must'" class="tooltiptext" [style]="style">
      <p class="h5">{{ 'tooltip.mustSelect.header' | i18next }}</p>
      <div class="text" style="width: 100%">
        {{ 'tooltip.mustSelect.text' | i18next }} <br />
        {{ validExplanation }}
      </div>
    </span>
  </div>
</div>
