<div
  class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
  style="
    overflow: hidden auto;
    background-color: gray;
    opacity: 0.8;
    z-index: 99001;
  "
></div>
<div
  class="mfp-container mfp-s-ready mfp-inline-holder"
  style="z-index: 99001; position: fixed"
>
  <div class="mfp-content" style="z-index: 99001">
    <div class="add-to-cart-dialog" style="z-index: 99001">
      <div class="module tap-notification">
        <div class="wrapper">
          <div class="content">
            <p class="headline h5">There error occurred</p>
            <div class="text">
              <p>
                This option could not be added to the current configuration.
                Please try again.
              </p>
            </div>
            <div class="button-container">
              <a class="button-primary" (click)="reject()">Continue</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
