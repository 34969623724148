<div
  class="country-selector-menu"
  [ngClass]="{ 'com-component': comSelector === true }"
>
  <div class="close-btn-wrapper">
    <span
      class="btn-close"
      (click)="RequestQuoteSelectedComponent.openCountrySelector()"
    ></span>
  </div>
  <!-- <app-country-selector></app-country-selector> -->
  <div class="country-header" *ngIf="!displaySelector || !mobile">
    <ul>
      <li
        *ngFor="let item of countries; index as i"
        [ngClass]="{ active: i == activeIndex }"
      >
        <button (click)="changeContinent(i)">
          {{ item.continent }}
        </button>
      </li>
    </ul>

    <p class="button-tertiary country-not-listed" (click)="countryNotListed()">
      My Country is not listed
    </p>
  </div>

  <div class="country-body" *ngIf="displaySelector">
    <p *ngIf="mobile" class="back-to-region" (click)="displaySelector = false">
      Region
    </p>
    <ul (window:resize)="onResize()">
      <li *ngFor="let item of displayedCountries">
        <button
          class="country-selector-button"
          (click)="changeCountry(item[0])"
        >
          <span>{{ item[0] }}</span
          >{{ item[1] }}
        </button>
      </li>
    </ul>
  </div>
</div>
