import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { CamosPromotionsComponent } from 'src/app/promotion/camos-promotions/camos-promotions.component';
import { PromotionComponent } from 'src/app/promotion/promotion.component';
import { CamosService } from 'src/app/services/camos.service';
import { PriceService } from 'src/app/services/price.service';
import { SetDistributorInfos } from 'src/app/store/distributionpartner/distributionpartner.actions';
import {
  SelectPromoItem,
  SelectPromotionItem
} from 'src/app/store/promotionUi/promotionUi.actions';
// import { SalesTextService } from 'src/app/services/sales-text.service';
import { SelectItemState } from 'src/app/store/ui/ui.actions';
import { Value, Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';
import { AdobeAnalyticsManager } from './../../adobe/adobe-analytics-manager';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-cui-comp-single-table',
  templateUrl: './cui-comp-single-table.component.html',
  styleUrls: ['./cui-comp-single-table.component.scss']
})
export class CuiCompSingleTableComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;

  baseValues: Value[] = [];
  properties: any[] = [];
  width: number = 100;
  public error: boolean = false;
  // salesText;

  constructor(
    private store: Store,
    private adobeAnalyticsManager: AdobeAnalyticsManager,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camosPromotionsComponent: CamosPromotionsComponent,
    private promotionComponent: PromotionComponent,
    private priceService: PriceService,
    private appComponent: AppComponent,
    private camosService: CamosService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    for (let property in this.widget.widgetProperty) {
      this.properties.push({
        name: property,
        description: this.widget.widgetProperty[property]
      });
    }
    this.width = this.width / this.properties.length;
    if (this.widget.xtIsBaseList) {
      this.widget.values.forEach((value) => {
        if (value.name && value.name != null && value.naming != 'Ø') {
          this.baseValues.push(value);
        }
      });
    }
    this.widget.values.forEach((vals) => {
      if (vals.validity.type === 'Must') {
        this.selectItem(vals.name);
      }
    });
    // await this.salesTextSvc.preloadSalesTexts(this.widget.values.map(value => value.name));
  }

  async selectItem(name: string, event?: Event) {
    const targetNode = event?.target as HTMLElement;
    if (this.widget.xtIsBaseList) {
      this.adobeAnalyticsManager.trackBaseModelSelection(name);
    }
    let curr;
    this.widget.values.forEach((value) => {
      if (value.isSelected) {
        curr = value.name;
      }
    });
    try {
      document.getElementById('priceBox').style.color = 'lightgray';
      if (targetNode) targetNode.parentElement.style.opacity = '0';
      this.spinner.show(`spinner-${name}`);
      this.spinner.show('sticky-spinner');

      const nextState = await this.camosService.getItemForWidget(
        name,
        this.widget.id,
        1,
        this.store.snapshot().lead.configuratorSetup.country
      );
      this.store.dispatch(
        new SelectItemState(
          nextState,
          name,
          this.widget.id,
          1,
          1,
          'cUI_Comp_Single_Table_C',
          curr
        )
      );
    } catch (error) {
      this.camosConfiguratorComponent.selectionError = true;
    }
    try {
      if (this.appComponent.isPrice) {
        const price = await this.priceService.setPriceTotal();
        this.priceService.updatePriceTotal(price);
      }
      if (targetNode) targetNode.parentElement.style.opacity = '1';
      document.getElementById('priceBox').style.color = '#009bea';
      this.spinner.hide('sticky-spinner');
      this.spinner.hide(`spinner-${name}`);
    } catch (error) {
      this.camosConfiguratorComponent.selectionError = true;
      const radioButton = document.getElementById(
        `single-radio-${name}`
      ) as HTMLElement;
    }
  }

  selectPromoItem(name: string) {
    if (this.promotionComponent._promotionSetup) {
      this.store.dispatch(
        new SelectPromotionItem(
          this.promotionComponent._promotionSetup.product,
          this.promotionComponent._promotionSetup.country,
          this.widget.id,
          name
        )
      );
    } else {
      this.store.dispatch(
        new SelectPromoItem(
          name,
          this.widget.id,
          1,
          this.store.snapshot().lead.configuratorSetup.country
        )
      );
    }
  }

  calculateTableGroupRowSize(materialId: string) {
    var tableGroupRow = document.getElementById(
      'table-group-row-' + materialId
    );
    if (tableGroupRow) {
      var tableGroupRowHeight = tableGroupRow.offsetHeight;
      var tableGroupRowWidth = tableGroupRow.offsetWidth;
      var salesTextElement = document.getElementById(
        'tooltiptext_' + materialId
      );
      if (salesTextElement != undefined) {
        salesTextElement.style.setProperty(
          '--table-group-row-height',
          tableGroupRowHeight + 'px'
        );
        salesTextElement.style.setProperty(
          '--table-group-row-width',
          tableGroupRowWidth + 'px'
        );
      }
    }
  }

  public async promotionGoToPartnerPage(name, price) {
    while (!this.store.snapshot().distributionpartner) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    let product = '';
    this.widget.values.forEach((value) => {
      if (value.name == name) {
        product = value.product;
      }
    });
    if (this.promotionComponent._promotionSetup) {
      let countryAllowed = await this.camosService.checkCountry(
        product,
        this.promotionComponent._promotionSetup.country
      );
      if (countryAllowed) {
        let promoPartners = await this.camosService.getPromotioPartners(
          product,
          this.promotionComponent._promotionSetup.country
        );
        await this.store.dispatch(
          new SetDistributorInfos({
            distributors: promoPartners,
            selectedDistributor: null
          })
        );
      } else {
        await this.store.dispatch(
          new SetDistributorInfos({
            distributors: [],
            selectedDistributor: null
          })
        );
      }
    }
    let partners = [];
    if (this.store.snapshot().distributionpartner.distributors) {
      partners =
        this.store.snapshot().distributionpartner.distributors.distributors;
    }
    if (partners.length == 0) {
      if (this.promotionComponent._promotionSetup) {
        this.camosPromotionsComponent.promotionGoToPartnerPageWithoutPartner(
          name,
          price
        );
      } else {
        this.camosConfiguratorComponent.promotionGoToPartnerPageWithoutPartner(
          name,
          price
        );
      }
    } else {
      if (this.promotionComponent._promotionSetup) {
        this.camosPromotionsComponent.promotionGoToPartnerPage(name, price);
      } else {
        this.camosConfiguratorComponent.promotionGoToPartnerPage(name, price);
      }
    }
  }

  public async addToBasket(name: string, price: string) {
    while (this.store.snapshot().lead.configuratorSetup === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    let structure = await this.camosService.getPartnerStructure(
      this.store.snapshot().lead.configuratorSetup.country
    );
    structure.values.push({
      name: name,
      uuid: '',
      parentuuid: structure.uuid,
      isExpanded: false,
      visible: true,
      productID: name,
      productType: 'Promotion',
      quantity: 1,
      price: price
    });

    let product = {
      name: structure.name,
      values: structure.values,
      camosConfigurationID: ''
    };

    console.log(product);

    // const leadWindow = window.opener;
    window.postMessage(product, window.location.origin);
  }
}
