import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { Select } from '@ngxs/store';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';
import { UIState } from 'src/app/store/ui/ui.state';
import { CuiCompMultiComboboxComponent } from '../cui-comp-multi-combobox/cui-comp-multi-combobox.component';
import { CuiCompMultiTableComponent } from '../cui-comp-multi-table/cui-comp-multi-table.component';
import { CuiCompSingleTableComponent } from '../cui-comp-single-table/cui-comp-single-table.component';
import { CuiFeatureSingleComboboxComponent } from '../cui-feature-single-combobox/cui-feature-single-combobox.component';
import { CuiFeatureSingleNEditlineComponent } from '../cui_feature_single_n_editline/cui_feature_single_n_editline.component';
import { CuiCompSingleHeaderComponent } from '../cui_comp_single_header/cui_comp_single_header.component';
import { CuiStaticAccordionComponent } from '../cui-static-accordion/cui-static-accordion.component';
import { CuiStaticHeaderComponent } from '../cui-static-header/cui-static-header.component';
import { CuiWidget } from '../cui-widget';
import { WidgetHostDirective } from '../widget-host.directive';
import { CuiCompSingleComboboxComponent } from '../cui-comp-single-combobox/cui-comp-single-combobox.component';
import { Observable } from 'rxjs';
import { CuiStaticSubheadlineComponent } from '../cui-static-subheadline/cui-static-subheadline.component';
import { SubConfigComponent } from 'src/app/sub-config/sub-config.component';

@Component({
  selector: 'app-cui-widget',
  templateUrl: './cui-widget.component.html',
  styleUrls: ['./cui-widget.component.scss']
})
export class CuiWidgetComponent implements OnInit {
  @Input()
  widget: Widget;

  @Input() promotion?: boolean = false;

  @ViewChild(WidgetHostDirective, { static: true })
  widgetHost: WidgetHostDirective;

  @Select(UIState.navinodes) navinodes$: Observable<NaviNode[]>;
  navinodes: NaviNode[];

  public conflict = false;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.navinodes$.subscribe((_navinodes) => (this.navinodes = _navinodes));
    /*
    let oneNotValid
    this.navinodes.forEach(navinode => {
      if (!navinode.isValid) {
        oneNotValid = true;
      }
    });
    if (oneNotValid) {
      this.conflict = true;
    } else {
      this.conflict = false;
    }
    */
    this.renderWidget();
  }

  renderWidget() {
    const componentFactory = this.getComponentFactory(
      this.widget.type,
      this.widget.variable.isList,
      this.widget.isReadonly
    );
    this.widgetHost.viewContainerRef.clear();
    const componentRef =
      this.widgetHost.viewContainerRef.createComponent<CuiWidget>(
        componentFactory
      );
    componentRef.instance.widget = this.widget;
    let oneMust = false;
    if (this.widget.values) {
      if (Array.isArray(this.widget.values)) {
        this.widget.values.forEach((vals) => {
          if (vals.validity.type === 'Must') {
            oneMust = true;
          }
        });
      }
    }
    componentRef.instance.oneMust = oneMust;
    componentRef.instance.promotion = this.promotion;
  }

  getComponentFactory(
    widgetName: string,
    isList: number,
    isReadonly: number
  ): ComponentFactory<CuiWidget> {
    switch (widgetName) {
      case 'Label': // cUI_Static_Label
        return this.componentFactoryResolver.resolveComponentFactory(
          CuiStaticSubheadlineComponent
        );
      case 'Header': // cUI_Static_Header
        return this.componentFactoryResolver.resolveComponentFactory(
          CuiStaticHeaderComponent
        );
      case 'SubNavi':
        return this.componentFactoryResolver.resolveComponentFactory(
          SubConfigComponent
        );
      case 'ConfigboxList':
        if (isList === 1) {
          // cUI_Comp_Multi_Table_C
          return this.componentFactoryResolver.resolveComponentFactory(
            CuiCompMultiTableComponent
          );
        } else {
          // cUI_Comp_Single_Table_C
          return this.componentFactoryResolver.resolveComponentFactory(
            CuiCompSingleTableComponent
          );
        }
      case 'Accordion': // cUI_Static_Accordion
        return this.componentFactoryResolver.resolveComponentFactory(
          CuiStaticAccordionComponent
        );
      case 'ConfigboxCombobox':
        if (isList === 1) {
          // cUI_Comp_Multi_Combobox
          return this.componentFactoryResolver.resolveComponentFactory(
            CuiCompMultiComboboxComponent
          );
        } else {
          if (isReadonly === 1) {
            // cUI_Comp_Single_Combobox
            return this.componentFactoryResolver.resolveComponentFactory(
              CuiCompSingleComboboxComponent
            );
          } else {
            // cUI_Feature_Single_S_Combobox
            return this.componentFactoryResolver.resolveComponentFactory(
              CuiFeatureSingleComboboxComponent
            );
          }
        }
      case 'Editline': // cUI_Feature_Single_N_Editline
        return this.componentFactoryResolver.resolveComponentFactory(
          CuiFeatureSingleNEditlineComponent
        );
      case 'Header': // cUI_Comp_Single_Header
        return this.componentFactoryResolver.resolveComponentFactory(
          CuiCompSingleHeaderComponent
        );
      default:
        console.log('No ComponentFactory for ' + widgetName);
        break;
    }
  }
}
