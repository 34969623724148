import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { RevertSelectItem } from '../store/ui/ui.actions';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';

@Component({
  selector: 'app-conflict-notification',
  templateUrl: './conflict-notification.component.html',
  styleUrls: ['./conflict-notification.component.scss']
})
export class ConflictNotificationComponent implements OnInit {
  @Input() add: boolean;
  @Input() remove: boolean;
  @Input() widget?: Widget;
  @Input() naviNode?: NaviNode;

  widgetExplanation = '';
  valueType = '';
  valueExplanation = '';
  values: any[] = [];

  constructor(
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.widget && this.widget.widgets) {
      this.widget.widgets.forEach((widget) => {
        this.widgetExplanation = widget.variable.validity.explanation;
        if (Array.isArray(widget.values)) {
          widget.values.forEach((value) => {
            if (value.isSelected && value.validity.explanation && value.cType) {
              this.values.push({
                type: value.cType.toString(),
                explanation: value.validity.explanation
              });
              // this.valueType = value.cType.toString();
              // this.valueExplanation = value.validExplanation;
            }
          });
        }
      });
    }
  }

  public reject() {
    this.store.dispatch(new RevertSelectItem());
  }
}
