import {
  Collapse,
  Expand,
  SelectItem,
  SelectItemState,
  SelectNavinode,
  StartSession,
  StopSession,
  LoadConfig,
  RevertSelectItem
} from './ui.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ConfiguratorSetup, StopSessionSetup } from '../lead/lead.model';
import { PromotionUIState } from '../promotionUi/promotionUi.state';
import { PriceService } from 'src/app/services/price.service';
import { CamosService } from '../../services/camos.service';
import { UIModel, Widget, NaviNode } from './ui.model';
import { Injectable } from '@angular/core';
import { AdobeAnalyticsManager } from 'src/app/adobe/adobe-analytics-manager';
import { firstValueFrom } from 'rxjs';

@State<UIModel>({
  name: 'ui'
})
@Injectable()
export class UIState {
  previousState: UIModel = null;

  selectItemHistory: SelectItem[] = [];

  constructor(
    private camos: CamosService,
    private store: Store,
    private promotionUi: PromotionUIState,
    private priceService: PriceService,
    private adobeAnalyticsManager: AdobeAnalyticsManager
  ) {}

  ngxsOnInit() {}

  @Selector()
  public static start(state: UIModel): UIModel {
    return state;
  }

  @Selector()
  public static widgets(state: UIModel): Widget[] {
    return state.widgets;
  }

  @Selector()
  public static navinodes(state: UIModel): NaviNode[] {
    return state.naviWidgets[0].navinodes;
  }

  @Selector()
  public static currentNaviNodeIndex(state: UIModel): number {
    return state.naviWidgets[0].navinodes.findIndex(
      (node) => node.isSelected == 1
    );
  }

  @Selector()
  public static nextNaviNode(state: UIModel): NaviNode | void {
    const curr = state.naviWidgets[0].navinodes.findIndex(
      (node) => node.isSelected == 1
    );
    if (state.naviWidgets[0].navinodes.length > curr + 1) {
      return state.naviWidgets[0].navinodes[curr + 1];
    } else {
      return null;
    }
  }

  @Selector()
  public static prevNaviNode(state: UIModel): NaviNode | void {
    const curr = state.naviWidgets[0].navinodes.findIndex(
      (node) => node.isSelected == 1
    );
    if (curr > 0) {
      return state.naviWidgets[0].navinodes[curr - 1];
    } else {
      return null;
    }
  }

  @Action(StartSession)
  async startSession(ctx: StateContext<UIModel>, action: ConfiguratorSetup) {
    try {
      if (action.configId && action.configId.length > 0) {
        await this.camos.startSession('', action.configId);
        let uis = await this.camos.getUis(action.country, action.language);
        uis.forEach((ui) => {
          switch (ui.category) {
            case 'TAP':
              ctx.setState(ui);
              break;
            case 'PROMO':
              this.promotionUi.promotionsUi = ui;
              break;
            default:
              break;
          }
        });
      } else {
        if (action.ver && action.verproduct && action.environment) {
          await this.camos.startSession(
            action.product,
            '',
            action.ver,
            action.verproduct,
            action.environment
          );
        } else {
          await this.camos.startSession(action.product, '');
        }
        let uis = await this.camos.getUis(action.country, action.language);
        let currUI;
        uis.forEach((ui) => {
          switch (ui.category) {
            case 'TAP':
              if (!action.baseModel) {
                ctx.setState(ui);
              }
              currUI = ui;
              break;
            case 'PROMO':
              this.promotionUi.promotionsUi = ui;
              break;
            default:
              break;
          }
        });
        if (action.baseModel) {
          // let ui = ctx.getState();
          const nextState = await this.camos.selectItemForWidget(
            action.baseModel,
            currUI.widgets[0].id,
            1,
            action.country
          );
          this.priceService.structure = await this.camos.getReviewStructure(
            action.country
          );
          this.priceService.price = this.priceService.calculateTotalPrice(
            this.priceService.structure
          );
          ctx.setState(nextState);
        }
      }
    } catch (error) {
      this.adobeAnalyticsManager.setErrorData();
      // console.log('CAMOS SESSION ERROR!');
      throw 'Camos Error';
    }
  }

  @Action(StopSession)
  stopSession(ctx: StateContext<UIModel>, action: StopSessionSetup) {
    // this.camos.stopSession(action.session).subscribe();
    firstValueFrom(this.camos.stopSession(action.session)).then(() => {
      let ui: UIModel;
      ctx.setState(ui);
    });
  }

  @Action(LoadConfig)
  async loadConfig(ctx: StateContext<UIModel>, action: LoadConfig) {
    await this.camos.loadConfiguration(action.configuuid);
    const resp = await this.camos.getProducts(action.language);
    // const uis = await this.camos.instantiateUI(resp['products'][0].id, action.country);
    let uis = await this.camos.getUis(action.country, action.language);
    ctx.setState(uis[0]);
    return uis[0];
  }

  @Action(SelectNavinode)
  async selectNavinode(ctx: StateContext<UIModel>, action: SelectNavinode) {
    const nextState: UIModel = await this.camos.selectNavinode(
      action.navinodeId,
      this.store.snapshot().lead.configuratorSetup.country
    );
    ctx.setState(nextState);
  }

  //! NOT IN USE, COULD BE DELETED
  @Action(SelectItem)
  async selectItem(ctx: StateContext<UIModel>, action: SelectItem) {
    const nextState = await this.camos.selectItemForWidget(
      action.itemName,
      action.widgetId,
      action.quantity,
      this.store.snapshot().lead.configuratorSetup.country
    );
    console.log('nextState1', nextState);
    ctx.setState(nextState);

    console.log('done');

    console.log('action.itemName', action.itemName);
    console.log('action.widgetType: ', action.widgetType);
    console.log('action.prevSelected: ', action.prevSelected);
    console.log('action.widgetId: ', action.widgetId);
    console.log('action.previousQuantity: ', action.previousQuantity);
    console.log('action.quantity: ', action.quantity);
    if (this.selectItemHistory.length > 10) {
      this.selectItemHistory.shift();
    }
    if (action.widgetType == 'cUI_Comp_Single_Table_C') {
      this.selectItemHistory.push(
        Object.assign(
          {},
          {
            itemName: action.prevSelected,
            widgetId: action.widgetId,
            quantity: action.quantity,
            previousQuantity: action.previousQuantity
          }
        )
      );
    } else {
      this.selectItemHistory.push(Object.assign({}, action));
    }
  }

  @Action(SelectItemState)
  SelectItemState(ctx: StateContext<UIModel>, action: SelectItemState) {
    // const nexState = await action.nextState
    ctx.setState(action.nextState);
    // console.log('done')
    // console.log("action.nextState", action.nextState)
    // console.log("action.itemName", action.itemName)
    // console.log('action.widgetType: ', action.widgetType);
    // console.log('action.prevSelected: ', action.prevSelected);
    // console.log('action.widgetId: ', action.widgetId);
    // console.log('action.previousQuantity: ', action.previousQuantity);
    // console.log('action.quantity: ', action.quantity);

    if (this.selectItemHistory.length > 10) {
      this.selectItemHistory.shift();
    }
    if (action.widgetType == 'cUI_Comp_Single_Table_C') {
      this.selectItemHistory.push(
        Object.assign(
          {},
          {
            itemName: action.prevSelected,
            widgetId: action.widgetId,
            quantity: action.quantity,
            previousQuantity: action.previousQuantity
          }
        )
      );
    } else {
      this.selectItemHistory.push(Object.assign({}, action));
    }
  }

  @Action(Expand)
  async expand(ctx: StateContext<UIModel>, action: Expand) {
    const nextState = await this.camos.expandOrCollapseWidget(
      action.widgetId,
      'expand',
      this.store.snapshot().lead.configuratorSetup.country
    );
    ctx.setState(nextState);
  }

  @Action(Collapse)
  async collapse(ctx: StateContext<UIModel>, action: Collapse) {
    const nextState = await this.camos.expandOrCollapseWidget(
      action.widgetId,
      'collapse',
      this.store.snapshot().lead.configuratorSetup.country
    );
    ctx.setState(nextState);
  }

  @Action(RevertSelectItem)
  async revertSelectItem(ctx: StateContext<UIModel>) {
    const lastSelectItemAction = this.selectItemHistory.pop();
    if (lastSelectItemAction) {
      const nextState = await this.camos.selectItemForWidget(
        lastSelectItemAction.itemName,
        lastSelectItemAction.widgetId,
        lastSelectItemAction.previousQuantity,
        this.store.snapshot().lead.configuratorSetup.country
      );
      ctx.setState(nextState);
    }
  }
}
