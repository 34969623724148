<!--
<p>{{widget.widgetType}}</p>
<p>{{widget.variable.name}}</p>
<ul>
  <li 
  *ngFor="let item of widget.items" 
  (click)="selectItem(item.name)"
  style="cursor: pointer;">
  {{isSelected(item.nameDisplay) ? '[x]' : "[ ]"}} {{item.name}} - {{item.nameDisplay}}
  </li>
</ul>
-->

<!--
<div class="module-table-card table-striped module-tap-multi-select-quantity" role="table">
  <div class="table-header">
    <div class="table-group">
      <div class="table-group-row">
        <div class="table-group-col col-2" data-key="header">
          <div class="table-row">
            <div class="table-header-col col-12" data-key="multi" title="Multi-Select"><span>Multi-Select possible</span></div>
          </div>
        </div>
        <div class="table-group-col col-3" data-key="header">
          <div class="table-header-col col-12" data-key="product" title="Product"><span>PRODUCT</span></div>
        </div>
        <div class="table-group-col col-5" data-key="header">
          <div class="table-header-col col-12" data-key="description" title="Description">DESCRIPTION</div>
        </div>
        <div class="table-group-col col-2" data-key="header">
          <div class="table-header-col col-12" data-key="price" title="Price"><span>PRICE</span></div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-body">
    <div class="product-list-container">
      <div class="table-group product-list-item" role="rowgroup">
        <div class="table-group-row" *ngFor="let item of widget.values; let i = index">
          <div class="table-group-col odd col-2">
            <div class="table-row" role="row">
              <div class="table-col col-12" data-key="multi">
                <div class="table-col-body" role="cell">
                  <div class="product-group">
                    <div class="product-group-content product-text">
                      <div class="product-title" *ngIf="!item.quantity && item.quantity != 0">
                        <a id="{{item.name}}" (click)="selectItem(item.name)" *ngIf="item.isSelected == 0" style="vertical-align: middle; color: #009bea; cursor: pointer;">
                          <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
                            <polygon points="19,11 13,11 13,5 11,5 11,11 5,11 5,13 11,13 11,19 13,19 13,13 19,13 " style="fill:#009bea;"/>
                          </svg>
                          {{ 'widgets.addToConfiguration' | i18next }}
                        </a>
                        <div *ngIf="item.isSelected == 1">
                          <div style="vertical-align: middle; display: inline-block;">
                            <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
                              <polygon points="9.5,19 4,13.5 6,11.5 9.5,15 19,5.5 21,7.5 " style="fill:#44a63d;" />
                            </svg>
                            <span style="vertical-align: middle; color: #44a63d;">
                              {{ 'widgets.added' | i18next }}
                            </span>
                          </div>
                          <a id="{{item.name}}" (click)="selectItem(item.name)" style="vertical-align: middle; color: #009bea; cursor: pointer; margin-left: 25px;">
                            Remove
                          </a>
                        </div>
                      </div>
                      <div class="product-title" *ngIf="item.quantity || item.quantity == 0">
                        <div class="counter-quantity">
                          <span class="plus rsi rsi-plus" (click)="changeQuantity(item.name, '+')"></span>
                          <input id="select_{{item.name}}" class="counter" type="text" [value]="item.quantity" (change)="changeQuantity(item.name)">
                          <span class="minus rsi rsi-minus" (click)="changeQuantity(item.name, '-')"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-group-col odd col-3">
            <div class="table-row" role="row">
              <div class="table-col col-12" data-key="product">
                <div class="table-col-header" role="columnheader">Product</div>
                <div class="table-col-body" role="cell"><label for="{{item.name}}" style="font-weight: bold;"> {{item.name}} </label></div>
              </div>
            </div>
          </div>
          <div class="table-group-col odd col-5">
            <div class="table-row" role="row">
              <div class="table-col col-12" data-key="description">
                <div class="table-col-header" role="columnheader">Description</div>
                <div class="table-col-body" role="cell">{{item.nameDisplay}}</div>
              </div>
            </div>
          </div>
          <div class="table-group-col odd col-2">
            <div class="table-row" role="row">
              <div class="table-col col-12" data-key="price">
                <div class="table-col-header" role="columnheader">Price</div>
                <div class="table-col-body" role="cell"> + {{item.price}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->

<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <div class="module-table-card table-striped module-tap-multi-select-quantity">
    <div class="table-header">
      <div class="table-group">
        <div class="table-group-row">
          <div class="table-group-col col-4">
            <div class="table-row">
              <div class="table-header-col col-6">
                <span>{{
                  'general.table.header.multiSelectPossible' | i18next
                }}</span>
              </div>
              <div class="table-header-col col-6">
                <span>{{ 'general.table.header.product' | i18next }}</span>
              </div>
            </div>
          </div>
          <div class="table-group-col col-8">
            <div class="table-row">
              <div class="table-header-col col-7">
                <span>{{ 'general.table.header.description' | i18next }}</span>
              </div>
              <div class="table-header-col col-3">
                <span>{{ 'general.table.header.price' | i18next }}</span>
              </div>
              <div class="table-header-col col-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-body">
      <div class="product-list-container">
        <div
          class="table-group product-list-item"
          *ngFor="let item of widget.values; let i = index"
        >
          <div
            class="table-group-row"
            *ngIf="item.validity.type !== 'InvisibleOnly'"
          >
            <div
              class="table-group-col col-4"
              [style.opacity]="
                item.validity.type === 'MayNot' ||
                widget.variable.validity.type === 'Must'
                  ? 0.5
                  : 1
              "
            >
              <div class="table-row">
                <!--
                  <div class="table-col col-6">
                    <div *ngIf="item.quantity || item.quantity == 0">
                      <div class="counter-quantity" style="margin-top: 0px;">
                        <div class="counter-quantity" [style]="item.validType === 'MayNot' || widget.variable.validType === 'Must' ? 'pointer-events: none; cursor: default;' : 'cursor: pointer;'">
                          <span class="minus rsi rsi-minus" (click)="subQuantity(item.name, item.quantity)"></span>
                          <input id="select_{{item.name}}" class="counter" type="text" [value]="item.quantity" (change)="changeQuantity(item.name, item.quantity)">
                          <span class="plus rsi rsi-plus" (click)="addQuantity(item.name, item.quantity)"></span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!item.quantity && item.quantity != 0"  style="margin-top: 8px;">
                      <a *ngIf="!item.isSelected" class="tap-tertiary-btn" (click)="selectItem(item.name)" [style]="item.validType === 'MayNot' || widget.variable.validType === 'Must' ? 'pointer-events: none; cursor: default;' : 'cursor: pointer;'">
                        <div class="rsi rsi-plus"></div>{{ 'widgets.addToConfiguration' | i18next }}
                      </a>
                      <a *ngIf="item.isSelected" class="tap-tertiary-btn checked">
                        <div class="rsi rsi-plus"></div>
                        {{ 'widgets.added' | i18next }}
                      </a>
                      <a *ngIf="item.isSelected" class="tap-tertiary-btn" style="cursor: pointer; display: inline-block; margin-left: 10px;" (click)="selectItem(item.name)">
                        <div class="rsi rsi-close"></div>
                        Remove
                      </a>
                    </div>
                  </div>
                  <div class="table-col col-6">
                    <div class="table-col-body">
                      <div class="product-group">
                        <div class="product-group-content product-text">
                          <p><strong>{{item.cType}}&nbsp;</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  -->
                <div class="table-col col-6">
                  <div
                    class="product-text-controls"
                    style="
                      margin: 0px;
                      position: static;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <div *ngIf="item.quantity || item.quantity == 0">
                      <div class="counter-quantity" style="margin-top: 5px">
                        <div
                          class="counter-quantity"
                          [style]="
                            item.validity.type === 'MayNot' ||
                            widget.variable.validity.type === 'Must'
                              ? 'pointer-events: none; cursor: default;'
                              : 'cursor: pointer;'
                          "
                        >
                          <span
                            class="minus rsi rsi-minus"
                            style="font-size: 0.875em"
                            (click)="subQuantity(item.name, item.quantity)"
                          ></span>
                          <input
                            id="select_{{ item.name }}"
                            class="counter"
                            type="text"
                            style="
                              min-width: 30px;
                              height: 30px;
                              margin-top: 1px;
                            "
                            [value]="item.quantity"
                            (change)="changeQuantity(item.name, item.quantity)"
                          />
                          <span
                            class="plus rsi rsi-plus"
                            style="font-size: 0.875em"
                            (click)="addQuantity(item.name, item.quantity)"
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="!item.quantity && item.quantity != 0"
                      style="margin-top: 0px"
                    >
                      <a
                        *ngIf="!item.isSelected"
                        class="tap-tertiary-btn"
                        (click)="selectItem(item.name)"
                        [style]="
                          item.validity.type === 'MayNot' ||
                          widget.variable.validity.type === 'Must'
                            ? 'pointer-events: none; cursor: default;'
                            : 'cursor: pointer;'
                        "
                      >
                        <div class="rsi rsi-plus"></div>
                        {{ 'widgets.addToConfiguration' | i18next }}
                      </a>
                      <a
                        *ngIf="item.isSelected"
                        class="tap-tertiary-btn checked"
                      >
                        <div class="rsi rsi-plus"></div>
                        {{ 'widgets.added' | i18next }}
                      </a>
                      <a
                        *ngIf="item.isSelected"
                        class="tap-tertiary-btn"
                        style="
                          cursor: pointer;
                          display: inline-block;
                          margin-left: 10px;
                        "
                        (click)="selectItem(item.name)"
                      >
                        <div class="rsi rsi-close"></div>
                        {{ 'widgets.remove' | i18next }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="table-col col-6">
                  <div class="table-col-body">
                    <div class="product-group">
                      <div class="product-group-content product-text">
                        <p style="margin: 5px 0px">
                          <strong>{{ item.cType }}&nbsp;</strong>
                        </p>
                      </div>
                      <!--
                        <div class="product-text-controls nowrap" style="margin-top: 0px;">
                          <div *ngIf="item.quantity || item.quantity == 0">
                            <div class="counter-quantity" style="margin-top: 5px;">
                              <div class="counter-quantity" [style]="item.validity.type === 'MayNot' || widget.variable.validity.type === 'Must' ? 'pointer-events: none; cursor: default;' : 'cursor: pointer;'">
                                <span class="minus rsi rsi-minus" (click)="subQuantity(item.name, item.quantity)"></span>
                                <input id="select_{{item.name}}" class="counter" type="text" [value]="item.quantity" (change)="changeQuantity(item.name, item.quantity)">
                                <span class="plus rsi rsi-plus" (click)="addQuantity(item.name, item.quantity)"></span>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="!item.quantity && item.quantity != 0"  style="margin-top: 0px;">
                            <a *ngIf="!item.isSelected" class="tap-tertiary-btn" (click)="selectItem(item.name)" [style]="item.validity.type === 'MayNot' || widget.variable.validity.type === 'Must' ? 'pointer-events: none; cursor: default;' : 'cursor: pointer;'">
                              <div class="rsi rsi-plus"></div>{{ 'widgets.addToConfiguration' | i18next }}
                            </a>
                            <a *ngIf="item.isSelected" class="tap-tertiary-btn checked">
                              <div class="rsi rsi-plus"></div>
                              {{ 'widgets.added' | i18next }}
                            </a>
                            <a *ngIf="item.isSelected" class="tap-tertiary-btn" style="cursor: pointer; display: inline-block; margin-left: 10px;" (click)="selectItem(item.name)">
                              <div class="rsi rsi-close"></div>
                              {{ 'widgets.remove' | i18next }}
                            </a>
                          </div>
                        </div>
                        -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="table-group-col col-8"
              [style.opacity]="
                item.validity.type === 'MayNot' ||
                widget.variable.validity.type === 'Must'
                  ? 0.5
                  : 1
              "
            >
              <div class="table-row">
                <div class="table-col col-7" style="width: calc(100% - 48px)">
                  <div class="table-col-header">
                    {{ 'widgets.heade.description' | i18next }}
                  </div>
                  <div class="table-col-content">
                    <span>{{ item.naming }}</span>
                  </div>
                </div>
                <div class="table-col col-3">
                  <div class="table-col-header">
                    {{ 'general.table.header.price' | i18next }}
                  </div>
                  <div class="table-col-content">
                    <span
                      >+
                      {{
                        camosConfiguratorComponent.numberWithCommas(item.price)
                      }}</span
                    >
                  </div>
                </div>
                <div class="table-col col-2">
                  <div class="table-col-header"></div>
                  <div class="table-col-content">
                    <app-validtype-tooltip
                      [validExplanation]="item.validity.explanation"
                      [validType]="item.validity.type"
                      [tooltipPosition]="'left'"
                    ></app-validtype-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
