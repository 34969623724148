import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';

@Component({
  selector: 'app-validtype-tooltip',
  templateUrl: './validtype-tooltip.component.html',
  styleUrls: ['./validtype-tooltip.component.scss']
})
export class ValidtypeTooltipComponent implements OnInit {
  @Input() validType: string;
  @Input() validExplanation: string;
  @Input() tooltipPosition: string;
  @ViewChild('tooltip') tooltip: ElementRef;

  constructor(
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    private cdRef: ChangeDetectorRef
  ) {}

  camosWrapperWidth = this.camosConfiguratorComponent.camosWrapperWidth;
  camosWrapperWidthCss = this.camosWrapperWidth - 15;
  camosWrapperPosLeft = this.camosConfiguratorComponent.camosWrapperPosLeft;
  private tooltipPosLeft: number;
  private tooltiptextTranslateX: number;
  public style: string;
  private tooltipPosUp = false;

  setTooltipCss() {
    if (this.tooltip) {
      if (this.camosWrapperWidth < 767) {
        this.tooltipPosLeft =
          this.tooltip.nativeElement.getBoundingClientRect().left;
        this.tooltiptextTranslateX =
          this.tooltipPosLeft - this.camosWrapperPosLeft - 7;

        this.style = `
          transform: translate(-${this.tooltiptextTranslateX}px, ${
            this.tooltipPosUp ? -130 : 5
          }%);
          -webkit-transform: translate(-${this.tooltiptextTranslateX}px, ${
            this.tooltipPosUp ? -130 : 5
          }%);
          -ms-transform: translate(-${this.tooltiptextTranslateX}px, ${
            this.tooltipPosUp ? -130 : 5
          }%)};
          width: ${this.camosWrapperWidthCss}px;
        `;
      } else {
        this.style = `
          transform: translate(-90%, ${this.tooltipPosUp ? -125 : 5}%);
          -webkit-transform: translate(-90%, ${this.tooltipPosUp ? -125 : 5}%);
          -ms-transform: translate(-90%, ${this.tooltipPosUp ? -125 : 5}%)};
        `;
      }
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() {}
  ngAfterViewInit() {}

  tooltipMouseEnter() {
    if (document.querySelector('#sticky-element')) {
      const stickyElTop = document
        .querySelector('#sticky-element')
        .getBoundingClientRect().top;
      const tooltipTop = this.tooltip.nativeElement.getBoundingClientRect().top;
      this.tooltipPosUp = stickyElTop - tooltipTop < 160;
      this.setTooltipCss();
    }
  }
}
