import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-erorr-modal',
  templateUrl: './erorr-modal.component.html',
  styleUrls: ['./erorr-modal.component.scss']
})
export class ErorrModalComponent implements OnInit {
  @Input() closeFunction: () => void;
  @Input() modalContent: any;

  constructor() {}

  ngOnInit(): void {}
}
