import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-error',
  templateUrl: './check-error.component.html',
  styleUrls: ['./check-error.component.scss']
})
export class CheckErrorComponent {
  public errorState: any;
  public configurationId: string;

  constructor() {}
}
