import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-ui',
  templateUrl: './test-ui.component.html',
  styleUrls: ['./test-ui.component.scss']
})
export class TestUiComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
