import { Component, OnInit } from '@angular/core';
import { State } from './error-model';
import { CamosService } from '../services/camos.service';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { SelectNavinode } from '../store/ui/ui.actions';

@Component({
  selector: 'app-errors-view',
  templateUrl: './errors-view.component.html',
  styleUrls: ['./errors-view.component.scss']
})
export class ErrorsViewComponent implements OnInit {
  public stateEnum = State;
  public errorState: any;
  public configurationId: string;

  constructor(
    public camos: CamosService,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    private store: Store
  ) {}

  async ngOnInit() {
    console.log('error code: ', this.store.snapshot().error.errorState);
    this.errorState = this.stateEnum[this.store.snapshot().error.errorState];
    console.log('error state: ', this.errorState);

    if (
      this.errorState != this.stateEnum.loadconfig &&
      this.errorState != this.stateEnum.configuration
    ) {
      console.log('was here!');
      await this.getConfigurationId();
    }
  }

  async getConfigurationId() {
    const resp = await this.camos.saveConfiguration();
    console.log(resp);
    this.configurationId = resp['configUUID'];
    localStorage.setItem('camosConfigurationID', this.configurationId);
  }

  public close() {
    this.store
      .dispatch(
        new SelectNavinode(
          this.store.snapshot().ui.naviWidgets[0].navinodes[0].id
        )
      )
      .subscribe();
    if (
      this.errorState != this.stateEnum.loadconfig &&
      this.errorState != this.stateEnum.configuration
    ) {
      this.camosConfiguratorComponent.openConfigurationCode();
    } else {
      this.camosConfiguratorComponent.showMainPage();
    }
  }
}
