import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-checbox-input',
  templateUrl: './checbox-input.component.html',
  styleUrls: ['./checbox-input.component.scss']
})
export class ChecboxInputComponent implements OnInit {
  public enabled = null;
  uuidNum = uuidv4();
  setEnabled(value: number) {
    this.enabled = value;
  }

  constructor() {}

  ngOnInit(): void {}
}
