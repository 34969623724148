import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-configurator-overview',
  templateUrl: './configurator-overview.component.html',
  styleUrls: ['./configurator-overview.component.scss']
})
export class ConfiguratorOverviewComponent implements OnInit {
  @Input('overview-setup') overviewSetup;
  @ViewChild('componentContainer') container: any;

  public partnerId: number;
  public productList: any;
  public displayConfigurator: boolean = false;

  constructor() {}

  ngOnInit(): void {
    console.log('Input:', JSON.parse(this.overviewSetup));
    this.productList = JSON.parse(this.overviewSetup).productList;
    this.partnerId = JSON.parse(this.overviewSetup).partnerId;
  }

  startConfigurator(config: any) {
    const component = document.createElement('tap-webcomponent');
    component.setAttribute('configurator-setup', `${config}`);
    this.container.nativeElement.innerHTML = '';
    this.container.nativeElement.appendChild(component);
    this.displayConfigurator = true;
  }
}
