import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Collapse, Expand } from 'src/app/store/ui/ui.actions';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';
import { UIState } from 'src/app/store/ui/ui.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cui-static-accordion',
  templateUrl: './cui-static-accordion.component.html',
  styleUrls: ['./cui-static-accordion.component.scss']
})
export class CuiStaticAccordionComponent implements CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;

  @Select(UIState.navinodes) navinodes$: Observable<NaviNode[]>;
  firstNavinode: boolean = false;

  public accordionOpen: boolean;
  public conflict = false;
  public columnsAuto = '';

  constructor(private store: Store) {
    this.navinodes$.subscribe((_navinodes) => {
      if (_navinodes[0].isSelected) {
        this.firstNavinode = true;
      }
    });
  }

  ngOnInit() {
    if (this.widget.layout && this.widget.layout.type === 'Grid') {
      for (let i = 0; i < this.widget.layout.gridColumns; i++) {
        if (i === 0) {
          this.columnsAuto = this.columnsAuto + '17% ';
        } else {
          this.columnsAuto = this.columnsAuto + 'auto ';
        }
      }
      this.columnsAuto = this.columnsAuto.trim();
    }
    if (this.firstNavinode && !this.widget.isExpanded) {
      this.store.dispatch(new Expand(this.widget.id));
    }
    if (this.widget.isExpanded != null && this.widget.isExpanded == 1) {
      this.accordionOpen = true;
    } else {
      this.accordionOpen = false;
    }

    if (this.widget.isValid) {
      this.conflict = false;
    } else {
      this.conflict = true;
    }
  }

  expandOrCollapse(event: Event) {
    const accordionTitle = event.target as HTMLElement;

    if (this.widget.isExpanded != null && this.widget.isExpanded == 1)
      this.store.dispatch(new Collapse(this.widget.id));

    if (this.widget.isExpanded != null && this.widget.isExpanded == 0)
      this.store.dispatch(new Expand(this.widget.id));

    this.accordionOpen === true
      ? (this.accordionOpen = false)
      : (this.accordionOpen = true);

    accordionTitle.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
