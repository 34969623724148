import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetErrorState } from './error.actions';
import { ErrorState } from './error.model';

@State<ErrorState>({
  name: 'error'
})
@Injectable()
export class ErrorHandlerState {
  constructor() {}

  ngxsOnInit(ctx: StateContext<Error>) {
    console.log('start error ngxs');
  }

  @Selector()
  public static setErrorState(errorState: number): number {
    return errorState;
  }

  @Action(SetErrorState)
  setFields(ctx: StateContext<number>, action: number) {
    const nextState = action;
    console.log('next state: ', nextState);
    ctx.setState(nextState);
  }
}
