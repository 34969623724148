<!-- Body -->
<div class="parent">
  <div class="div1">
    <p class="title">{{ 'general.table.header.modelName' | i18next }}</p>
    <p class="title">
      {{ 'general.table.header.product' | i18next }}
    </p>
    <p class="item-name">{{ item.productType }}</p>
  </div>
  <div class="div2">
    <p class="title">Ports</p>
    <p class="title">
      {{ 'review.header.matNo' | i18next }}
    </p>
    <p>{{ item.productID }}</p>
  </div>
  <div class="div3">
    <p class="title">
      {{ 'general.table.header.description' | i18next }}
    </p>
    <p class="title">Frequency</p>
    <p style="margin: 0px">{{ item.name }}</p>
  </div>
  <div class="div4">
    <p class="title">
      {{ 'review.header.quantity' | i18next }}
    </p>
    <p class="title">Connectors</p>
    <p>{{ item.quantity }}</p>
  </div>
  <div class="div5" *ngIf="!appComponent.isPrice">
    <p class="title">{{ 'general.table.header.price' | i18next }}</p>
    <p>On request</p>
  </div>
  <div class="div5" *ngIf="appComponent.isPrice">
    <p class="title">{{ 'general.table.header.price' | i18next }}</p>
    <p>+ {{ camosConfiguratorComponent.numberWithCommas(item.price) }}</p>
  </div>
</div>
