<div class="service-selection-wrapper">
  <div class="service-selection-left">
    <div class="service-selection-radio-wrapper">
      <input
        type="radio"
        [name]="'radioBtn' + nameInput"
        [value]="value"
        [checked]="isChecked"
        [id]="'radioId-' + uuidNum"
        (click)="setEnabled(value)"
      />
      <label [htmlFor]="'radioId-' + uuidNum">Contract duration1</label>
      <app-validtype-tooltip
        [validExplanation]="'test'"
        [validType]="'Must'"
        [tooltipPosition]="'left'"
      ></app-validtype-tooltip>
    </div>
  </div>
  <div class="service-selection-right">
    <select
      [name]="'select' + uuidNum"
      [disabled]="enabled !== value"
      *ngIf="inputType === 'select'"
    >
      <option value="1">1 year (EUR 1.750)</option>
      <option value="2">2 year (EUR 2.750)</option>
      <option value="3">3 year (EUR 3.750)</option>
    </select>
    <input
      type="date"
      [name]="'datepicker' + uuidNum"
      placeholder="Please choose a date"
      [disabled]="enabled !== value"
      *ngIf="inputType === 'datepicker'"
      (change)="datePickerChange($event)"
      [ngClass]="datePick && 'active'"
    />
  </div>
</div>
