<div id="error-view">
  <h2 class="shopping-cart-customerinfo-headline h2 bold">
    {{ 'error.header' | i18next }}
  </h2>
  <div class="shopping-cart-customerinfo-stage" id="webshop-cart-error-info">
    <div class="shopping-cart-customerinfo-stage-left">
      <app-icon
        name="errorCart"
        class="shopping-cart-customerinfo-stage-img"
      ></app-icon>
    </div>
    <div class="shopping-cart-customerinfo-stage-right">
      <div class="shopping-cart-customerinfo-stage-description">
        {{ 'error.insufficientRights' | i18next }}
      </div>
    </div>
  </div>
</div>
