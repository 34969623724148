<div *ngIf="this.configurationID">
  <div class="headline-container" style="text-align: center; margin-top: 30px">
    <h5 *ngIf="!leadMode">{{ 'configurationCode.save.header' | i18next }}</h5>
    <h3 *ngIf="leadMode">
      {{ 'configurationCode.save.leadHeader' | i18next }}
    </h3>
  </div>

  <div id="config" *ngIf="!leadMode" style="text-align: center">
    <p id="configCode" style="margin: 20px 0px">
      {{ this.configurationID }}
    </p>
    {{ 'configurationCode.save.description' | i18next }}
    <br />
    <table style="display: inline-block">
      <tr>
        <td class="configCodeTable">
          <div class="controls" style="margin: 20px">
            <a
              class="button-tertiary"
              style="cursor: pointer"
              (click)="copyToClipboard()"
              >{{ 'configurationCode.save.copy' | i18next }}</a
            >
          </div>
        </td>
        <td class="configCodeTable">
          <div class="controls" style="margin: 20px">
            <a
              class="button-tertiary"
              style="cursor: pointer"
              (click)="shareConfiguration()"
              >{{ 'configurationCode.save.share' | i18next }}</a
            >
          </div>
        </td>
      </tr>
    </table>
  </div>
  <!-- <div>
      <div class="controls" style="margin: 40px 0px 20px 0px; justify-content: center; text-align: center;">
          <a *ngIf="!leadMode" class="button-tertiary" (click)="requestQuoteSelected.openRequestQuoteSelected()" style="cursor: pointer;">{{ 'general.back' | i18next }}</a>
      </div>
  </div> -->

  <div *ngIf="leadMode && !saved" style="padding: 50px; text-align: center">
    {{ 'configurationCode.save.leadDescription' | i18next }}
    <br />
    <table style="display: inline-block">
      <tr>
        <td>
          <div class="controls" style="margin: 20px">
            <a
              class="button-primary"
              style="cursor: pointer"
              (click)="saveChanges()"
              >{{ 'general.save' | i18next }}</a
            >
          </div>
        </td>
        <td>
          <div class="controls" style="margin: 20px">
            <a
              class="button-secondary"
              style="cursor: pointer"
              (click)="cancelChanges()"
              >{{ 'general.cancel' | i18next }}</a
            >
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div *ngIf="saved" style="padding: 50px; text-align: center">
    {{ 'configurationCode.save.successfull' | i18next }}
    <br />
    {{ 'configurationCode.save.newConfigID' | i18next }}
    <p style="font-size: 20px" id="configCode">
      {{ this.configurationID }}
    </p>
    <table style="display: inline-block">
      <tr>
        <td>
          <div class="controls" style="margin: 20px">
            <a
              class="button-primary"
              style="cursor: pointer"
              (click)="closeAndUpdate()"
              >{{ 'general.close' | i18next }}</a
            >
          </div>
        </td>
      </tr>
    </table>
  </div>

  <!-- <div>
      <div class="controls" style="margin: 40px 0px 20px 0px; justify-content: center; text-align: center;">
        <a *ngIf="leadMode" (click)="camosConfiguratorComponent.goBackRequestQuote()" style="cursor: pointer;">{{ 'general.back' | i18next }}</a>
      </div>
  </div> -->
</div>
