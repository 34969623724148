<app-camos-configurator
  *ngIf="check && !load"
  [openCode]="_configuratorSetup.product"
  [leadMode]="_configuratorSetup.leadMode"
  [configId]="_configuratorSetup.configId"
  [bundleMode]="_configuratorSetup.bundleMode"
  [msku]="_configuratorSetup.msku"
  [malias]="_configuratorSetup.malias"
  [uis]="_configuratorSetup.uis"
  [country]="_configuratorSetup.country"
></app-camos-configurator>

<app-check-error *ngIf="!check"></app-check-error>

<ngx-spinner
  name="spinner"
  type="ball-clip-rotate"
  bdColor="rgba(0, 0, 0, 0)"
  color="#009bea"
  [fullScreen]="false"
>
</ngx-spinner>
