<div class="checkbox-input">
  <app-checkbox-input-single
    (emitEnabled)="setEnabled($event)"
    [enabled]="enabled"
    [nameInput]="uuidNum"
    [uuidNum]="uuidNum"
    [value]="uuidNum"
    [isChecked]="true"
    inputType="select"
  >
  </app-checkbox-input-single>
  <app-checkbox-input-single
    (emitEnabled)="setEnabled($event)"
    [enabled]="enabled"
    [nameInput]="uuidNum"
    [uuidNum]="uuidNum + 1"
    [value]="uuidNum + 1"
    [isChecked]="false"
    inputType="datepicker"
  >
  </app-checkbox-input-single>
</div>
