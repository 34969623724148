<div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"></div>
<div
  class="mfp-container mfp-s-ready mfp-inline-holder"
  style="z-index: 99000; position: fixed"
>
  <div class="mfp-content" style="z-index: 99000">
    <div class="add-to-cart-dialog" style="z-index: 99000">
      <div class="module tap-notification">
        <div class="wrapper">
          <div class="content">
            <p class="headline h5">{{ modalContent?.title }}:</p>
            <div class="text">
              <p>{{ modalContent?.body }}</p>
              <p>{{ modalContent?.additionalBody }}</p>
            </div>
            <div class="button-container">
              <a class="button-primary" (click)="closeFunction()">{{
                'conflictNotification.ok' | i18next
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
