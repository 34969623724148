<div
  class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
  style="
    overflow: hidden auto;
    background-color: gray;
    opacity: 0.8;
    z-index: 99000;
  "
></div>
<div
  class="mfp-container mfp-s-ready mfp-inline-holder"
  style="z-index: 99000; position: fixed"
>
  <div class="mfp-content" style="z-index: 99000">
    <div class="add-to-cart-dialog" style="z-index: 99000">
      <div class="module tap-notification">
        <div class="wrapper">
          <div class="content" *ngIf="widget">
            <p class="headline h5">
              {{ 'conflictNotification.header' | i18next }}
            </p>
            <div class="text">
              <p>
                {{ 'conflictNotification.editing' | i18next }}
                {{ 'conflictNotification.rs' | i18next }}
                {{ widget.variable.naming }}
                {{ 'conflictNotification.followingOptionsInvalid' | i18next }}:
              </p>
              <ul>
                <li>
                  {{ 'conflictNotification.rs' | i18next }}
                  {{ widget.variable.naming }}: {{ widgetExplanation }}
                </li>
                <li *ngFor="let value of values">
                  {{ 'conflictNotification.rs' | i18next }} {{ value.type }}:
                  {{ value.explanation }}
                </li>
              </ul>
              <p>{{ 'conflictNotification.reject' | i18next }}</p>
            </div>
            <div class="button-container">
              <a class="button-primary" (click)="reject()">{{
                'conflictNotification.ok' | i18next
              }}</a>
            </div>
          </div>
          <div class="content" *ngIf="naviNode">
            <p class="headline h5">
              {{ 'conflictNotification.header' | i18next }}
            </p>
            <div class="text">
              <p>
                {{ 'conflictNotification.editing' | i18next }}
                {{ 'conflictNotification.followingOptionsInvalid' | i18next }}
              </p>
              <p>{{ 'conflictNotification.reject' | i18next }}</p>
            </div>
            <div class="button-container">
              <a class="button-primary" (click)="reject()">{{
                'conflictNotification.ok' | i18next
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
