<div class="service-selection-main">
  <div class="service-selection">
    <div class="service-selection-container">
      <app-service-selection-checkbox></app-service-selection-checkbox>
    </div>
    <div class="service-selection-container">
      <app-checbox-input></app-checbox-input>
    </div>
    <div class="service-selection-price">
      <span>Position total EUR 1.750</span>
    </div>
  </div>
  <div class="service-selection">
    <div class="service-selection-container">
      <app-service-selection-checkbox></app-service-selection-checkbox>
    </div>
    <div class="service-selection-container">
      <app-service-selection-sub-checkbox></app-service-selection-sub-checkbox>
      <app-service-selection-datepicker></app-service-selection-datepicker>
    </div>
    <div class="service-selection-container">
      <app-checbox-input></app-checbox-input>
    </div>
    <div class="service-selection-price">
      <span>Position total EUR 1.750</span>
    </div>
  </div>
</div>
