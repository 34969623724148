import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamosService } from 'src/app/services/camos.service';
import { PriceService } from 'src/app/services/price.service';
import { SelectItemState } from 'src/app/store/ui/ui.actions';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';

@Component({
  selector: 'app-cui-feature-single-n-editline',
  templateUrl: './cui_feature_single_n_editline.component.html',
  styleUrls: ['./cui_feature_single_n_editline.component.scss']
})
export class CuiFeatureSingleNEditlineComponent implements CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  timer: any;

  constructor(
    private store: Store,
    private priceService: PriceService,
    private spinner: NgxSpinnerService,
    private camosService: CamosService
  ) {}

  ngOnInit() {}

  addQuantity(name: number): void {
    let id = 'select_' + name;

    (document.getElementById(id) as any).value++;
    // clearTimeout(this.timer);
    this.safeQuantity(id);
  }

  changeQuantity(name: number): void {
    let id = 'select_' + name;

    // clearTimeout(this.timer);
    this.safeQuantity(id);
  }

  subQuantity(name: number): void {
    let id = 'select_' + name;

    if ((document.getElementById(id) as any).value !== '0') {
      (document.getElementById(id) as any).value--;
    }
    // clearTimeout(this.timer);
    this.safeQuantity(id);
  }

  async safeQuantity(id: string) {
    const name = (document.getElementById(id) as any).value;
    // this.store.dispatch(new SelectItem((document.getElementById(id) as any).value, this.widget.id));
    this.spinner.show('sticky-spinner');
    document.getElementById('priceBox').style.color = 'lightgray';

    const nextState = await this.camosService.getItemForWidget(
      name,
      this.widget.id,
      1,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(new SelectItemState(nextState, name, this.widget.id));

    const price = await this.priceService.setPriceTotal();
    this.priceService.updatePriceTotal(price);

    document.getElementById('priceBox').style.color = '#009bea';
    this.spinner.hide('sticky-spinner');
  }
}
