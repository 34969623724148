<div class="service-selection-wrapper">
  <div class="service-selection-left">
    <p class="service-selection-title">Select software maintenance period</p>
  </div>
  <div class="service-selection-right">
    <div class="service-selection-radio-wrapper">
      <input
        type="radio"
        [name]="'radio' + uuidNum"
        [id]="'id-' + uuidNum"
        checked
      />
      <label [for]="'id-' + uuidNum">Standard warranty only</label>
      <app-validtype-tooltip
        [validExplanation]="'test'"
        [validType]="'Must'"
        [tooltipPosition]="'left'"
      ></app-validtype-tooltip>
    </div>
    <div class="service-selection-radio-wrapper">
      <input
        type="radio"
        [name]="'radio' + uuidNum"
        [id]="'id-' + uuidNum + 1"
      />
      <label [for]="'id-' + uuidNum + 1"
        >Extended software maintenance after warranty</label
      >
      <app-validtype-tooltip
        [validExplanation]="'test'"
        [validType]="'Must'"
        [tooltipPosition]="'left'"
      ></app-validtype-tooltip>
    </div>
  </div>
</div>
