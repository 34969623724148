<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <div
    *ngIf="widget.xtIsBaseList && !promotion"
    class="module tap-base-model-select"
  >
    <div
      class="wrapper"
      style="display: flex; flex-direction: column; gap: 30px"
    >
      <app-base-product-table
        [baseValues]="baseValues"
        [properties]="properties"
      ></app-base-product-table>
      <h3 *ngIf="appComponent._configuratorSetup?.uis?.includes('PROMO')">
        {{ 'startPage.promotions.header' | i18next }}
      </h3>
      <app-camos-widgets [promotion]="true"></app-camos-widgets>
    </div>
  </div>

  <app-option-table
    [widget]="widget"
    [values]="widget.values"
    [type]="'radio'"
    *ngIf="!widget.xtIsBaseList"
  ></app-option-table>

  <div *ngIf="widget.xtIsBaseList && promotion">
    <div class="module-table-card" style="border: 1px solid #cbcbcb">
      <div class="table-header">
        <div class="table-group">
          <div class="table-group-row">
            <div class="table-group-col col-3">
              <div class="table-row">
                <div class="table-header-col col-12">
                  <span>{{ 'general.table.header.modelName' | i18next }}</span>
                </div>
              </div>
            </div>
            <div class="table-group-col col-9">
              <div class="table-row">
                <div class="table-group-col col-4">
                  <div
                    class="table-header-col"
                    [style]="
                      'display: inline-block; width: calc(' +
                      width +
                      '%' +
                      ' - 48px); vertical-align: top; padding: 22px 24px;'
                    "
                    *ngFor="let property of properties"
                  >
                    <span>{{ property.description }}</span>
                  </div>
                </div>
                <div class="table-header-col col-2">
                  <span>{{
                    'general.table.header.regionalAvailability' | i18next
                  }}</span>
                </div>
                <div class="table-header-col col-2">
                  <span>{{ 'general.table.header.discount' | i18next }}</span>
                </div>
                <div class="table-header-col col-2">
                  <span>{{ 'general.table.header.price' | i18next }}</span>
                </div>
                <div class="table-header-col col-2"><span></span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-body">
        <div class="product-list-container">
          <ng-container  *ngFor="let item of baseValues" >
          <div
            class="table-group product-list-item"
            *ngIf="item.cType"
          >
            <div class="table-group-row">
              <div
                style="width: 100%"
                [style.opacity]="
                  item.validity.type === 'MayNot' || oneMust ? 0.5 : 1
                "
                *ngIf="item.validity.type !== 'InvisibleOnly'"
              >
                <div
                  class="table-group-col odd col-3"
                  style="display: inline-block; width: 100%"
                >
                  <div class="table-row">
                    <div
                      class="table-col col-12"
                      [style.padding-top]="
                        item.validity.type === 'May' ? 5 : 10
                      "
                      [style.padding-bottom]="
                        item.validity.type === 'May' ? 5 : 10
                      "
                    >
                      <div class="table-col-body">
                        <div class="product-group">
                          <app-validtype-tooltip
                            *ngIf="item.validity.type !== 'May'"
                            [validType]="item.validity.type"
                            [tooltipPosition]="'right'"
                          ></app-validtype-tooltip>
                          <div
                            class="product-group-content product-text promotion"
                          >
                            <div class="product-title-wrapper">
                              <div class="product-title">
                                <strong>{{ item.cType }}</strong>
                              </div>
                              <div class="product-order-number"></div>
                              <div class="order-number-text messages-stashed">
                                <p>
                                  {{ 'widgets.bodyOrderNumber' | i18next
                                  }}<span class="order-no">
                                    {{ item.name }}</span
                                  >
                                </p>
                              </div>
                              <div class="">
                                <app-sales-text
                                  [salesText]="item.salestext"
                                  [showText]="
                                    'widgets.showBundleDescription' | i18next
                                  "
                                  [hideText]="
                                    'widgets.hideBundleDescription' | i18next
                                  "
                                ></app-sales-text>
                              </div>
                              <!-- <div *ngIf="!item.isSelected">
                                <a class="button-tertiary downButton" (click)="selectPromoItem(item.name)">{{ 'widgets.showBundleDescription' | i18next }}</a>
                              </div>
                              <div *ngIf="item.isSelected">
                                <a
                                  class="button-tertiary upButton"
                                  (click)="selectPromoItem(null)"
                                  >{{
                                    'widgets.hideBundleDescription' | i18next
                                  }}</a
                                ><br />
                                <span style="white-space: pre-line">
                                  {{ item.salestext }}
                                </span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="table-group-col odd col-9"
                  style="display: inline-block; width: 100%"
                >
                  <div class="table-row">
                    <div class="table-group-col odd col-4">
                      <div class="table-row">
                        <div
                          class="table-col"
                          [style]="
                            'display: inline-block; width: calc(' +
                            width +
                            '%' +
                            ' - 48px); vertical-align: top; padding: 22px 24px;'
                          "
                          *ngFor="let property of properties"
                        >
                          <div class="table-col-header">
                            {{ property.description }}
                          </div>
                          <div class="table-col-body" style="text-align: left">
                            {{ item[property.name] }} &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-col col-2">
                      <div class="table-col-header">
                        {{
                          'general.table.header.regionalAvailability' | i18next
                        }}
                      </div>
                      <div class="table-col-body">{{ item.availability }}</div>
                    </div>
                    <div class="table-col col-2">
                      <div class="table-col-header">
                        {{ 'general.table.header.discount' | i18next }}
                      </div>
                      <div class="table-col-body">{{ item.discount }}</div>
                    </div>
                    <div class="table-col col-2">
                      <div class="table-col-header">
                        {{ 'general.table.header.price' | i18next }}
                      </div>
                      <div
                        class="table-col-body"
                        *ngIf="
                          appComponent.isPrice || promotionComponent.isPrice
                        "
                      >
                        <span
                          *ngIf="!item.promoPrice?.includes('price on request')"
                          ><del>{{
                            camosConfiguratorComponent.numberWithCommas(
                              item.promoPrice
                            )
                          }}</del
                          ><br /></span
                        >{{
                          camosConfiguratorComponent.numberWithCommas(
                            item.price
                          )
                        }}
                      </div>
                      <div
                        class="table-col-body"
                        *ngIf="
                          !appComponent.isPrice && !promotionComponent.isPrice
                        "
                      >
                        <span>On request</span>
                      </div>
                    </div>
                    <div
                      class="table-col col-2"
                      id="requestPromo"
                      style="padding-left: 0px"
                    >
                      <div class="table-col-body">
                        <a
                          class="button-primary"
                          *ngIf="
                            !camosConfiguratorComponent.leadCreated &&
                            !(
                              this.appComponent._configuratorSetup.partnerId &&
                              this.appComponent._configuratorSetup.partnerId
                                .length > 0
                            )
                          "
                          (click)="
                            promotionGoToPartnerPage(item.name, item.price)
                          "
                          >{{ 'widgets.requestAQuote' | i18next }}</a
                        >
                        <a
                          class="button-primary"
                          *ngIf="
                            this.appComponent._configuratorSetup.partnerId &&
                            this.appComponent._configuratorSetup.partnerId
                              .length > 0
                          "
                          (click)="addToBasket(item.name, item.price)"
                          >{{ 'partnerStoreIntegration.basket' | i18next }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

         

            
          </div>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
