import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-service-selection-sub-checkbox',
  templateUrl: './service-selection-sub-checkbox.component.html',
  styleUrls: ['./service-selection-sub-checkbox.component.scss']
})
export class ServiceSelectionSubCheckboxComponent implements OnInit {
  uuidNum = uuidv4();
  isCheckboxActive = true;
  constructor() {}

  setCheckboxes(value) {
    this.isCheckboxActive = value;
    console.log(this.isCheckboxActive);
  }
  ngOnInit(): void {}
}
