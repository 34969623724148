import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preconfigured-review-ui',
  templateUrl: './preconfigured-review-ui.component.html',
  styleUrls: ['./preconfigured-review-ui.component.scss']
})
export class PreconfiguredReviewUiComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
