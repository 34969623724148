import { CuiWidget } from '../camos-widgets/cui-widget';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-config',
  templateUrl: './sub-config.component.html',
  styleUrls: ['./sub-config.component.scss']
})
export class SubConfigComponent implements OnInit, CuiWidget {
  constructor() {}

  widget: any;
  oneMust: boolean;
  promotion: boolean;

  public review: boolean;
  public naviNodes;
  public widgets;

  ngOnInit(): void {
    console.log(this.widget);
    if (this.widget) {
      this.naviNodes = this.widget?.uis[0]?.naviWidgets[0]?.navinodes;
      this.widgets = this.widget?.uis[0]?.widgets;
    }
  }
}
