import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @ViewChild('slider') slider;
  @Input() sliderContent;
  @Input() loadFunction: Function;
  @Input() PartnerFunction: Function;
  @Input() type: string;

  public transformValue: number = 0;
  public elementWidth: number = 0;
  public activeIndex: number = 0;
  public slideElements: number = 0;

  get myMethodFunc() {
    return this.testFunction.bind(this);
  }

  constructor() {}

  ngOnInit(): void {}

  public testFunction(type: string) {
    if (type == 'partner-page') {
      this.PartnerFunction(false, true);
    } else {
      this.loadFunction(type);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.elementWidth = this.slider.nativeElement.children[0].clientWidth;
      this.slideElements = this.slider.nativeElement.children.length;
    }, 1);
  }

  onResize() {
    this.elementWidth = this.slider.nativeElement.children[0].clientWidth;
    this.transformValue = -this.activeIndex * this.elementWidth;
  }

  changeSlide(direction: string) {
    if (direction == 'next') {
      this.transformValue -= this.elementWidth;
      this.activeIndex++;
    } else {
      this.transformValue += this.elementWidth;
      this.activeIndex--;
    }

    if (this.activeIndex >= this.slideElements || this.activeIndex < 0) {
      this.activeIndex = 0;
      this.transformValue = 0;
    }
  }

  changeSlideDots(i: number) {
    this.activeIndex = i;
    this.elementWidth = this.slider.nativeElement.children[0].clientWidth;
    this.transformValue = -this.activeIndex * this.elementWidth;
  }
}
