<div class="service-selection-wrapper">
  <div class="service-selection-left">
    <p class="service-selection-title">Select software maintenance period</p>
  </div>
  <div class="service-selection-right">
    <div class="service-selection-checkbox">
      <div class="service-selection-radio-wrapper">
        <input
          type="radio"
          [name]="uuidNum"
          checked
          [id]="uuidNum"
          (change)="setCheckboxes(true)"
        />
        <label [htmlFor]="uuidNum">Calibration coverage</label>
        <app-validtype-tooltip
          [validExplanation]="'test'"
          [validType]="'Must'"
          [tooltipPosition]="'left'"
        ></app-validtype-tooltip>
      </div>
      <div
        class="service-selection-child"
        [ngClass]="isCheckboxActive ? '' : 'disabled'"
      >
        <div class="service-selection-radio-wrapper">
          <input type="radio" name="name33" id="id44" checked />
          <label for="id44">Manufacturer calibration</label>
          <app-validtype-tooltip
            [validExplanation]="'test'"
            [validType]="'Must'"
            [tooltipPosition]="'left'"
          ></app-validtype-tooltip>
        </div>
        <div class="service-selection-radio-wrapper">
          <input type="radio" name="name33" id="id5" />
          <label for="id5">Accredited calibration</label>
          <app-validtype-tooltip
            [validExplanation]="'test'"
            [validType]="'Must'"
            [tooltipPosition]="'left'"
          ></app-validtype-tooltip>
        </div>
      </div>
    </div>
    <div class="service-selection-checkbox">
      <div class="service-selection-radio-wrapper">
        <input
          type="radio"
          [name]="uuidNum"
          [id]="uuidNum + 1"
          (change)="setCheckboxes(false)"
        />
        <label [htmlFor]="uuidNum + 1">No calibration coverage</label>
        <app-validtype-tooltip
          [validExplanation]="'test'"
          [validType]="'Must'"
          [tooltipPosition]="'left'"
        ></app-validtype-tooltip>
      </div>
    </div>
  </div>
</div>
