import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdobeAnalyticsManager } from 'src/app/adobe/adobe-analytics-manager';
import { SelectNavinode } from 'src/app/store/ui/ui.actions';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';
import { UIState } from 'src/app/store/ui/ui.state';
import { CamosConfiguratorComponent } from '../../camos-configurator/camos-configurator.component';

@Component({
  selector: 'app-cui-next-prev-nav',
  templateUrl: './cui-next-prev-nav.component.html',
  styleUrls: ['./cui-next-prev-nav.component.scss']
})
export class CuiNextPrevNavComponent implements OnInit {
  @Select(UIState.nextNaviNode) nextNavNode$: Observable<NaviNode>;
  nextNavNode: NaviNode;

  @Select(UIState.prevNaviNode) prevNavNode$: Observable<NaviNode>;
  prevNavNode: NaviNode;

  @Select(UIState.widgets) widgets$: Observable<Widget[]>;
  widgets: Widget[];

  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;

  @Input() nodes?;
  @Input() subConfig?: boolean = false;
  review = false;
  disabled = true;
  public activeNode;

  constructor(
    private store: Store,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public AdobeAnalyticsManager: AdobeAnalyticsManager
  ) {}

  ngOnInit(): void {
    if (this.nodes) {
      this.nodes?.forEach((element, index) => {
        if (element.isSelected) {
          if (!this.nodes[index - 1]) {
            this.prevNavNode = undefined;
          } else {
            this.prevNavNode = this.nodes[index - 1];
          }
          if (!this.nodes[index + 1]) {
            this.nextNavNode = undefined;
          } else {
            this.nextNavNode = this.nodes[index + 1];
          }
          console.log(this.prevNavNode);
          console.log(this.nextNavNode);
          this.disabled = false;
        }
      });
    }
  }

  public next() {
    if (!this.disabled) {
      if (!this.nodes) {
        this.currentNaviNode$.subscribe((num) => {
          if (num != undefined) {
            this.review = true;
          }
        });
        this.disabled = true;
        this.nextNavNode$.subscribe((next) => (this.nextNavNode = next));
        this.prevNavNode$.subscribe((prev) => (this.prevNavNode = prev));
        this.widgets$.subscribe((_widgets) => {
          this.widgets = _widgets;
          this.disabled = true;
          let basemodel = false;
          if (this.widgets && this.widgets.length) {
            for (let i = 0; i < this.widgets.length; i++) {
              if (this.widgets[i].widgets && this.widgets[i].widgets.length) {
                for (let j = 0; j < this.widgets[i].widgets.length; j++) {
                  if (this.widgets[i].widgets[j].xtIsBaseList) {
                    basemodel = true;
                  }
                }
              } else {
                if (this.widgets[i].xtIsBaseList) {
                  basemodel = true;
                }
              }
            }
          }
          if (this.widgets && this.widgets.length > 0 && basemodel) {
            this.widgets.forEach((widget) => {
              if (widget.values && widget.values.length > 0) {
                widget.values.forEach((value) => {
                  if (value.isSelected && value.name) {
                    this.disabled = false;
                  }
                });
              }
            });
          } else {
            this.disabled = false;
          }
        });
        this.camosConfiguratorComponent.changeOpacity();
      }
      this.store.dispatch(new SelectNavinode(this.nextNavNode.id));
      this.camosConfiguratorComponent.goToTop();
    }
  }

  public prev() {
    this.camosConfiguratorComponent.changeOpacity();
    this.store.dispatch(new SelectNavinode(this.prevNavNode.id));
    this.camosConfiguratorComponent.goToTop();
  }
}
