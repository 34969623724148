import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  APP_INITIALIZER,
  LOCALE_ID
} from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { UIState } from './store/ui/ui.state';
import { PromotionUIState } from './store/promotionUi/promotionUi.state';
import { CuiStaticHeaderComponent } from './camos-widgets/cui-static-header/cui-static-header.component';
import { CuiCompSingleTableComponent } from './camos-widgets/cui-comp-single-table/cui-comp-single-table.component';
import { CuiNaviNodesComponent } from './navigation/cui-navi-nodes/cui-navi-nodes.component';
import { CuiWidgetComponent } from './camos-widgets/cui-widget/cui-widget.component';
import { WidgetHostDirective } from './camos-widgets/widget-host.directive';
import { createCustomElement } from '@angular/elements';
import { CamosConfiguratorComponent } from './camos-configurator/camos-configurator.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CuiNaviNodeComponent } from './navigation/cui-navi-node/cui-navi-node.component';
import { CamosWidgetsComponent } from './camos-widgets/camos-widgets.component';
import { CuiStaticAccordionComponent } from './camos-widgets/cui-static-accordion/cui-static-accordion.component';
import { CuiCompMultiTableComponent } from './camos-widgets/cui-comp-multi-table/cui-comp-multi-table.component';
import { CuiCompMultiComboboxComponent } from './camos-widgets/cui-comp-multi-combobox/cui-comp-multi-combobox.component';
import { CuiFeatureSingleComboboxComponent } from './camos-widgets/cui-feature-single-combobox/cui-feature-single-combobox.component';
import { CuiFeatureSingleNEditlineComponent } from './camos-widgets/cui_feature_single_n_editline/cui_feature_single_n_editline.component';
import { CuiCompSingleHeaderComponent } from './camos-widgets/cui_comp_single_header/cui_comp_single_header.component';
import { CuiCompSingleComboboxComponent } from './camos-widgets/cui-comp-single-combobox/cui-comp-single-combobox.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { StepperComponent } from './stepper/stepper.component';
import { CuiNextPrevNavComponent } from './navigation/cui-next-prev-nav/cui-next-prev-nav.component';
import { HeadingComponent } from './heading/heading.component';
import { ConfigurationCodeComponent } from './configuration-code/configuration-code.component';
import { ReviewComponent } from './review/review.component';
import { RequestQuoteSelectedComponent } from './request-quote-selected/request-quote-selected.component';
import { SaveConfigurationCodeComponent } from './request-quote-selected/save-configuration-code/save-configuration-code.component';
import { DistributionPartnerSelectedComponent } from './request-quote-selected/distribution-partner-selected/distribution-partner-selected.component';
import { ConflictNotificationComponent } from './conflict-notification/conflict-notification.component';
import { ConfiguratorHiddenState } from './store/lead/lead.state';
import { ReviewState } from './store/review/review.state';
import { DistributionPartnerState } from './store/distributionpartner/distributionpartner.state';
import { TestWebsiteComponent } from './test-website/test-website.component';
import { ErrorsViewComponent } from './errors-view/errors-view.component';
import { ErrorHandlerState } from './store/error/error.state';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ValidtypeTooltipComponent } from './validtype-tooltip/validtype-tooltip.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { EnvServiceProvider } from './env.service.provider';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { PromotionComponent } from './promotion/promotion.component';
import { CamosPromotionsComponent } from './promotion/camos-promotions/camos-promotions.component';
import { PromotionsComponent } from './promotions/promotions.component';
// import { CamosPromotionsComponent } from './promotions/camos-promotions/camos-promotions.component';
import { CountrySuggestionComponent } from './newUi/country-suggestion/country-suggestion.component';
import { StickyElementComponent } from './newUi/sticky-element/sticky-element.component';
import { RequestQuoteComponent } from './newUi/request-quote/request-quote.component';
import { ReviewTableComponent } from './newUi/review-table/review-table.component';
import { BaseProductTableComponent } from './newUi/base-product-table/base-product-table.component';
import { ReviewPreconfiguredTableComponent } from './newUi/review-preconfigured-table/review-preconfigured-table.component';
import { ReviewTableBodyComponent } from './newUi/review-preconfigured-table/review-table-body/review-table-body.component';
import { PreconfiguredReviewUiComponent } from './newUi/preconfigured-review-ui/preconfigured-review-ui.component';
import { OptionTableComponent } from './newUi/option-table/option-table.component';
import { PromotionTableComponent } from './newUi/promotion-table/promotion-table.component';
import { ImageTableComponent } from './newUi/image-table/image-table.component';
// import { CamosPromotionsComponent } from './promotions/camos-promotions/camos-promotions.component';
import { PreconfiguredBundlesComponent } from './preconfigured-bundles/preconfigured-bundles.component';
import { SliderComponent } from './slider/slider.component';
import { CardElementComponent } from './card-element/card-element.component';
import { OscilloscopesComponent } from './promotions/oscilloscopes/oscilloscopes.component';
import { PowerSuppliesPowerAnalyzersComponent } from './promotions/powerSuppliesPowerAnalyzers/powerSuppliesPowerAnalyzers.component';
import { SpectrumAnalyzerSignalGeneratorsComponent } from './promotions/spectrumAnalyzerSignalGenerators/spectrumAnalyzerSignalGenerators.component';
import { CuiStaticSubheadlineComponent } from './camos-widgets/cui-static-subheadline/cui-static-subheadline.component';
import { CheckErrorComponent } from './check-error/check-error.component';

import {
  defaultInterpolationFormat,
  I18NextModule,
  I18NEXT_SERVICE,
  ITranslationService
} from 'angular-i18next';
import Backend from 'i18next-http-backend';
// import { EnvService } from './env.service';
import { ErrorNotificationComponent } from './error-notification/error-notification.component';
import { CountrySelectorMenuComponent } from './newUi/country-selector-menu/country-selector-menu.component';
import { environment } from 'src/environments/environment';
import { ErorrModalComponent } from './newUi/erorr-modal/erorr-modal.component';
import { SubConfigComponent } from './sub-config/sub-config.component';
import { NaviNodeHeadlineComponent } from './newUi/navi-node-headline/navi-node-headline.component';
import { TooltipComponent } from './newUi/tooltip/tooltip.component';
import { ConfiguratorOverviewComponent } from './newUi/configurator-overview/configurator-overview.component';
import { ServiceSelectionComponent } from './newUi/service-selection/service-selection.component';
import { ChecboxInputComponent } from './newUi/checbox-input/checbox-input.component';
import { CheckboxInputSingleComponent } from './newUi/checkbox-input-single/checkbox-input-single.component';
import { TestUiComponent } from './test-ui/test-ui.component';
import { ServiceSelectionCheckboxComponent } from './newUi/service-selection-checkbox/service-selection-checkbox.component';
import { ServiceSelectionDatepickerComponent } from './newUi/service-selection-datepicker/service-selection-datepicker.component';
import { ServiceSelectionSubCheckboxComponent } from './newUi/service-selection-sub-checkbox/service-selection-sub-checkbox.component';
import { SalesTextComponent } from './newUi/sales-text/sales-text.component';
import { ValidationComponent } from './validation/validation.component';

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next.use(Backend).init({
      // whitelist: ['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'pt'],
      fallbackLng: ['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'pt'],
      debug: true,
      returnEmptyString: false,
      ns: ['translation'],
      interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
      },
      backend: {
        loadPath: environment.apiUrl + '/language/{{lng}}'
      }
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }
];

@NgModule({
  declarations: [
    AppComponent,
    CuiStaticHeaderComponent,
    CuiCompSingleTableComponent,
    CuiNaviNodesComponent,
    CuiWidgetComponent,
    CamosConfiguratorComponent,
    WidgetHostDirective,
    CuiNaviNodeComponent,
    CamosWidgetsComponent,
    CuiStaticAccordionComponent,
    CuiCompMultiTableComponent,
    CuiCompMultiComboboxComponent,
    CuiFeatureSingleComboboxComponent,
    CuiFeatureSingleNEditlineComponent,
    CuiCompSingleHeaderComponent,
    CuiCompSingleComboboxComponent,
    ProductSelectionComponent,
    StepperComponent,
    CuiNextPrevNavComponent,
    HeadingComponent,
    ConfigurationCodeComponent,
    ReviewComponent,
    RequestQuoteSelectedComponent,
    SaveConfigurationCodeComponent,
    DistributionPartnerSelectedComponent,
    ConflictNotificationComponent,
    TestWebsiteComponent,
    ErrorsViewComponent,
    ValidtypeTooltipComponent,
    CountrySelectorComponent,
    PromotionComponent,
    CamosPromotionsComponent,
    PromotionsComponent,
    CamosPromotionsComponent,
    CountrySuggestionComponent,
    StickyElementComponent,
    RequestQuoteComponent,
    ReviewTableComponent,
    BaseProductTableComponent,
    ReviewPreconfiguredTableComponent,
    ReviewTableBodyComponent,
    PreconfiguredReviewUiComponent,
    OptionTableComponent,
    PromotionTableComponent,
    ImageTableComponent,
    PreconfiguredBundlesComponent,
    SliderComponent,
    CardElementComponent,
    OscilloscopesComponent,
    PowerSuppliesPowerAnalyzersComponent,
    SpectrumAnalyzerSignalGeneratorsComponent,
    CuiStaticSubheadlineComponent,
    CheckErrorComponent,
    ErrorNotificationComponent,
    ErorrModalComponent,
    SubConfigComponent,
    CountrySelectorMenuComponent,
    NaviNodeHeadlineComponent,
    TooltipComponent,
    ConfiguratorOverviewComponent,
    ServiceSelectionComponent,
    ChecboxInputComponent,
    CheckboxInputSingleComponent,
    TestUiComponent,
    ServiceSelectionCheckboxComponent,
    ServiceSelectionDatepickerComponent,
    ServiceSelectionSubCheckboxComponent,
    SalesTextComponent,
    ValidationComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgxsModule.forRoot(
      [
        UIState,
        PromotionUIState,
        ConfiguratorHiddenState,
        ReviewState,
        DistributionPartnerState,
        ErrorHandlerState
      ],
      {
        developmentMode: true
      }
    ),
    NgxsLoggerPluginModule.forRoot(),
    HttpClientModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule,
    I18NextModule.forRoot()
  ],
  entryComponents: [
    AppComponent,
    // PromotionComponent
    OscilloscopesComponent,
    PowerSuppliesPowerAnalyzersComponent,
    SpectrumAnalyzerSignalGeneratorsComponent,
    // ValidationComponent
    ValidationComponent
  ],
  providers: [
    // EnvServiceProvider,
    AppComponent,
    ConfigurationCodeComponent,
    CamosConfiguratorComponent,
    PromotionComponent,
    CuiCompSingleTableComponent,
    CuiCompMultiTableComponent,
    CamosPromotionsComponent,
    CuiNaviNodesComponent,
    ReviewComponent,
    RequestQuoteSelectedComponent,
    PromotionsComponent,
    I18N_PROVIDERS,
    CuiNextPrevNavComponent,
    StickyElementComponent,
    CamosPromotionsComponent
  ],
  bootstrap: []
})
export class AppModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    // * Regular TAP component
    const app = createCustomElement(AppComponent, {
      injector: this.injector
    });
    customElements.define('tap-webcomponent', app);
    const oscilloscopes = createCustomElement(OscilloscopesComponent, {
      injector: this.injector
    });

    //* Multiple product configurations in one component
    const configuratorOverview = createCustomElement(
      ConfiguratorOverviewComponent,
      {
        injector: this.injector
      }
    );
    customElements.define('tap-configurator-overview', configuratorOverview);

    //* Oscilloscope promotions
    customElements.define('tap-oscilloscope-promotions', oscilloscopes);
    const powerSuppliesPowerAnalyzers = createCustomElement(
      PowerSuppliesPowerAnalyzersComponent,
      {
        injector: this.injector
      }
    );
    customElements.define(
      'tap-powersuppliespoweranalyzers-promotions',
      powerSuppliesPowerAnalyzers
    );

    //* Specturm AnalyzerSignalGenerators promotions
    const spectrumAnalyzerSignalGenerators = createCustomElement(
      SpectrumAnalyzerSignalGeneratorsComponent,
      {
        injector: this.injector
      }
    );
    customElements.define(
      'tap-spectrumanalyzersignalgenerators-promotions',
      spectrumAnalyzerSignalGenerators
    );

    //* TAP Validation Webcomponent
    const validation = createCustomElement(ValidationComponent, {
      injector: this.injector
    });
    customElements.define('tap-validation-webcomponent', validation);

    //* Component for UI testing
    const testUi = createCustomElement(TestUiComponent, {
      injector: this.injector
    });
    customElements.define('tap-test-ui', testUi);
  }
}
