export const environment = {
  production: true,
  // Location: '',
  // ProductStructure: [],
  // pCurrentProduct: null,
  // loaded: false,
  // pressedNumber: 0,
  apiUrl: 'https://tap.cloud.rohde-schwarz.com/v1',
  pricingUrl: 'https://pricing-service.cloud.rohde-schwarz.com/v1',
  distributionUrl:
    'https://distribution-partner-service.cloud.rohde-schwarz.com/v1',
  stage: 'prod'
};
