import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { PriceService } from 'src/app/services/price.service';
import { SelectItemState } from 'src/app/store/ui/ui.actions';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamosService } from 'src/app/services/camos.service';

@Component({
  selector: 'app-cui-feature-single-combobox',
  templateUrl: './cui-feature-single-combobox.component.html',
  styleUrls: ['./cui-feature-single-combobox.component.scss']
})
export class CuiFeatureSingleComboboxComponent implements CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  // selected: Value;

  constructor(
    private store: Store,
    private priceService: PriceService,
    private spinner: NgxSpinnerService,
    private camosService: CamosService
  ) {}

  async selectItem(name: string) {
    this.spinner.show('sticky-spinner');
    document.getElementById('priceBox').style.color = 'lightgray';

    const nextState = await this.camosService.getItemForWidget(
      name,
      this.widget.id,
      1,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(new SelectItemState(nextState, name, this.widget.id));

    const price = await this.priceService.setPriceTotal();
    this.priceService.updatePriceTotal(price);

    document.getElementById('priceBox').style.color = '#009bea';
    this.spinner.hide('sticky-spinner');
  }
}
