import { Component, Input, OnInit } from '@angular/core';
import { RequestQuoteSelectedComponent } from '../request-quote-selected.component';
import { DistributionPartnerService } from '../services/distribution-partner.service';
import {
  Lead,
  InteractionProduct,
  InteractionData,
  Profile,
  ContactData
} from '../leadmodel';
import { Value, Widget } from '../../store/ui/ui.model';
import { AdobeAnalyticsManager } from '../../adobe/adobe-analytics-manager';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { Select, Store } from '@ngxs/store';
import { Distributor } from '../../store/distributionpartner/distributionpartner.model';
import { CamosService } from '../../services/camos.service';
import { SetErrorState } from '../../store/error/error.actions';
import { State } from '../../errors-view/error-model';
import { UIState } from 'src/app/store/ui/ui.state';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
// import { environment } from '../../../environments/environment';
import SelectableCountries from '../distribution-partner-selected/selectableCountries.json';
import SelectableUSAStates from '../distribution-partner-selected/selectableUSAStates.json';
import SelectableCanadaStates from '../distribution-partner-selected/selectableCanadaStates.json';
// import { EnvService } from './../../env.service';
import { partnerIDs } from './../../partnerID-mapping';
import { AppComponent } from './../../app.component';
import { CamosPromotionsComponent } from 'src/app/promotion/camos-promotions/camos-promotions.component';
import { PromotionComponent } from 'src/app/promotion/promotion.component';
import { SetDistributorInfos } from 'src/app/store/distributionpartner/distributionpartner.actions';
import { environment } from 'src/environments/environment';

interface ISelectableCountriesJson {
  countries: ICountry[];
}

interface ICountry {
  id: string;
  country: string;
}

interface ISelectabelStatesJson {
  states: IState[];
}

interface IState {
  id: string;
  state: string;
}

@Component({
  selector: 'app-distribution-partner-selected',
  templateUrl: './distribution-partner-selected.component.html',
  styleUrls: ['./distribution-partner-selected.component.scss']
})
export class DistributionPartnerSelectedComponent implements OnInit {
  @Input() iNeedHelp?: boolean = false;
  @Input() woConfiguration?: boolean = false;

  public northAmerica = false;
  public DistributionPartner: Distributor;
  public selectableCountries: ISelectableCountriesJson;
  public selectableStates: ISelectabelStatesJson;
  public formClick: boolean = false;
  public couldNotSubmit: boolean = false;
  private currency: string = 'EUR';

  @Select(UIState.widgets) widgets$: Observable<Widget[]>;
  constructor(
    public requestQuote: RequestQuoteSelectedComponent,
    private distributionPartner: DistributionPartnerService,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camosPromotionsComponent: CamosPromotionsComponent,
    public promotionComponent: PromotionComponent,
    private camos: CamosService,
    private store: Store,
    private spinner: NgxSpinnerService,
    // private env: EnvService,
    public appComponent: AppComponent,
    private AdobeAnalyticsManager: AdobeAnalyticsManager
  ) {}

  ngOnInit() {
    this.camosConfiguratorComponent.goToTop();
    this.getCountriesFromJson();
    this.formClick = false;
    // this.getStatesFromJson();
    // setTimeout(() => {
    //   this.store.dispatch(new SetErrorState(State.leadcreation));
    //   this.camosConfiguratorComponent.showErrorPage();
    // }, 2000)

    try {
      (window as any).app.initViews(
        [],
        document.querySelector('tap-webcomponent')
      );
    } catch (err) {
      // console.log(err);
    }
    if (this.store.snapshot().distributionpartner.distributors) {
      this.DistributionPartner =
        this.store.snapshot().distributionpartner.distributors.selectedDistributor;
    } else {
      this.DistributionPartner = null;
    }
    this.camosConfiguratorComponent.leadCreation(false);

    let country = this.store
      .snapshot()
      .lead.configuratorSetup.country.toLowerCase();
    if (country == 'us' || country == 'ca') {
      this.northAmerica = true;
      switch (country) {
        case 'us':
          this.getUSAStatesFromJson();
          break;
        case 'ca':
          this.getCanadaStatesFromJson();
          break;
        default:
          return;
      }
    }
  }

  /*
  ngAfterViewInit(): void {
    let email = document.getElementById('INRED_ProductQuoteRequest_group0_email_10')
    email.addEventListener('focusout', e => {
      if(!(email as any).checkValidity()) {
        email.setAttribute('style', 'border-color: red;');
      } else {
        email.setAttribute('style', 'border-color: rgb(199, 199, 199);');
      }
    });
    let phone = document.getElementById('INRED_ProductQuoteRequest_group0_phone_11')
    phone.addEventListener('focusout', e => {
      if(!(phone as any).checkValidity()) {
        phone.setAttribute('style', 'border-color: red;');
      } else {
        phone.setAttribute('style', 'border-color: rgb(199, 199, 199);');
      }
    });
  }
  */

  /*
  private checkValidity(className) {
    let valid = true;
    let tags = document.getElementsByClassName(className);
    let radiobuttons = [];
    for (let i = 0; i < tags.length; i++) {
      if ((tags[i] as any).value === '') {
        tags[i].setAttribute('style', 'border: 1px solid red; width: 100%;');
        valid = false;
      } else {
        tags[i].setAttribute('style', 'border: 1px solid rgb(199, 199, 199); width: 100%;');
      }
      tags[i].addEventListener('focusout', e => {
        if ((tags[i] as any).value === '') {
          tags[i].setAttribute('style', 'border: 1px solid red; width: 100%;');
        } else {
          tags[i].setAttribute('style', 'border: 1px solid rgb(199, 199, 199); width: 100%;');
        }
      });
    }
    return valid;
  }
  */

  private checkValidityGender() {
    let tags = document.getElementsByClassName('label');
    let oneSelected = false;
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].children && tags[i].children.length > 0) {
        for (let j = 0; j < tags[i].children.length; j++) {
          if ((tags[i].children[j] as any).type == 'radio') {
            if ((tags[i].children[j] as any).checked) {
              oneSelected = true;
            }
          }
        }
      }
    }
    if (!oneSelected) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].children && tags[i].children.length > 0) {
          for (let j = 0; j < tags[i].children.length; j++) {
            if (
              tags[i].children[j].className == 'gender' ||
              tags[i].children[j].className == 'red'
            ) {
              tags[i].children[j].setAttribute('class', 'red');
            }
          }
        }
      }
      let radioContainer = document.getElementsByClassName(
        'form-field radio-container'
      );
      for (let i = 0; i < radioContainer.length; i++) {
        if (
          radioContainer[i].children &&
          radioContainer[i].children.length > 0
        ) {
          for (let j = 0; j < radioContainer[i].children.length; j++) {
            if (radioContainer[i].children[j].className == 'couldNotSubmit') {
              radioContainer[i].children[j].setAttribute(
                'style',
                'display: inline-block;'
              );
            }
          }
        }
      }
    }
    return oneSelected;
  }

  getCountriesFromJson(): void {
    this.selectableCountries = SelectableCountries as ISelectableCountriesJson;
  }

  getUSAStatesFromJson(): void {
    this.selectableStates = SelectableUSAStates as ISelectabelStatesJson;
  }

  getCanadaStatesFromJson(): void {
    this.selectableStates = SelectableCanadaStates as ISelectabelStatesJson;
  }

  getPreSelectedCountry(): string {
    return this.store.snapshot().lead.configuratorSetup.country;
  }

  checkValidityCountryState() {
    this.formTracking();
    let country = document.getElementById(
      'INRED_ProductQuoteRequest_group0_country_8'
    );
    let stateSelected = false;

    // if((country as any).value == "us" || (country as any).value == "ca" || (country as any).value == "mx") {
    if ((country as any).value == 'us' || (country as any).value == 'ca') {
      this.northAmerica = true;

      switch ((country as any).value) {
        case 'us':
          this.getUSAStatesFromJson();
          break;
        case 'ca':
          this.getCanadaStatesFromJson();
          break;
        default:
          return;
      }

      let state = document.getElementById(
        'INRED_ProductQuoteRequest_group0_state_9'
      );
      if (state) {
        if (
          (state as any).value &&
          (state as any).value != '' &&
          (state as any).value.length > 0
        ) {
          state.setAttribute('style', 'border-color: #c6ccd0');
          stateSelected = true;
          let children = state.parentElement.children;
          for (let j = 0; j < children.length; j++) {
            if (children[j].className == 'couldNotSubmit') {
              children[j].setAttribute('style', 'display: none;');
            }
          }
        } else {
          state.setAttribute('style', 'border-color: red');
          let children = state.parentElement.children;
          for (let j = 0; j < children.length; j++) {
            if (children[j].className == 'couldNotSubmit') {
              children[j].setAttribute('style', 'display: inline-block;');
            }
          }
          stateSelected = false;
        }
      }
    } else {
      this.northAmerica = false;
      stateSelected = true;
    }
    if (
      (country as any).value &&
      (country as any).value != '' &&
      (country as any).value.length > 0
    ) {
      country.setAttribute('style', 'border-color: #c6ccd0');
      if (stateSelected) {
        return true;
      } else {
        return false;
      }
    }
    country.setAttribute('style', 'border-color: red');
    return false;
  }

  // private checkPrivacypolicy() {
  //   let valid = true;
  //   let privacypolicy = document.getElementById('privacypolicy');
  //   if (!(<any>privacypolicy).checked) {
  //     privacypolicy.setAttribute('class', 'red');
  //     valid = false;
  //   } else {
  //     privacypolicy.setAttribute('class', 'privacypolicy');
  //   }
  //   privacypolicy.addEventListener('click', e => {
  //     if (!(<any>privacypolicy).checked) {
  //       privacypolicy.setAttribute('class', 'red');
  //     } else {
  //       privacypolicy.setAttribute('class', 'privacypolicy');
  //     }
  //   });
  //   return valid;
  // }

  public selected() {
    console.log(
      (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_country_8')
      )).value.toString()
    );
  }

  public async createLead() {
    let inputs = document.getElementsByClassName('form-input');
    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      if (!(inputs[i] as any).checkValidity()) {
        valid = false;
        inputs[i].setAttribute('style', 'border: 1px solid red; width: 100%;');
        let children = inputs[i].parentElement.children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].className == 'couldNotSubmit') {
            children[j].setAttribute('style', 'display: inline-block;');
          }
        }
        inputs[i].addEventListener('focusout', (e) => {
          if (!(inputs[i] as any).checkValidity()) {
            inputs[i].setAttribute(
              'style',
              'border: 1px solid red; width: 100%;'
            );
            let children = inputs[i].parentElement.children;
            for (let j = 0; j < children.length; j++) {
              if (children[j].className == 'couldNotSubmit') {
                children[j].setAttribute('style', 'display: inline-block;');
              }
            }
          } else {
            inputs[i].setAttribute(
              'style',
              'border: 1px solid rgb(199, 199, 199); width: 100%;'
            );
            let children = inputs[i].parentElement.children;
            for (let j = 0; j < children.length; j++) {
              if (children[j].className == 'couldNotSubmit') {
                children[j].setAttribute('style', 'display: none;');
              }
            }
          }
        });
      }
    }
    // const validInput = this.checkValidity('form-input');
    const validGender = this.checkValidityGender();
    const validCountry = this.checkValidityCountryState();
    // const validPrivacypolicy = this.checkPrivacypolicy();
    // if (validInput && validGender && validCountry && validPrivacypolicy) {
    // if (validInput && validGender && validCountry) {
    if (valid && validCountry && validGender) {
      this.couldNotSubmit = false;
      this.spinner.show('lead-spinner');
      let gender;
      let tags = document.getElementsByClassName('label');
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].children && tags[i].children.length > 0) {
          for (let j = 0; j < tags[i].children.length; j++) {
            if (
              (tags[i].children[j] as any).type == 'radio' &&
              (tags[i].children[j] as any).checked
            ) {
              gender = (tags[i].children[j] as any).value;
              break;
            }
          }
        }
      }
      let configId = '';
      if (!this.store.snapshot().lead.configuratorSetup.bundleMode) {
        const resp = await this.camos.saveConfiguration();
        if (resp) {
          configId = await resp['configUUID'];
        }
        //console.log("DPS", configId)
        this.AdobeAnalyticsManager.getConfId(configId);
      } else {
        this.AdobeAnalyticsManager.getConfId(undefined);
      }
      const leadObject = await this.createLeadObject(configId);
      // console.log(leadObject)
      const response = await this.distributionPartner.createLead(leadObject);
      if (response) {
        this.camosConfiguratorComponent.leadCreation(true);
      } else {
        this.camosConfiguratorComponent.leadCreation(false);
        this.store.dispatch(new SetErrorState(State.leadcreation));
        this.camosConfiguratorComponent.showErrorPage();
        this.AdobeAnalyticsManager.trackCamosError('5');
      }
    } else {
      this.couldNotSubmit = true;
      const msku = this.store.selectSnapshot(
        (state) => state.lead.configuratorSetup.msku
      );
      const arg = msku ? msku : undefined;
      // console.log(arg)
      this.AdobeAnalyticsManager.trackRequestQuoteError(arg);
    }

    this.spinner.hide('lead-spinner');
  }

  public formTracking() {
    if (this.formClick) return;
    this.formClick = true;
    // console.log("Sent");
    const msku = this.store.selectSnapshot(
      (state) => state.lead.configuratorSetup.msku
    );
    const arg = msku ? msku : undefined;
    // console.log(arg)
    this.AdobeAnalyticsManager.trackRequestQuoteForm(arg);
  }

  private async createLeadObject(configId: string) {
    let lead: Lead = {
      Profile: await this.createProfile(),
      InteractionData: await this.createInteractionData(configId)
    };

    let contactMeByFaxOnly = (<any>(
      document.getElementById(
        'marketingPermissionFields_2_INRED_ProductQuoteRequest_fax'
      )
    )).value;
    if (contactMeByFaxOnly && contactMeByFaxOnly.length > 0) {
      lead.contact_me_by_fax_only = contactMeByFaxOnly;
    }

    return lead;
  }

  private async createProfile() {
    const profile: Profile = {
      IDOrigin: 'PUBLIC_WEB',
      C4C_ContactID: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_email_10')
      )).value.toString(),
      ContactData: await this.createContactData()
    };
    return profile;
  }

  private async createContactData() {
    let countryValue: string = (<any>(
      document.getElementById('INRED_ProductQuoteRequest_group0_country_8')
    )).value.toString();
    (countryValue as string) == 'us' || (countryValue as string) == 'ca'
      ? (this.northAmerica = true)
      : (this.northAmerica = false);
    let NoValue = this.northAmerica
      ? ''
      : (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_streetno_5')
        )).value.toString();
    let contactData: ContactData = await {
      Salutation: this.getSalutaionCode(), // optional
      LastName: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_lastname_2')
      )).value.toString(),
      FirstName: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_firstname_1')
      )).value.toString(),
      Phone: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_phone_11')
      )).value.toString(),
      //Mobile: (<any>document.getElementById('INRED_ProductQuoteRequest_group0_phone_11')).value.toString(),
      Email: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_email_10')
      )).value.toString(), // client Mail
      Language: await this.distributionPartner.getLanguage(
        (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_country_8')
        )).value.toString()
      ),
      Adressdata: {
        ContactAccountName: (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_company_12')
        )).value.toString(),
        Street: (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_street_4')
        )).value.toString(),
        StreetNo: NoValue,
        PostalCode: (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_zip_6')
        )).value.toString(),
        City: (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_city_7')
        )).value.toString(),
        Country: (<any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_country_8')
        )).value
          .toString()
          .toLocaleUpperCase(),
        State: <any>(
          document.getElementById('INRED_ProductQuoteRequest_group0_state_9')
        )
          ? (<any>(
              document.getElementById(
                'INRED_ProductQuoteRequest_group0_state_9'
              )
            )).value
              .toString()
              .toLocaleUpperCase()
          : null
      },
      EmailPermission: (<any>(
        document.getElementById(
          'marketingPermissionFields_0_INRED_ProductQuoteRequest_email'
        )
      )).checked as boolean,
      PaperPermission: (<any>(
        document.getElementById(
          'marketingPermissionFields_1_INRED_ProductQuoteRequest_post'
        )
      )).checked as boolean
    };
    return contactData;
  }

  private getSalutaionCode() {
    let gender;
    let tags = document.getElementsByClassName('label');
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].children && tags[i].children.length > 0) {
        for (let j = 0; j < tags[i].children.length; j++) {
          if (
            (tags[i].children[j] as any).type == 'radio' &&
            (tags[i].children[j] as any).checked
          ) {
            gender = (tags[i].children[j] as any).value;
            break;
          }
        }
      }
    }

    switch (gender) {
      case '0':
        return '0002';
      case '1':
        return '0001';
      case '2':
        return '0004';
      default:
        return '0004';
    }
  }

  private async createInteractionData(
    configId: string
  ): Promise<InteractionData> {
    const intProductList: InteractionProduct[] =
      await this.getInteractionProductList();

    let inquiry = (<any>(
      document.getElementById('INRED_ProductQuoteRequest_inquiry')
    )).value.toString();
    if (
      this.store.snapshot().lead.configuratorSetup.bundleMode === 'true' &&
      this.store.snapshot().lead.configuratorSetup.malias &&
      this.store.snapshot().lead.configuratorSetup.msku
    ) {
      if (inquiry === '' && inquiry.length === 0) {
        inquiry = document.getElementById(
          'INRED_ProductQuoteRequest_model'
        ).textContent;
      } else {
        inquiry =
          document.getElementById('INRED_ProductQuoteRequest_model')
            .textContent +
          ' | Customer Note: ' +
          inquiry;
      }
    }
    if (inquiry === '' && inquiry.length === 0) {
      inquiry =
        'Customer requested a quote. Please contact the customer as soon as possible to discuss further details!';
    }
    if (this.iNeedHelp) {
      inquiry += '\nI need Help - Workflow';
    }
    if (this.woConfiguration) {
      inquiry += '\nProceed without configuration - Workflow';
    }

    let stage = await this.camos.getStage();
    let partnerID =
      this.DistributionPartner !== null
        ? this.DistributionPartner.partner_id._cdata
        : '';
    if (stage.stage.toLowerCase() !== 'prod') {
      // map ProductID prod to qual
      let pid = partnerIDs.get(partnerID);
      if (pid && pid.length > 0) {
        partnerID = pid;
      }
    }

    let distri = true;
    if (!this.DistributionPartner) {
      distri = false;
    } else {
      if (this.DistributionPartner.partner_id._cdata === '') {
        distri = false;
      }
    }

    let intData: InteractionData = {
      InteractionType: 'RFQ', //this.store.snapshot().lead.configuratorSetup.m4c_interactionType, // constant
      InteractionSubject: this.store.snapshot().lead.configuratorSetup
        ? 'DISTRIBUTOR LEAD: ' +
          this.store.snapshot().lead.configuratorSetup.product
        : 'DISTRIBUTOR LEAD: RTM3000', // Titel der Interaction // Optional
      InteractionContent: inquiry,
      InteractionSourceDataURL: window.location.origin,
      //CampaignID: this.store.snapshot().lead.configuratorSetup.m4c_campaignId, // constant
      CampaignID: environment.stage === 'prod' ? '6412' : '2408', // constant
      // WebCampaignID: '000_com_eml-nl_AUT_m4c_20-12_i______overview__MM-142253', // optional // traffic url metatag von der Website
      DistributorChannel: !distri ? '10' : '80', // if there are partner: 80; if no partner: 10
      DistributorId: partnerID,
      DistributorRelevant: !distri ? false : true, // if there are partner: true; if no partner: false
      CAMOSConfigurationID: configId,
      ItemOfInterest: this.store
        .snapshot()
        .lead.configuratorSetup.m4c_ioi.split('_')[0], // TODO temp fix for m4c strucutre
      ID: (<any>(
        document.getElementById('INRED_ProductQuoteRequest_group0_email_10')
      )).value.toString(), // client Mail
      CommunicationMedium: 'WEB', // constant
      //IntMarketSegment: 'AUT', // ?
      InteractionCurrency: this.getCurrency(),
      InteractionProduct: intProductList,
      Country: this.appComponent._configuratorSetup.country
    };

    // console.log(await this.distributionPartner.getInteractionId());

    if (this.promotionComponent._promotionSetup) {
      intData.Country = this.promotionComponent._promotionSetup.country;
    }

    return intData;
  }

  private getCurrency(): string {
    if (
      this.store.snapshot().review.reviewProducts &&
      this.store.snapshot().review.reviewProducts.length >= 1
    ) {
      let value: Value =
        this.store.snapshot().review.reviewProducts[0].values[0];
      if (value.price.includes('price on request')) {
        this.store.snapshot().review.reviewProducts[0].values.forEach((v) => {
          if (!v.price.includes('price on request')) {
            value = v;
          }
        });
      }

      if (value.price.includes('price on request')) {
        return 'EUR';
      }
      const currency = value.price.split(' ')[1];
      if (currency.toLocaleUpperCase() == 'ON') {
        return 'EUR';
      }
      return currency;
    } else {
      if (this.promotionComponent._promotionSetup) {
        if (
          this.camosPromotionsComponent.promotionPrice.includes(
            'price on request'
          )
        ) {
          return 'EUR';
        }
        return this.camosPromotionsComponent.promotionPrice.split(' ')[1];
      }
      if (
        this.camosConfiguratorComponent.promotionPrice.length > 0 &&
        this.camosConfiguratorComponent.promotionId.length > 0
      ) {
        if (
          this.camosConfiguratorComponent.promotionPrice.includes(
            'price on request'
          )
        ) {
          return 'EUR';
        }
        if (
          this.camosConfiguratorComponent.promotionPrice
            .split(' ')[1]
            .toUpperCase() == 'ON'
        ) {
          return 'EUR';
        } else {
          return this.camosConfiguratorComponent.promotionPrice.split(' ')[1];
        }
      }
      return this.currency;
    }
  }

  private async getInteractionProductList(): Promise<InteractionProduct[]> {
    let intProductList: InteractionProduct[] = [];

    if (!this.store.snapshot().lead.configuratorSetup.bundleMode) {
      const items = await this.camos.getStructure(
        this.store.snapshot().lead.configuratorSetup.country
      );

      let productId = this.camosConfiguratorComponent.promotionId;
      let price = this.camosConfiguratorComponent.promotionPrice;
      if (productId.length == 0 && price.length == 0) {
        items?.forEach((item) => {
          intProductList.push(this.createInteractionProduct(item));
        });
      }
      if (productId.length > 0 && price.length > 0 && items?.length > 0) {
        intProductList.push({
          ProductOrigin: 'SAP_C4C_PRODUCT',
          Product: productId,
          InteractionProductQuantity: 1
        });
      }
      if (!items || items.length == 0) {
        if (productId.length == 0 && price.length == 0) {
          this.widgets$.subscribe((_widgets) => {
            for (let i = 0; i < _widgets.length; i++) {
              if (_widgets[i].xtIsBaseList) {
                productId = _widgets[i].values.filter(
                  (value) => value.name != null && value.naming != 'Ø'
                )[0].name;
                price = _widgets[i].values.filter(
                  (value) => value.name != null && value.naming != 'Ø'
                )[0].price;
                this.currency = price.split(' ')[1];
              }
            }
          });
        }
        if (price.includes('price on request')) {
          price = '0';
          this.currency = 'EUR';
        } else {
          price = price.split(' ')[0];
        }
        intProductList.push({
          ProductOrigin: 'SAP_C4C_PRODUCT',
          Product: productId,
          InteractionProductQuantity: 1
        });
      }
    } else {
      if (
        this.store.snapshot().lead.configuratorSetup.malias &&
        this.store.snapshot().lead.configuratorSetup.msku
      ) {
        intProductList.push({
          ProductOrigin: 'SAP_C4C_PRODUCT',
          Product: this.store.snapshot().lead.configuratorSetup.msku,
          InteractionProductQuantity: 1
        });
      } else {
        let productId = '';
        // let price = "";
        if (this.promotionComponent._promotionSetup) {
          productId = this.camosPromotionsComponent.promotionId;
          // price = this.camosPromotionsComponent.promotionPrice;
        }
        intProductList.push({
          ProductOrigin: 'SAP_C4C_PRODUCT',
          Product: productId,
          InteractionProductQuantity: 1
        });
      }
    }

    if (!intProductList[0].Product || intProductList[0].Product.length == 0) {
      const resp = await this.camos.getBaseID(
        this.store.snapshot().lead.configuratorSetup.product
      );
      intProductList[0].Product = resp;
    }

    return intProductList;
  }

  private createInteractionProduct(item: any): InteractionProduct {
    // const quantity = (product.quantity) ? product.quantity : 1;
    let isPrice = true;
    if (item.price.split(' ', 1)[0] === 'price') {
      isPrice = false;
    }
    let pr = item.price.split(' ', 1)[0];
    if (pr.includes(',')) {
      pr = pr.replace(',', '.');
    }
    let price = Number.parseFloat(pr);
    price = price * Number.parseInt(item.quantity);

    if (!isPrice) {
      // TODO clarify which price shoud be saved
      price = 0;
    }

    if (!price) {
      price = 0;
    }

    if (isPrice) {
      this.currency = item.price.split(' ')[1];
    }

    const intProduct: InteractionProduct = {
      ProductOrigin: 'SAP_C4C_PRODUCT',
      Product: item.productID,
      InteractionProductQuantity: Number.parseInt(item.quantity)
    };

    return intProduct;
  }

  public select(element) {
    element.setAttribute('checked', true);
    let tags = document.getElementsByClassName('label');
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].children && tags[i].children.length > 0) {
        for (let j = 0; j < tags[i].children.length; j++) {
          if (
            tags[i].children[j].className == 'gender' ||
            tags[i].children[j].className == 'red'
          ) {
            tags[i].children[j].setAttribute('class', 'gender');
          }
        }
      }
    }
    let radioContainer = document.getElementsByClassName(
      'form-field radio-container'
    );
    for (let i = 0; i < radioContainer.length; i++) {
      if (radioContainer[i].children && radioContainer[i].children.length > 0) {
        for (let j = 0; j < radioContainer[i].children.length; j++) {
          if (radioContainer[i].children[j].className == 'couldNotSubmit') {
            radioContainer[i].children[j].setAttribute(
              'style',
              'display: none;'
            );
          }
        }
      }
    }
    this.formTracking();
  }

  public back() {
    if (this.camosConfiguratorComponent.formWithoutPartner) {
      this.camosConfiguratorComponent.formWithoutPartner = false;
      this.camosConfiguratorComponent.isInternational = true;
    } else {
      this.requestQuote.back(this.iNeedHelp, this.woConfiguration);
    }
    this.camosConfiguratorComponent.goToTop();
  }

  public async backToPromotions() {
    if (this.promotionComponent._promotionSetup) {
      this.camosPromotionsComponent.requestQuote = false;
      this.camosPromotionsComponent.leadCreated = false;
      this.camosPromotionsComponent.partnerPage = false;
      this.camosPromotionsComponent.opendistributionPartner = false;
      this.camosPromotionsComponent.distributionPartner = false;
      this.camosPromotionsComponent.opensaveConfig = false;
      this.camosPromotionsComponent.promotionPage = true;
      this.camosPromotionsComponent.promotionId = '';
      this.camosPromotionsComponent.promotionPrice = '';

      await this.store.dispatch(
        new SetDistributorInfos({
          distributors: [],
          selectedDistributor: null
        })
      );
      this.camosPromotionsComponent.goToTop();
    } else {
      this.camosConfiguratorComponent.requestQuote = false;
      this.camosConfiguratorComponent.leadCreated = false;
      this.camosConfiguratorComponent.notification = false;
      this.camosConfiguratorComponent.testWebsite = false;
      this.camosConfiguratorComponent.partnerPage = false;
      this.camosConfiguratorComponent.opendistributionPartner = false;
      this.camosConfiguratorComponent.distributionPartner = false;
      this.camosConfiguratorComponent.opensaveConfig = false;
      this.camosConfiguratorComponent.iNeedHelp = false;
      this.camosConfiguratorComponent.woConfiguration = false;
      this.camosConfiguratorComponent.promotionPage = true;
      this.camosConfiguratorComponent.promotionId = '';
      this.camosConfiguratorComponent.promotionPrice = '';
      this.camosConfiguratorComponent.goToTop();
    }
  }

  // Load Previously country
  async loadPrevCountry() {
    if (this.camosConfiguratorComponent.lastCountryValue != 'international') {
      await this.camosConfiguratorComponent.setSelectedCountry(
        this.camosConfiguratorComponent.lastCountryValue,
        true,
        true
      );
    }
    this.camosConfiguratorComponent.partnerPage = true;
    this.camosConfiguratorComponent.formWithoutPartner = false;
    this.camosConfiguratorComponent.lastCountryValue = undefined;
  }
}
