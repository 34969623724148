import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';

@Component({
  selector: 'app-cui-static-header',
  templateUrl: './cui-static-header.component.html',
  styleUrls: ['./cui-static-header.component.scss']
})
export class CuiStaticHeaderComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;

  constructor() {}

  ngOnInit(): void {}
}
