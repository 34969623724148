<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <div class="module-table-card table-striped">
    <div class="table-body">
      <div class="product-list-container">
        <div class="table-row">
          <div class="table-col col-5" style="padding-left: 0px">
            <div class="table-col-content">
              <div style="text-align: center">
                <div class="counter-quantity" style="margin-top: 0px">
                  <div
                    class="counter-quantity"
                    [style]="
                      widget.variable.validity.type === 'MayNot' ||
                      widget.variable.validity.type === 'Must'
                        ? 'pointer-events: none; cursor: default;'
                        : 'cursor: pointer;'
                    "
                  >
                    <span
                      class="minus rsi rsi-minus"
                      style="font-size: 0.875em"
                      (click)="subQuantity(widget.values.name)"
                    ></span>
                    <input
                      id="select_{{ widget.values.name }}"
                      class="counter"
                      type="text"
                      [value]="widget.values.name"
                      (change)="changeQuantity(widget.values.name)"
                    />
                    <span
                      class="plus rsi rsi-plus"
                      style="font-size: 0.875em"
                      (click)="addQuantity(widget.values.name)"
                    ></span>
                  </div>
                </div>
                <br />
                <div>{{ 'widgets.years' | i18next }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
