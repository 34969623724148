import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetReviewProducts } from './review.actions';
import { ReviewProduct } from './review.model';

@State<ReviewProduct>({
  name: 'review'
})
@Injectable()
export class ReviewState {
  constructor() {}

  ngxsOnInit(ctx: StateContext<ReviewProduct>) {
    console.log('start review ngxs');
  }

  @Selector()
  public static reviewProducts(products: ReviewProduct): ReviewProduct {
    return products;
  }

  @Action(SetReviewProducts)
  setReviewProducts(ctx: StateContext<ReviewProduct>, action: ReviewProduct) {
    const nextState = action;
    console.log('next state: ', nextState);
    ctx.setState(nextState);
  }
}
