import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { Lead } from '../leadmodel';
import { HttpClient } from '@angular/common/http';
// import { EnvService } from './../../env.service';
import { AdobeAnalyticsManager } from 'src/app/adobe/adobe-analytics-manager';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DistributionPartnerService {
  public interactionId: string;

  constructor(
    private http: HttpClient,
    // private env: EnvService,
    private store: Store,
    public AdobeAnalyticsManager: AdobeAnalyticsManager
  ) {}

  public getInteractionId() {
    return this.interactionId;
  }

  public async getDistributionPartners(productIds: string[], country) {
    let response = await firstValueFrom(
      this.http.post(environment.distributionUrl + '/channelpartner/aliases', {
        cpAlias: productIds.toString(),
        country: country
      })
    ).catch((error) => {
      console.log(error);
      return [];
    });
    if ((response as any).channelpartners.channelpartner) {
      return (response as any).channelpartners.channelpartner;
    } else {
      return [];
    }

    /*
    return fetch(this.env.distributionUrl + '/channelpartner/aliases', {
      body: `{"cpAlias":"${productIds}", "country":"${country}"}`,
      method: 'POST',
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.channelpartners.channelpartner;
    })
    .catch(error => {
      console.log(error);
      return;
    });
    */
  }

  public getLanguage(country: string) {
    return fetch(environment.apiUrl + '/country2language/' + country, {
      method: 'GET'
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.language;
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }

  public async hashEmail(email: string) {
    const bodyValue = {
      email: email
    };
    const response = await fetch(`${environment.apiUrl}/adobe`, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyValue),
      method: 'POST'
    });
    const data = await response.json();
    this.interactionId = data.interactionID;
    return data.email;
  }

  public async createLead(lead: Lead) {
    // console.log('lead',lead.Profile.C4C_ContactID);
    let mail = await this.hashEmail(lead.Profile.C4C_ContactID);
    this.AdobeAnalyticsManager.getInteractionId(this.interactionId);
    lead.InteractionData.AdobehashedEmail = mail;
    lead.InteractionData.AdobeInteractionID = this.interactionId;
    // console.log("LEAD:",lead);
    const msku = this.store.selectSnapshot(
      (state) => state.lead.configuratorSetup.msku
    );
    const arg = msku ? msku : undefined;
    return firstValueFrom(this.http.post(environment.apiUrl + '/leads', lead))
      .then((response) => {
        // console.log('create lead response: ', response);
        if ((response as any).message !== 'Lead created!') {
          this.AdobeAnalyticsManager.trackRequestQuoteError(arg);
          return false;
        }
        this.AdobeAnalyticsManager.trackRequestQuoteSuccess(arg, mail);
        return response;
      })
      .catch((error) => {
        console.error(error);
        // ToDo: return "an error occurred"
        return false;
      });
  }
}
