import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { DistributionPartnerService } from './services/distribution-partner.service';
import { Select, Store } from '@ngxs/store';
import { SetDistributorInfos } from '../store/distributionpartner/distributionpartner.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from '../app.component';
import { AdobeAnalyticsManager } from '../adobe/adobe-analytics-manager';
import { CamosPromotionsComponent } from '../promotion/camos-promotions/camos-promotions.component';
import { UIState } from '../store/ui/ui.state';
import { Observable } from 'rxjs';
import { PromotionComponent } from '../promotion/promotion.component';
import i18next from 'i18next';
import { CamosService } from '../services/camos.service';
import { DistributionPartnerState } from '../store/distributionpartner/distributionpartner.state';
import { Distributor } from '../store/distributionpartner/distributionpartner.model';

@Component({
  selector: 'app-request-quote-selected',
  templateUrl: './request-quote-selected.component.html',
  styleUrls: ['./request-quote-selected.component.scss']
})
export class RequestQuoteSelectedComponent implements OnInit {
  @Input() opendistributionPartner: boolean = false;
  @Input() distributionPartner: boolean = true;
  @Input() opensaveConfig: boolean = true;
  @Input() iNeedHelp?: boolean = false;
  @Input() woConfiguration?: boolean = false;
  @Input() promo?: boolean = false;
  @Input() country?: string;

  public partners: Distributor[] = [];
  public partner;
  public loading = false;
  public isNL = false;
  public nlPartners = [];
  public bePartners = [];
  public luxPartners = [];
  public backToPromotionPage: boolean = false;
  public isLoaded = false;
  public loaded = false;
  public isDualChannel: boolean;
  public shuffledPartners = [];
  public isInternational;
  public leadMode = false;
  public saved = false;

  @ViewChild('countryButton') countryButton: ElementRef;

  constructor(
    public store: Store,
    public distributionService: DistributionPartnerService,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent,
    public AdobeAnalyticsManager: AdobeAnalyticsManager,
    private camosPromotionsComponent: CamosPromotionsComponent,
    private promotionComponent: PromotionComponent,
    private camosService: CamosService
  ) {}

  @Select(DistributionPartnerState.distributors) distributors$: Observable<
    Distributor[]
  >;
  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;
  currentNaviNode: number = 0;

  async ngOnInit() {
    this.spinner.show('spinner');
    while (!this.store.snapshot().lead.configuratorSetup) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    const mode = this.store.snapshot().lead.configuratorSetup.leadMode;
    if (mode === 'true') {
      this.leadMode = true;
    }
    if (!this.leadMode) {
      this.isInternational = await this.camosService.checkCountry(
        this.appComponent.getProduct(),
        this.appComponent._configuratorSetup.country
      );
    }
    this.appComponent._configuratorSetup.country.toLowerCase() == 'com'
      ? (this.isInternational = false)
      : this.isInternational;
    this.isDualChannel = this.appComponent._configuratorSetup.dualChannel;
    this.currentNaviNode$.subscribe((num) => {
      if (num != undefined) {
        this.isLoaded = true;
      }
      this.currentNaviNode = num;
    });
    this.backToPromotionPage = this.camosConfiguratorComponent.hideProgressBar;
    if (this.promo && !this.appComponent.sessionError) {
      this.backToPromotionPage = true;
    }
    // this.spinner.show("spinner");
    if (this.leadMode) {
      this.spinner.hide('spinner');
      this.loaded = true;
      this.saveConfiguration();
    } else {
      this.distributors$.subscribe(async (num) => {
        while (!this.store.snapshot().distributionpartner.distributors) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        while (
          !this.store.snapshot().distributionpartner.distributors.distributors
        ) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        this.partners =
          this.store.snapshot().distributionpartner.distributors.distributors;
        let shuffledPartners = [];
        Object.assign(shuffledPartners, this.partners);
        shuffledPartners = shuffledPartners.sort(() => Math.random() - 0.5);
        this.shuffledPartners = shuffledPartners;
        let country =
          this.appComponent._configuratorSetup.country.toUpperCase();
        let product =
          this.appComponent._configuratorSetup.product.toLowerCase();
        if (this.promotionComponent._promotionSetup) {
          country =
            this.promotionComponent._promotionSetup.country.toUpperCase();
          product =
            this.promotionComponent._promotionSetup.product.toLowerCase();
        }
        if (country === 'NL') {
          this.isNL = true;
          this.spinner.show('spinner');

          this.partners.map((partner) => {
            (partner.assigned.country as any).map((country) => {
              switch (country._cdata) {
                case 'nl':
                  this.nlPartners.push(partner);
                  break;
                case 'lu':
                  this.luxPartners.push(partner);
                  break;
                case 'be':
                  this.bePartners.push(partner);
                  break;
                default:
                  break;
              }
            });

            const containsNL = (partner.assigned.country as any).some(
              (obj) => obj._cdata === 'nl'
            );
            const containsBE = (partner.assigned.country as any).some(
              (obj) => obj._cdata === 'be'
            );
            const containsLU = (partner.assigned.country as any).some(
              (obj) => obj._cdata === 'lu'
            );

            if (containsNL && containsBE && containsLU) {
              this.isNlPartnersSame = true;
            } else {
              this.isNlPartnersSame = false;
            }
          });

          // this.nlPartners = this.partners;
          // this.bePartners =
          //   await this.distributionService.getDistributionPartners(
          //     [product],
          //     'BE'
          //   );
          // this.luxPartners =
          //   await this.distributionService.getDistributionPartners(
          //     [product],
          //     'LU'
          //   );
          this.spinner.hide('spinner');
          this.loaded = true;
        }
        //TODO: has to be refactored -> should happen by the inital call for distribution partner
        if (
          country.toUpperCase() === 'COM' &&
          this.camosConfiguratorComponent.selectedCountry !== '' &&
          this.partners.length === 0
        ) {
          this.spinner.show('spinner');
          this.partners =
            await this.distributionService.getDistributionPartners(
              [product],
              this.camosConfiguratorComponent.selectedCountry
            );
          this.spinner.hide('spinner');
          this.loaded = true;
        }
        this.spinner.hide('spinner');
        this.loaded = true;
      });
      // if (this.partners.length === 0) {
      //   console.log("Lane")
      //   this.openRequestQuoteWithoutPartner();
      // }
    }
    // if ((this.iNeedHelp || this.woConfiguration) && this.store.snapshot().distributionpartner.distributors.distributors.length === 0) {
    //   this.openRequestQuoteWithoutPartner();
    // }
  }

  public isNlPartnersSame: boolean = false;

  public openRequestQuoteSelected() {
    this.distributionPartner = false;
    this.opendistributionPartner = false;
    this.opensaveConfig = false;
    this.camosConfiguratorComponent.goToTop();
    this.camosPromotionsComponent.goToTop();
  }

  public requestQuote() {
    // let productIds: string[] = ['Polo']; // for testing
    // let country = 'us'; // for testing
    if (
      this.store.snapshot().distributionpartner.distributors.distributors
        .length === 0
    ) {
      this.openRequestQuoteWithoutPartner();
    } else {
      this.country = this.appComponent._configuratorSetup.country;
      this.distributionPartner = true;
      this.opensaveConfig = !(
        this.camosConfiguratorComponent.iNeedHelp ||
        this.camosConfiguratorComponent.woConfiguration
      );
      this.opendistributionPartner = false;
      this.AdobeAnalyticsManager.trackRequestQuoteChannelPartner(undefined);
      this.camosConfiguratorComponent.goToTop();
      // this.camosPromotionsComponent.goToTop();
    }

    // ToDo get ProductIds from ProductStructure and country from webside
    // this.loading = true;
    // this.partners = await this.distributionService.getDistributionPartners(productIds, country)
    // .catch(e => this.loading = false);
    // this.store.dispatch(new SetDistributorInfos({
    //   distributors: this.partners,
    //   selectedDistributor: null
    // }));
    // this.loading = false;
  }

  public back(iNeedHelp: boolean, woConfiguration: boolean) {
    if (iNeedHelp || woConfiguration) {
      this.camosConfiguratorComponent.goBackToConfigType();
      this.distributionPartner = true;
      this.opensaveConfig = false;
      this.opendistributionPartner = false;
    } else {
      this.camosConfiguratorComponent.goBackRequestQuote();
      this.distributionPartner = false;
      this.opensaveConfig = false;
      this.opendistributionPartner = false;
    }
    this.camosConfiguratorComponent.goToTop();
  }

  public saveConfiguration() {
    // this.opensaveConfig = true;
    // this.distributionPartner = false;
    // this.opendistributionPartner = false;
    this.camosConfiguratorComponent.goToTop();
  }

  public openDistributionPartner(partner) {
    this.store.dispatch(
      new SetDistributorInfos({
        distributors: this.partners,
        selectedDistributor: partner
      })
    );
    this.opensaveConfig = false;
    this.partner = partner;
    this.opendistributionPartner = true;
    // this.camosConfiguratorComponent.goToTop();
  }

  public openNotSurePartner() {
    let partner = {
      assigned: { country: { _cdata: '' } },
      city: { _cdata: '' },
      companyname: { _cdata: i18next.t('distributionPartner.notSure.header') },
      country: { _cdata: i18next.t('distributionPartner.notSure.text') },
      deeplinks: { link: { _cdata: '' } },
      email: { _cdata: '' },
      fax: { _cdata: '' },
      identifier: { _cdata: '' },
      iframekey: { _cdata: '' },
      logo: { _cdata: 'Partner.svg' },
      phone: { _cdata: '' },
      street: { _cdata: '' },
      streetno: {},
      web: { _cdata: '' },
      zip: { _cdata: '' },
      debitor_id: { _cdata: '' },
      c4c_account_id: { _cdata: '' },
      partner_id: { _cdata: '' }
    };
    this.store.dispatch(
      new SetDistributorInfos({
        distributors: this.partners,
        selectedDistributor: partner
      })
    );
    this.opensaveConfig = false;
    this.partner = partner;
    this.opendistributionPartner = true;
    this.camosConfiguratorComponent.goToTop();
    this.camosPromotionsComponent.goToTop();
  }

  public openRusPartner() {
    let partner = {
      assigned: { country: { _cdata: '' } },
      city: { _cdata: '' },
      companyname: {
        _cdata: i18next.t('distributionPartner.rohdeSchwarz.header')
      },
      country: {
        _cdata: i18next.t('distributionPartner.rohdeSchwarz.description')
      },
      deeplinks: { link: { _cdata: '' } },
      email: { _cdata: '' },
      fax: { _cdata: '' },
      identifier: { _cdata: '' },
      iframekey: { _cdata: '' },
      logo: { _cdata: 'rus-logo2.svg' },
      phone: { _cdata: '' },
      street: { _cdata: '' },
      streetno: {},
      web: { _cdata: '' },
      zip: { _cdata: '' },
      debitor_id: { _cdata: '' },
      c4c_account_id: { _cdata: '' },
      partner_id: { _cdata: '' }
    };
    this.store.dispatch(
      new SetDistributorInfos({
        distributors: this.partners,
        selectedDistributor: partner
      })
    );
    this.opensaveConfig = false;
    this.partner = partner;
    this.opendistributionPartner = true;
    this.camosConfiguratorComponent.goToTop();
    this.camosPromotionsComponent.goToTop();
  }

  ngAfterViewInit() {
    this.waitForWindow().then(() => {
      const msku = this.appComponent._configuratorSetup.msku
        ? this.appComponent._configuratorSetup.msku
        : undefined;
      this.AdobeAnalyticsManager.trackRequestQuoteChannelPartner(msku);
    });
  }

  //* Step 5 needs to wait for digitalData
  async waitForWindow() {
    while (window['digitalData'] === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }

  public openRequestQuoteWithoutPartner() {
    this.store.dispatch(
      new SetDistributorInfos({
        distributors: this.partners,
        selectedDistributor: null
      })
    );
    this.distributionPartner = false;
    this.opensaveConfig = false;
    this.opendistributionPartner = true;
    this.camosConfiguratorComponent.goToTop();
    this.camosPromotionsComponent.goToTop();
  }

  // private setInteractionInfos(partner: any) {
  //   this.interactionInfos.subject = this.store.snapshot().ui.className;
  //   this.interactionInfos.content = 'Product URL: ' + window.location.origin + ', Distributor: ' + partner.companyname._cdata;
  //   this.interactionInfos.dataUrl = window.location.origin;
  //   this.interactionInfos.deviceType = this.store.snapshot().ui.uiCategory;
  // }

  public goToConfigType() {
    this.camosConfiguratorComponent.requestQuote = false;
    this.camosConfiguratorComponent.leadCreated = false;
    this.camosConfiguratorComponent.notification = false;
    this.camosConfiguratorComponent.testWebsite = false;
    this.camosConfiguratorComponent.partnerPage = false;
    this.camosConfiguratorComponent.opendistributionPartner = false;
    this.camosConfiguratorComponent.distributionPartner = false;
    this.camosConfiguratorComponent.opensaveConfig = false;
    this.camosConfiguratorComponent.iNeedHelp = false;
    this.camosConfiguratorComponent.woConfiguration = false;
    this.camosConfiguratorComponent.hideProgressBar = false;
    this.camosConfiguratorComponent.promotionPage = false;
    this.camosConfiguratorComponent.goToConfigType();
  }

  public async goToPromotionPage() {
    if (this.promo) {
      this.camosPromotionsComponent.requestQuote = false;
      this.camosPromotionsComponent.leadCreated = false;
      this.camosPromotionsComponent.partnerPage = false;
      this.camosPromotionsComponent.opendistributionPartner = false;
      this.camosPromotionsComponent.distributionPartner = false;
      this.camosPromotionsComponent.opensaveConfig = false;
      this.camosPromotionsComponent.promotionPage = true;
      this.camosPromotionsComponent.promotionId = '';
      this.camosPromotionsComponent.promotionPrice = '';

      await this.store.dispatch(
        new SetDistributorInfos({
          distributors: [],
          selectedDistributor: null
        })
      );
      this.camosPromotionsComponent.goToTop();
    } else {
      this.camosConfiguratorComponent.requestQuote = false;
      this.camosConfiguratorComponent.leadCreated = false;
      this.camosConfiguratorComponent.notification = false;
      this.camosConfiguratorComponent.testWebsite = false;
      this.camosConfiguratorComponent.partnerPage = false;
      this.camosConfiguratorComponent.opendistributionPartner = false;
      this.camosConfiguratorComponent.distributionPartner = false;
      this.camosConfiguratorComponent.opensaveConfig = false;
      this.camosConfiguratorComponent.iNeedHelp = false;
      this.camosConfiguratorComponent.woConfiguration = false;
      this.camosConfiguratorComponent.promotionPage = true;
      this.camosConfiguratorComponent.promotionId = '';
      this.camosConfiguratorComponent.promotionPrice = '';
      this.camosConfiguratorComponent.goToTop();
    }
  }

  //* Country Selector *//

  public displayCountrySelector: boolean = false;
  private style: object;

  public openCountrySelector() {
    this.displayCountrySelector = !this.displayCountrySelector;
    this.style = { top: '100%' };
    if (window.screen.width <= 641) {
      const countryButtonElement: HTMLElement =
        this.countryButton.nativeElement;
      this.style = {
        top: `calc(100% - ${countryButtonElement.offsetHeight}px)`
      };
    }
  }

  public async saveChanges() {
    console.log('save changes');
    const resp = await this.camosService.saveConfiguration();
    let configurationID = resp['configUUID'];
    const newItems = await this.camosService.getStructure(
      this.store.snapshot().lead.configuratorSetup.country
    );
    const leadWindow = window.opener;
    leadWindow.postMessage(
      { configurationId: configurationID, changes: true, items: newItems },
      localStorage.getItem('leadOrigin')
    );
    this.saved = true;
  }

  public closeAndUpdate() {
    console.log('close and update window');
    localStorage.removeItem('leadOrigin');
    window.close();
  }

  public cancelChanges() {
    console.log('close window');
    localStorage.removeItem('leadOrigin');
    window.close();
  }
}
