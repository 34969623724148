<app-cui-navi-nodes [nodes]="naviNodes"></app-cui-navi-nodes>
<app-cui-widget
  [widget]="widget"
  [promotion]="promotion"
  *ngFor="let widget of widgets; let i = index"
></app-cui-widget>
<app-cui-next-prev-nav
  [nodes]="naviNodes"
  [subConfig]="true"
></app-cui-next-prev-nav>
