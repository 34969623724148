import { Injectable } from '@angular/core';
import { UIModel } from './promotionUi.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  SelectPromoItem,
  SelectPromotionItem,
  Start,
  StartPromo
} from './promotionUi.actions';
import { Widget } from '../ui/ui.model';
import { CamosService } from 'src/app/services/camos.service';

@State<UIModel>({
  name: 'promotionUi'
})
@Injectable()
export class PromotionUIState {
  promotionsUi: UIModel;

  constructor(
    private store: Store,
    private camos: CamosService
  ) {}

  @Selector()
  public static start(state: UIModel): UIModel {
    return state;
  }

  @Selector()
  public static widgets(state: UIModel): Widget[] {
    return state.widgets;
  }

  @Action(Start)
  start(ctx: StateContext<UIModel>) {
    ctx.setState(this.promotionsUi);
  }

  @Action(SelectPromoItem)
  async selectItem(ctx: StateContext<UIModel>, action: SelectPromoItem) {
    const nextState = await this.camos.selectItemForWidget(
      action.itemName,
      action.widgetId,
      action.quantity,
      action.country
    );

    nextState.widgets.forEach((widget) => {
      widget.values.forEach((value) => {
        if (
          value.isSelected &&
          (value as any).salestext &&
          (value as any).salestext.length > 0
        ) {
          while ((value as any).salestext.includes('\n\n')) {
            (value as any).salestext = (value as any).salestext.replace(
              '\n\n',
              '\n'
            );
          }
        }
      });
    });

    ctx.setState(nextState);
  }

  @Action(StartPromo)
  async startPromo(
    ctx: StateContext<UIModel>,
    action: { country: string; product: string }
  ) {
    this.promotionsUi = await this.camos.getPromotionUi(
      action.product,
      action.country
    );
    ctx.setState(this.promotionsUi);
  }

  @Action(SelectPromotionItem)
  async selectPromoItem(
    ctx: StateContext<UIModel>,
    action: { product: string; country: string; widgetId: number; name: string }
  ) {
    let nextState = await this.camos.getPromotionUi(
      action.product,
      action.country
    );
    nextState.widgets.forEach((widget) => {
      if (widget.id == action.widgetId) {
        widget.values.forEach((value) => {
          if (value.name == action.name) {
            value.isSelected = 1;
            if (
              (value as any).salestext &&
              (value as any).salestext.length > 0
            ) {
              while ((value as any).salestext.includes('\n\n')) {
                (value as any).salestext = (value as any).salestext.replace(
                  '\n\n',
                  '\n'
                );
              }
            }
          }
        });
      }
    });
    ctx.setState(nextState);
  }
}
