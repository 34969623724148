import { Injectable } from '@angular/core';
import { ConfiguratorSetup } from '../store/lead/lead.model';
import { DigitalData } from './digital-data';

declare let _satellite: any;

@Injectable({
  providedIn: 'root'
})
export class AdobeAnalyticsManager {
  private digitalData: DigitalData;
  private initialized: boolean = false;
  private configuratorId: string;
  private conf: ConfiguratorSetup;
  private interactionId: string;

  constructor() {}

  init() {
    this.createDigitalData();
    this.digitalData = window['digitalData'];
    this.initialized = true;
  }

  private createDigitalData() {
    const data = window['digitalData'];
    data['form'] = {
      name: 'ProductConfigurator',
      stepName: '',
      stepNumber: '',
      status: '',
      sku: ''
    };
    (data['m4c'] = {
      interactions: {
        interactionData: {
          campaignID: '',
          interactionType: '',
          itemOfInterests: {
            ItemOfInterest: ''
          }
        }
      }
    }),
      (data.page.applicationName = 'TAP Product Configurator'),
      (data.page.applicationType = 'CAMOS');
  }

  sessionAttempt() {
    if (this.initialized) {
      _satellite.track('connection-attempt');
    }
  }

  setErrorData() {
    if (this.initialized) {
      this.digitalData.page.error = {
        code: 500,
        type: 'Application',
        message: 'Internal Server Error'
      };
      _satellite.track('error');
    }
  }

  getConfigData(config: ConfiguratorSetup) {
    this.conf = config;
  }

  getConfId(id: string) {
    if (id === undefined) {
      this.configuratorId = 'PRECONFIGURED_PRODUCT';
    } else {
      this.configuratorId = id;
    }
  }

  getInteractionId(id: string) {
    this.interactionId = id;
  }

  setM4cData() {
    if (this.initialized) {
      try {
        const campaignID = this.conf.m4c_campaignId;
        const interactionType = this.conf.m4c_interactionType;
        const itemOfInterest = this.conf.m4c_ioi;
        const productOfInterest = this.conf.product;
        let array = [];
        array.push(itemOfInterest);
        this.digitalData.m4c.interactions.interactionData.campaignID =
          campaignID;
        this.digitalData.m4c.interactions.interactionData.interactionType =
          interactionType;
        this.digitalData.m4c.interactions.interactionData.itemOfInterests =
          array;
        this.digitalData.m4c.interactions.interactionData.productInterest1 =
          productOfInterest;
      } catch (error) {
        console.log(error);
      }
    }
  }

  trackOpenConfigurator(sku: string) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Start';
      this.digitalData.form.stepNumber = '1';
      this.digitalData.form.status = 'Open';
      this.digitalData.form.sku = sku;
      this.setM4cData();
      _satellite.track('form');
    }
  }

  trackBaseModelSelection(sku: string) {
    this.setM4cData();
    if (this.initialized) {
      this.digitalData.form.stepName = 'Base model selection';
      this.digitalData.form.stepNumber = '2';
      this.digitalData.form.status = 'Open';
      this.digitalData.form.sku = sku;
      _satellite.track('form');
    }
  }

  trackConfiguration() {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Configuration';
      this.digitalData.form.stepNumber = '3';
      this.digitalData.form.status = 'Open';
      this.setM4cData();
      _satellite.track('form');
    }
  }

  trackReview() {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Review';
      this.digitalData.form.stepNumber = '4';
      this.digitalData.form.status = 'Open';
      this.setM4cData();
      _satellite.track('form');
    }
  }

  // trackRequestQuoteChoice() {
  //   if (this.initialized) {
  //       this.digitalData.form.stepName='Request a Quote: Request or Save';
  //       this.digitalData.form.stepNumber='5';
  //       this.digitalData.form.status='Open';
  //       this.setM4cData();
  //       _satellite.track('form');
  //   }
  // }

  trackRequestQuoteChannelPartner(msku: any) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Request a Quote: Channel Partner';
      this.digitalData.form.stepNumber = '5';
      this.digitalData.form.status = 'Open';
      if (msku !== undefined) {
        this.digitalData.form.sku = msku;
      }
      this.setM4cData();
      _satellite.track('form');
    }
  }

  trackRequestQuoteForm(msku: any) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Request a Quote: Form';
      this.digitalData.form.stepNumber = '6';
      this.digitalData.form.status = 'Open';
      if (msku !== undefined) {
        this.digitalData.form.sku = msku;
      }
      this.setM4cData();
      _satellite.track('form');
    }
  }

  trackRequestQuoteSuccess(msku: any, email: string) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Request a Quote: Success';
      this.digitalData.form.stepNumber = '7';
      this.digitalData.form.status = 'Success';
      this.digitalData.visitor.hashedEmail = email;
      if (msku !== undefined) {
        this.digitalData.form.sku = msku;
      }
      this.setM4cData();
      this.digitalData.m4c.interactions.interactionData.CAMOSConfigurationID =
        this.configuratorId;
      this.digitalData.m4c.interactions.interactionData.interactionID =
        this.interactionId;
      _satellite.track('form');
    }
  }

  trackRequestQuoteError(msku: any) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Request a Quote: Form';
      this.digitalData.form.stepNumber = '6';
      this.digitalData.form.status = 'Error';
      if (msku !== undefined) {
        this.digitalData.form.sku = msku;
      }
      this.setM4cData();
      _satellite.track('form');
    }
  }

  trackCamosError(step: string) {
    if (this.initialized) {
      this.digitalData.form.stepName = 'Camos Error';
      this.digitalData.form.stepNumber = `${step}`;
      this.digitalData.form.status = 'Error';
      this.setM4cData();
      _satellite.track('form');
    }
  }
}
