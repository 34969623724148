import { Component, OnInit } from '@angular/core';
import { RequestQuoteSelectedComponent } from 'src/app/request-quote-selected/request-quote-selected.component';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class RequestQuoteComponent implements OnInit {
  constructor(public requestComponent: RequestQuoteSelectedComponent) {}

  ngOnInit(): void {}
}
