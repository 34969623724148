import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { CamosService } from '../services/camos.service';
import { SelectNavinode } from '../store/ui/ui.actions';
import { ReviewProduct } from '../store/review/review.model';
import { SetReviewProducts } from '../store/review/review.actions';
import { SetErrorState } from '../store/error/error.actions';
import { State } from '../errors-view/error-model';
import { AdobeAnalyticsManager } from '../adobe/adobe-analytics-manager';
import { AppComponent } from '../app.component';
import { PriceService } from '../services/price.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  // reviewList: ReviewProduct[];
  structure: ReviewProduct;
  public netPrice = 0;
  public taxPrice = 0;
  public totalPrice = '';
  public loading = false;
  public leadMode = false;

  public requestQuote = false;

  constructor(
    private store: Store,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camos: CamosService,
    public AdobeAnalyticsManager: AdobeAnalyticsManager,
    public appComponent: AppComponent,
    private priceService: PriceService
  ) {}

  async ngOnInit() {
    try {
      this.loading = true;
      const mode = this.store.snapshot().lead.configuratorSetup.leadMode;

      if (mode === 'true') {
        this.leadMode = true;
      }
      this.structure = await this.camos.getReviewStructure(
        this.store.snapshot().lead.configuratorSetup.country
      );
      this.totalPrice = this.priceService.calculateTotalPrice(this.structure);
      this.store.dispatch(new SetReviewProducts(this.structure));
      this.loading = false;
    } catch (e) {
      setTimeout(() => {
        this.store.dispatch(new SetErrorState(State.review));
        this.camosConfiguratorComponent.showErrorPage();
        this.AdobeAnalyticsManager.trackCamosError('3');
      }, 2000);
    }
  }

  ngAfterViewInit() {
    // if (Object.keys(this.store.snapshot().review).length === 0) {
    //   this.continueButton()
    // }
    this.AdobeAnalyticsManager.trackReview();
  }

  public continueButton() {
    this.camosConfiguratorComponent.goToRequestQuote();
  }

  public changeBaseModel() {
    this.store.dispatch(
      new SelectNavinode(
        this.store.snapshot().ui.naviWidgets[0].navinodes[0].id
      )
    );
    this.camosConfiguratorComponent.loadComponent('manual-configurator');
  }

  public backToEdit() {
    this.store.dispatch(
      new SelectNavinode(
        this.store.snapshot().ui.naviWidgets[0].navinodes[
          this.store.snapshot().ui.naviWidgets[0].navinodes.length - 3
        ].id
      )
    );
    this.camosConfiguratorComponent.loadComponent('manual-configurator');
  }

  public backToConfiguration() {
    this.store.dispatch(
      new SelectNavinode(
        this.store.snapshot().ui.naviWidgets[0].navinodes[
          this.store.snapshot().ui.naviWidgets[0].navinodes.length - 1
        ].id
      )
    );
    this.camosConfiguratorComponent.loadComponent('manual-configurator');
  }
}
