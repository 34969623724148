<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <app-option-table
    [widget]="widget"
    [values]="widget.values"
    [type]="'multi'"
    *ngIf="!widget.xtIsBaseList"
  ></app-option-table>
</div>
