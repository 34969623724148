import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { RequestQuoteSelectedComponent } from './../request-quote-selected.component';
import { CamosService } from '../../services/camos.service';
import { AppComponent } from 'src/app/app.component';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from '../../camos-configurator/camos-configurator.component';

@Component({
  selector: 'app-save-configuration-code',
  templateUrl: './save-configuration-code.component.html',
  styleUrls: ['./save-configuration-code.component.scss']
})
export class SaveConfigurationCodeComponent implements OnInit {
  @Output() changes = new EventEmitter();
  @Output() selectorChange = new EventEmitter();

  public configurationID: string;
  public leadMode = false;
  public saved = false;

  constructor(
    public requestQuoteSelected: RequestQuoteSelectedComponent,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camos: CamosService,
    private appComponent: AppComponent,
    private store: Store
  ) {}

  async ngOnInit() {
    const mode = this.store.snapshot().lead.configuratorSetup.leadMode;
    if (mode === 'true') {
      this.leadMode = true;
    }

    if (!this.leadMode) {
      const resp = await this.camos.saveConfiguration();
      console.log(resp);
      if (resp) {
        this.configurationID = resp['configUUID'];
        localStorage.setItem('camosConfigurationID', this.configurationID);
      }
    }
  }

  copyToClipboard() {
    console.log('copy to clipboard');

    let textToCopy = document.getElementById('configCode');
    if (navigator.clipboard && textToCopy)
      navigator.clipboard.writeText(textToCopy.innerText);
    else console.log('Browser Not compatible');
    // Old
    // const el = document.createElement('textarea');
    // console.log(el);
    // el.style.display = 'none';
    // el.value = this.configurationID;
    // console.log(el);
    // document.getElementById('config').appendChild(el);
    // console.log('appendChild');
    // el.focus();
    // el.select();
    // console.log(el.innerText);
    // let status = document.execCommand('copy');
    // console.log(status);
    // document.getElementById('config').removeChild(el);
    // console.log('done');
  }

  shareConfiguration() {
    let subject =
      'Rohde %26 Schwarz ' + this.appComponent.getProduct() + ' configuration';
    let body =
      'This is the configuration code for Rohde %26 Schwarz ' +
      this.appComponent.getProduct() +
      ':%0d%0a' +
      this.configurationID +
      '%0d%0a%0d%0aPlease visit the product configurator page to restore the configuration: ' +
      window.location.href.split('#')[0] +
      '.';
    window.location.href = `mailto:?to=&subject=${subject}&body=${body}`;
  }

  // ISN'T IN USE (if used language has to be passed in getStructure func)
  // public async saveChanges() {
  //   console.log('save changes');
  //   const resp = await this.camos.saveConfiguration();
  //   this.configurationID = resp['configUUID'];
  //   const newItems = await this.camos.getStructure(this.store.snapshot().lead.configuratorSetup.country);
  //   const leadWindow = window.opener;
  //   leadWindow.postMessage({ configurationId: this.configurationID, changes: true, items: newItems}, localStorage.getItem('leadOrigin'));
  //   this.saved = true;
  // }

  public closeAndUpdate() {
    console.log('close and update window');
    localStorage.removeItem('leadOrigin');
    window.close();
  }

  public cancelChanges() {
    console.log('close window');
    localStorage.removeItem('leadOrigin');
    window.close();
  }
}
