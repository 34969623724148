import { CuiCompSingleTableComponent } from 'src/app/camos-widgets/cui-comp-single-table/cui-comp-single-table.component';
import { CuiCompMultiTableComponent } from 'src/app/camos-widgets/cui-comp-multi-table/cui-comp-multi-table.component';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { Component, Input } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-option-table',
  templateUrl: './option-table.component.html',
  styleUrls: ['./option-table.component.scss']
})
export class OptionTableComponent {
  @Input() values: object;
  @Input() widget: object;
  @Input() type: string;
  constructor(
    public cuiComponent: CuiCompSingleTableComponent,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public cuiComponentMulti: CuiCompMultiTableComponent,
    public appComponent: AppComponent
  ) {}
}
