import { Component, Input, OnInit } from '@angular/core';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { CamosService } from '../services/camos.service';
import { Store, Select } from '@ngxs/store';
import { LoadConfig, StartSession } from '../store/ui/ui.actions';
import { Observable, firstValueFrom } from 'rxjs';
import { UIState } from '../store/ui/ui.state';
import { UIModel } from '../store/ui/ui.model';
import { SetErrorState } from '../store/error/error.actions';
import { State } from '../errors-view/error-model';
import { AppComponent } from '../app.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Start } from '../store/promotionUi/promotionUi.actions';
import { SetDistributorInfos } from '../store/distributionpartner/distributionpartner.actions';
import { DistributionPartnerService } from '../request-quote-selected/services/distribution-partner.service';

@Component({
  selector: 'app-configuration-code',
  templateUrl: './configuration-code.component.html',
  styleUrls: ['./configuration-code.component.scss']
})
export class ConfigurationCodeComponent implements OnInit {
  @Input() startSession: string;

  public configCode = '';
  public notFound = false;
  public empty = false;
  public show = true;

  // @Select(UIState.nextNaviNode) nextNavNode$: Observable<NaviNode>;
  // nextNavNode: NaviNode;

  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;
  disable = true;

  @Select(UIState.start) ui$: Observable<UIModel>;
  ui: UIModel;

  constructor(
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camos: CamosService,
    private store: Store,
    private appComponent: AppComponent,
    private spinner: NgxSpinnerService,
    public distributionService: DistributionPartnerService
  ) {}

  ngOnInit(): void {
    if (
      this.appComponent._configuratorSetup.partnerId &&
      this.appComponent._configuratorSetup.configId
    ) {
      this.show = false;
    }
    this.currentNaviNode$.subscribe((num) => {
      if (num != undefined) {
        this.disable = false;
      }
    });
    if (!this.startSession && this.startSession.length == 0) {
      this.disable = false;
    }
    if (localStorage.getItem('camosConfigurationID')) {
      this.configCode = localStorage.getItem('camosConfigurationID');
      console.log('camosID: ', this.configCode);
      // document.getElementById('configCode').setAttribute('value', this.configCode);
    }
  }

  ngAfterViewInit() {
    if (
      localStorage.getItem('camosConfigurationID') &&
      !this.appComponent._configuratorSetup.configId
    ) {
      document
        .getElementById('configCode')
        .setAttribute('value', this.configCode);
    }
    if (
      localStorage.getItem('camosConfigurationID') &&
      this.appComponent._configuratorSetup.leadMode === 'true'
    ) {
      document
        .getElementById('configCode')
        .setAttribute('value', this.configCode);
    }
    this.spinner.hide('spinnerConf');
    if (
      this.appComponent._configuratorSetup.partnerId &&
      this.appComponent._configuratorSetup.configId
    ) {
      this.spinner.show('spinnerConf');
      this.loadConfig(this.appComponent._configuratorSetup.configId);
    }
  }

  public async loadConfig(configCode: string) {
    try {
      if (configCode && configCode.length > 0 && configCode != 'null') {
        this.empty = false;
        this.spinner.show('spinnerConf');
        if (!this.startSession && this.startSession.length == 0) {
          // await this.camos.startSession('', configCode);
          await firstValueFrom(
            this.store.dispatch(
              new StartSession(
                this.appComponent._configuratorSetup.country.toLocaleUpperCase(),
                this.appComponent._configuratorSetup.product,
                configCode,
                this.appComponent.language,
                this.appComponent._configuratorSetup.baseModel
              )
            )
          );
          await this.store.dispatch(new Start());
          this.ui$.subscribe((_ui) => (this.ui = _ui));
          this.distributionService
            .getDistributionPartners(
              [this.ui.knbName.split('.')[1].toLowerCase()],
              this.appComponent._configuratorSetup.country.toLocaleUpperCase()
            )
            .then((partners) => {
              this.store.dispatch(
                new SetDistributorInfos({
                  distributors: partners,
                  selectedDistributor: null
                })
              );
            });
          this.spinner.show('spinnerConf');
        }
        console.log('load config: ', configCode);
        const resp = await this.camos.loadConfiguration(configCode);
        if (resp['load']) {
          this.notFound = false;
          // this.nextNavNode$.subscribe((next) => (this.nextNavNode = next));
          await firstValueFrom(
            this.store.dispatch(
              new LoadConfig(
                configCode,
                this.store
                  .snapshot()
                  .lead.configuratorSetup.country.toLocaleUpperCase(),
                this.appComponent.language
              )
            )
          );
          // await this.store.dispatch(new SelectNavinode(this.nextNavNode.id)).toPromise();

          this.camosConfiguratorComponent.loadComponent('review');
        } else {
          this.notFound = true;
          document
            .getElementById('configCode')
            .setAttribute('style', 'width: 350px; border: 1px solid red;');
          console.log('config not found!');
        }
        this.spinner.hide('spinnerConf');
      } else {
        this.empty = true;
        document
          .getElementById('configCode')
          .setAttribute('style', 'width: 350px; border: 1px solid red;');
        console.log('configCode empty!');
      }
    } catch (e) {
      this.store.dispatch(new SetErrorState(State.loadconfig));
      this.camosConfiguratorComponent.showErrorPage();
    }
  }
}
