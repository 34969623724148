export class UIModel {
  id: number;
  knbName: string;
  knbVersion: number;
  className: string;
  category: string;
  version: number;
  type: string;
  naviWidgets: NaviWidgets[];
  layout: Layout;
  widgets: Widget[];
  promoPrices?: Value[];
}

class Layout {
  type: string;
  gridColumns?: number;
  gridRows?: number;
}

class NaviWidgets {
  type: string;
  navinodes: NaviNode[];
}

export class NaviNode {
  id: number;
  type: string;
  naming: string;
  isSelected: number;
  isValid: number;
}

export class Widget {
  id: number;
  isExpanded?: number;
  name: string;
  naming: string;
  type: string; // enum?
  isReadonly: number;
  isEnabled: number;
  xtAllowedValuesOnly?: number;
  xtGraphicShow?: number;
  xtInfoIconShow?: number;
  xtQuantityShow?: number;
  xtIsBaseList?: number;
  widgetProperty: {
    propertyFrequencyRange?: string;
    propertyNumberOfChannels?: string;
    propertyVoltage?: string;
  };
  variable: Variable;
  values?: Value[];
  widgets?: Widget[];
  isValid?: number;
  layout?: Layout;
  grid?: {
    column: any;
    columnSpan: any;
    row: any;
    rowSpan: any;
  };
}

class Item {
  name?: string;
  naming: string;
  validity: {
    type: string;
    explanation: null;
  };
  price?: string;
  quantity?: number;
}

export class Value {
  name?: string;
  naming: string;
  description?: string;
  validity: {
    type: string;
    explanation?: string;
  };
  isSelected: number;
  price?: string;
  quantity?: number;
  cType?: number;
  salesText?: string;
}

class Variable {
  name: string;
  type: string;
  valueType?: string;
  isList?: number;
  hasUnitOfQuantity?: number;
  validity: {
    type: string;
    explanation?: string;
  };
  naming: string;
}

export class Product {
  id: number;
  name: string;
  naming: string;
  validity: {
    type: string;
    explanation?: string;
  };
}
