<!--<h1>{{widget.nameDisplay}} - {{widget.variable.nameDisplay}}</h1>-->

<!--
<div>
    <label class="label-header" >
        {{widget.variable.nameDisplay}}
    </label>
</div>
-->

<div class="module module-headline">
  <div class="headline-row">
    <div class="headline-column">
      <h3>{{ widget.variable.naming }}</h3>
    </div>
  </div>
</div>
