<span *ngIf="!promotion">
  <app-cui-widget
    [widget]="widget"
    *ngFor="let widget of widgets; let i = index"
  ></app-cui-widget>
  <!-- <app-cui-widget [widget]="widget" [promotion]="promotion" *ngFor="let widget of promoWidgets; let i = index"></app-cui-widget> -->
</span>

<span *ngIf="promotion">
  <app-cui-widget
    [widget]="widget"
    [promotion]="promotion"
    *ngFor="let widget of promoWidgets; let i = index"
  ></app-cui-widget>
</span>
