<div *ngIf="conflict">
  <app-conflict-notification
    [add]="true"
    [remove]="false"
    [naviNode]="naviNode"
  ></app-conflict-notification>
</div>
<div class="category-wrapper">
  <div
    [class]="
      naviNode.isSelected === 1 && !naviNode.isSelected
        ? 'category-container active'
        : naviNode.isSelected === 1
          ? 'category-container active configured'
          : currentNaviNode !== 0 && index <= currentNaviNode
            ? 'category-container configured'
            : 'category-container'
    "
    [style.cursor]="
      disabled && !naviNode.isSelected ? 'not-allowed' : 'pointer'
    "
    (click)="select()"
    id="config_button_{{ naviNode.id }}"
  >
    <div class="circle-container">
      <div
        [style.background-color]="!naviNode.isValid ? 'red' : ''"
        class="circle"
      >
        <div *ngIf="naviNode.isValid" class="circle-container-circle"></div>
      </div>
    </div>
    <div
      class="category"
      [style.color]="
        disabled && !naviNode.isSelected
          ? naviNode.isValid
            ? '#b7bcc0'
            : 'red'
          : 'none'
      "
    >
      <span
        [style.color]="!naviNode.isValid ? 'red' : ''"
        style="font-weight: 400"
      >
        {{ naviNode.naming }}
      </span>
    </div>
  </div>
</div>
