<div *ngIf="items.parentuuid == 'Root'">
  <div
    class="table-body"
    *ngIf="showConfig"
    [style]="show ? 'border-bottom: 0px;' : ''"
  >
    <div class="parent header">
      <div class="div1">
        <p>
          {{ 'general.table.header.description' | i18next }}
        </p>
      </div>
      <div class="div5">
        <p>
          {{ 'general.table.header.price' | i18next }}
        </p>
      </div>
    </div>
    <div *ngFor="let item of items.values">
      <div *ngIf="item.parentuuid == parentuuid && item.values">
        <div
          class="parent"
          [style]="
            !item.isExpanded
              ? 'border-bottom: 1px solid #e2e2e2'
              : 'border-bottom: 0px'
          "
        >
          <div
            class="div1"
            [style]="!item.isExpanded ? '' : 'padding-bottom: 0px;'"
          >
            {{ item.name }} <br />
            <p
              [ngClass]="
                item.isExpanded
                  ? 'button-tertiary downButton active'
                  : 'button-tertiary downButton'
              "
              style="cursor: pointer; color: #009bea; margin-bottom: 0"
              (click)="expandOrCollapse(item.uuid)"
            >
              <span *ngIf="!item.isExpanded">{{
                'review.header.showDetails' | i18next
              }}</span>
              <span *ngIf="item.isExpanded">{{
                'review.header.lessDetails' | i18next
              }}</span>
            </p>
          </div>
          <div class="div5">
            {{ camosConfiguratorComponent.numberWithCommas(item.price) }}
          </div>
        </div>
        <div
          [style]="
            item.isExpanded
              ? 'height: auto; transition: height 300ms; border-bottom: 1px solid #e2e2e2;'
              : 'height: 0px; overflow: hidden; transition: height 300ms; border-bottom: 0px;'
          "
        >
          <div class="module module-headline">
            <div class="headline-row">
              <div class="headline-column">
                <h3>{{ 'review.header.details' | i18next }}</h3>
              </div>
            </div>
          </div>
          <div style="margin: 5px 50px 25px 50px; border: 1px solid #e2e2e2">
            <div class="parent header">
              <div class="div1">
                <p *ngIf="type !== 'review'">
                  {{ 'general.table.header.modelName' | i18next }}
                </p>
                <p *ngIf="type == 'review'" class="">
                  {{ 'general.table.header.product' | i18next }}
                </p>
              </div>
              <div class="div2">
                <p *ngIf="type !== 'review'">Ports</p>
                <p *ngIf="type == 'review'" class="">
                  {{ 'review.header.matNo' | i18next }}
                </p>
              </div>
              <div class="div3">
                <p *ngIf="type !== 'review'">Frequency</p>
                <p *ngIf="type == 'review'" class="">
                  {{ 'general.table.header.description' | i18next }}
                </p>
              </div>
              <div class="div4">
                <p *ngIf="type !== 'review'">Connectors</p>
                <p *ngIf="type == 'review'" class="">
                  {{ 'review.header.quantity' | i18next }}
                </p>
              </div>
              <div class="div5">
                <p>{{ 'general.table.header.price' | i18next }}</p>
              </div>
              <div class="div6"></div>
            </div>
            <div *ngFor="let item of item.values">
              <app-review-table-body
                *ngIf="!item.values"
                [item]="item"
              ></app-review-table-body>
              <div *ngIf="item.values">
                <div *ngFor="let item of item.values">
                  <app-review-table-body [item]="item"></app-review-table-body>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-body" *ngIf="show">
    <div class="parent header">
      <div class="div1">
        <p *ngIf="type !== 'review'">
          {{ 'general.table.header.modelName' | i18next }}
        </p>
        <p *ngIf="type == 'review'" class="">
          {{ 'general.table.header.product' | i18next }}
        </p>
      </div>
      <div class="div2">
        <p *ngIf="type !== 'review'">Ports</p>
        <p *ngIf="type == 'review'" class="">
          {{ 'review.header.matNo' | i18next }}
        </p>
      </div>
      <div class="div3">
        <p *ngIf="type !== 'review'">Frequency</p>
        <p *ngIf="type == 'review'" class="">
          {{ 'general.table.header.description' | i18next }}
        </p>
      </div>
      <div class="div4">
        <p *ngIf="type !== 'review'">Connectors</p>
        <p *ngIf="type == 'review'" class="">
          {{ 'review.header.quantity' | i18next }}
        </p>
      </div>
      <div class="div5">
        <p>{{ 'general.table.header.price' | i18next }}</p>
      </div>
      <div class="div6"></div>
    </div>
    <div *ngFor="let item of items.values">
      <div *ngIf="item.parentuuid == parentuuid && !item.values">
        <app-review-table-body [item]="item"></app-review-table-body>
      </div>
    </div>
  </div>
</div>
