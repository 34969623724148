<div
  *ngIf="widget.variable.validity.type !== 'InvisibleOnly'"
  [style.opacity]="
    widget.variable.validity.type === 'MayNot' ||
    widget.variable.validity.type === 'Must'
      ? 0.5
      : 1
  "
>
  <div class="module-table-card table-striped module-tap-multi-select-quantity">
    <div class="table-body">
      <div class="product-list-container">
        <div class="table-row">
          <div class="table-col col-4">
            <div class="table-col-body">
              {{ 'widgets.singleComboboxBody' | i18next }}
            </div>
          </div>
          <div class="table-col col-6">
            <div class="table-col-content">
              <select
                (change)="selectItem($event.target.value)"
                required="required"
                data-error-message="Please check your input."
              >
                <option
                  class="serviceOption"
                  *ngFor="let item of widget.values"
                  [value]="item.name"
                  [selected]="item.isSelected"
                >
                  {{ item.naming }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
