import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { PromotionSetup } from '../store/lead/lead.model';
import { Store } from '@ngxs/store';
import { StopSession } from '../store/ui/ui.actions';
import { SetFields } from '../store/lead/lead.actions';
import { DistributionPartnerService } from '../request-quote-selected/services/distribution-partner.service';
import { AdobeAnalyticsManager } from '../adobe/adobe-analytics-manager';
import { CamosService } from '../services/camos.service';
// import { EnvService } from '../env.service';
import { StartPromo } from '../store/promotionUi/promotionUi.actions';
import { AppComponent } from '../app.component';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
// import { CamosPromotionsComponent } from './camos-promotions/camos-promotions.component';

@Component({
  selector: 'app-tap-promotions',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {
  promoSetupCalled = false;

  public isPrice: boolean;

  @Input('promotion-setup')
  get promotionSetup() {
    return JSON.stringify(this._promotionSetup);
  }
  set promotionSetup(_promotionSetupString: string) {
    this.promoSetupCalled = true;
    console.log('Input: ' + _promotionSetupString);
    JSON.parse(_promotionSetupString).price !== undefined
      ? (this.isPrice = JSON.parse(_promotionSetupString)?.price)
      : (this.isPrice = true);
    // console.log("isPrice",this.isPrice);
    try {
      this.appComponent._configuratorSetup.country = JSON.parse(
        _promotionSetupString
      ).country;
    } catch (error) {}
    try {
      if (!_promotionSetupString) {
        throw new Error('_promotionSetupString not set');
      }
      this._promotionSetup = JSON.parse(_promotionSetupString);
      for (const prop in this._promotionSetup) {
        // console.log(prop)
      }
      // this.ngOnInit();
    } catch (err) {
      console.warn(err);
      console.warn('Cannot read promotion Setup Input');
    }
  }
  public _promotionSetup: PromotionSetup;

  private mutationObserver;

  constructor(
    private store: Store,
    public distributionService: DistributionPartnerService,
    public _cd: ChangeDetectorRef,
    private adobeAnalyticsManager: AdobeAnalyticsManager,
    private camosService: CamosService,
    private appComponent: AppComponent // private env: EnvService,
  ) {
    // private camosPromotion:CamosPromotionsComponent
    this.camosService.getStage().then((resp) => {
      environment.stage = resp.stage;
      // console.log('set Stage: ', env.stage)
    });
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
    }
  }

  async ngOnInit() {
    if (document.getElementById('tap-promotions')) {
      this.mutationObserver = new MutationObserver(this.callback.bind(this));
      this.mutationObserver.observe(document.getElementById('tap-promotions'), {
        attributes: true
      });
    }
    window.onbeforeunload = (ev) => {
      if (this.camosService.session) {
        firstValueFrom(
          this.store.dispatch(new StopSession(this.camosService.session))
        ).then();
      }
    };
    const country = this.getParam('country');
    const product = this.getParam('product');
    const ver = this.getParam('ver');
    const verproduct = this.getParam('verproduct');
    const environment = this.getParam('environment');

    if (country) {
      this._promotionSetup.country = country;
    }
    if (product) {
      this._promotionSetup.product = product;
    }
    if (ver) {
      this._promotionSetup.ver = ver;
    }
    if (verproduct) {
      this._promotionSetup.verproduct = verproduct;
    }
    if (environment) {
      this._promotionSetup.environment = environment;
    }

    await this.startSession();
  }

  public async startSession() {
    this._promotionSetup.bundleMode = 'true';
    if (
      this._promotionSetup.product &&
      this._promotionSetup.product.length > 0
    ) {
      this._cd.detectChanges();
      await this.store.dispatch(
        new StartPromo(
          this._promotionSetup.country,
          this._promotionSetup.product
        )
      );
      this.store.dispatch(new SetFields(this._promotionSetup));
    } else {
      this._cd.detectChanges();
      this.store.dispatch(new SetFields(this._promotionSetup));
    }
  }

  ngAfterViewInit() {
    this.waitForWindow().then((resp) => {
      console.log('Digital Data is ready!✅✅✅');
      this.adobeAnalyticsManager.init();
      this.adobeAnalyticsManager.getConfigData(this._promotionSetup);
      let sku;
      // (this.camosPromotion.promotionId == '') ? sku = '123' : sku = this.camosPromotion.promotionId;
      //this.adobeAnalyticsManager.trackOpenConfigurator('320');
      //console.log(this._promotionSetup)
    });
  }

  private callback(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (
        mutation.attributeName === 'class' &&
        !mutation.target.classList.contains('module-web-component')
      ) {
        // console.log('tap-webcomponent is closed');
        if (this.camosService.session) {
          firstValueFrom(
            this.store.dispatch(new StopSession(this.camosService.session))
          ).then();
        }
        this.mutationObserver.disconnect();
      }
    });
  }

  async waitForWindow() {
    while (window['digitalData'] === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  public getProduct() {
    return this._promotionSetup.product;
  }

  private getParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
      window.location.href
    );
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  private getCountry() {
    let urls = window.location.href.split('/');
    return urls[3];
  }

  private receiveMessage(event: any) {
    // console.log('received event: ', event.data);
    localStorage.setItem('leadOrigin', event.data);
  }
}
