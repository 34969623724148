import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CamosService } from './../services/camos.service';
import i18next from 'i18next';
import { ValidationSetup } from '../store/lead/lead.model';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent {
  validationSetupCalled = false;

  @Input('validation-setup')
  get validationSetup() {
    return JSON.stringify(this._validationSetup);
  }
  set validationSetup(_validationSetupString: string) {
    try {
      this.validationSetupCalled = true;
      console.log('Input: ' + _validationSetupString);
      this._validationSetup.configCode = JSON.parse(
        _validationSetupString
      ).configCode;
      if (!_validationSetupString) {
        throw new Error('_validationSetupString not set');
      }
      this._validationSetup = JSON.parse(_validationSetupString);
    } catch (err) {
      console.warn(err);
      console.warn('Cannot read validation Setup Input');
    }
  }

  public _validationSetup: ValidationSetup = {
    configCode: 'ABC123456'
  };
  public language = 'en';
  public changeConfig = false;
  public valid = false;
  public loading = false;

  constructor(
    private camosService: CamosService,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService,
    public camosConfiguratorComponent: CamosConfiguratorComponent
  ) {
    this.camosService.getStage().then((resp) => {
      environment.stage = resp.stage;
    });
  }

  async ngOnInit() {
    setTimeout(() => {
      this.ngOnInit();
    }, 600000); // Activate after 10 minutes.
    // }, 60000); // Activate after 1 minutes.
    let htmls = document.getElementsByTagName('html');
    for (let i = 0; i < htmls.length; i++) {
      if (htmls[i] && htmls[i].getAttribute('xml:lang')) {
        this.language = htmls[i].getAttribute('xml:lang');
      }
      this.camosService.language = this.language;
    }
    if (i18next.languages.includes(this.language.toLowerCase())) {
      await i18next.changeLanguage(this.language.toLowerCase());
    }

    const configCode = this.getParam('configCode');
    if (configCode) {
      this._validationSetup.configCode = configCode;
    }
    var check = await this.camosService.checkValidationSetup(
      this._validationSetup
    );
    let cookieExists = false;
    const allCookies: {} = this.cookieService.getAll();
    for (const key in allCookies) {
      if (key.includes(this._validationSetup.configCode)) {
        cookieExists = true;
      }
    }
    this.loading = true;
    this.spinner.show('validationSpinner');
    if (check) {
      if (!cookieExists) {
        await this.startSession();
        await this.stopSession();
      }
      await this.checkValidation();
    }
    // this.spinner.hide("validationSpinner");
    this.loading = false;
  }

  public async startSession() {
    try {
      if (
        this._validationSetup.configCode &&
        this._validationSetup.configCode.length > 0 &&
        this._validationSetup.configCode != 'null'
      ) {
        await this.camosService.startValidationSession(
          this._validationSetup.configCode
        );
        const getCamosInfo = await this.camosService.getCamosInfo();
        console.log(
          '%cCamos Config Info',
          'color: #007acc; font-weight:bold',
          getCamosInfo
        );
        await this.validate(getCamosInfo);
      }
    } catch (error) {
      console.log(
        '%cSession start error, camos API error',
        'color: #f01e2c; font-weight:bold',
        error
      );
    }
  }

  private timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async validate(camosInfo) {
    // ToDo
    await this.timeout(60000);
    var date = new Date();
    var expiresDate = new Date(date.setDate(date.getDate() + 1));
    // var expiresDate = new Date(date.setMinutes(date.getMinutes() + 5));
    this.cookieService.set(
      this._validationSetup.configCode,
      'true',
      expiresDate
    );
  }

  public checkValidation() {
    let cookieValue = this.cookieService.get(this._validationSetup.configCode);
    this.valid = cookieValue === 'true';
  }

  public stopSession() {
    if (this.camosService.session && this.camosService.session.length > 0) {
      firstValueFrom(
        this.camosService.stopSession(this.camosService.session)
      ).then(() => {
        this.camosService.session = '';
      });
    }
  }

  private getParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
      window.location.href
    );
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  public async changeConfiguration() {
    if (!this.loading) {
      this.changeConfig = true;
      await this.timeout(100);
      document.getElementsByClassName('tooltiptext').item(0).innerHTML =
        "<tap-webcomponent configurator-setup='{&quot;country&quot;:&quot;us&quot;,&quot;configId&quot;:&quot;" +
        this._validationSetup.configCode +
        "&quot;,&quot;partnerId&quot;:&quot;dmp&quot;,&quot;product&quot;:&quot;&quot;,&quot;uis&quot;:[&quot;PROMO&quot;]}'></tap-webcomponent>";
    }
  }
}
