<div class="service-selection-wrapper">
  <div class="service-selection-left">
    <div class="service-selection-radio-wrapper">
      <span>Contract duration1</span>
      <app-validtype-tooltip
        [validExplanation]="'test'"
        [validType]="'Must'"
        [tooltipPosition]="'left'"
      ></app-validtype-tooltip>
    </div>
  </div>
  <div class="service-selection-right">
    <input
      type="date"
      [name]="'datepicker-' + uuidNum"
      placeholder="Please choose a date"
      (change)="datePickerChange($event)"
      [ngClass]="datePick && 'active'"
    />
  </div>
</div>
