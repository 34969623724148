import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sales-text',
  templateUrl: './sales-text.component.html',
  styleUrls: ['./sales-text.component.scss']
})
export class SalesTextComponent implements OnInit {
  @ViewChild('salesTextContainer') salesTextContainer: ElementRef<HTMLElement>;
  @Input('salesText') salesText: string;
  @Input('showText') showText: string;
  @Input('hideText') hideText: string;
  classList: string[];
  salesTitle: string;

  constructor() {}

  ngOnInit(): void {
    // console.log(this.showText, this.hideText);
    this.salesTitle = this.showText;
    this.classList = ['button-tertiary', 'downButton'];
  }

  toggleBundleDescription(): void {
    this.salesTitle == this.showText
      ? (this.salesTitle = this.hideText)
      : (this.salesTitle = this.showText);
    this.classList.length == 3
      ? this.classList.pop()
      : this.classList.push('active');
    this.salesTextContainer.nativeElement.clientHeight !== 0
      ? (this.salesTextContainer.nativeElement.style.height = '0px')
      : (this.salesTextContainer.nativeElement.style.height = `${this.salesTextContainer.nativeElement.scrollHeight}px`);
  }
}
