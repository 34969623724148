import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UIState } from '../store/ui/ui.state';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() leadMode: string;

  @Output() partnerPageEventEmitter = new EventEmitter();

  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;
  currentNaviNode: number = 0;

  mode = false;
  disable = true;

  constructor(
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public appComponent: AppComponent
  ) {}

  ngOnInit(): void {
    this.currentNaviNode$.subscribe((num) => {
      if (num != undefined) {
        this.disable = false;
      }
      this.currentNaviNode = num;
    });
    if (this.leadMode === 'true') {
      this.mode = true;
    }
  }

  goToPartnerPage() {
    this.partnerPageEventEmitter.emit();
    this.camosConfiguratorComponent.goToTop();
  }
}
