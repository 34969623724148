<div *ngIf="loading" class="spinner"></div>
<div *ngIf="!camosConfiguratorComponent.requestQuote">
  <div>
    <label class="label-header" style="text-align: center">
      <h3>{{ 'review.header.headline' | i18next }}</h3>
    </label>
  </div>
  <div>
    <div *ngIf="structure">
      <label class="label-header2">
        <h4>{{ structure.name }}</h4>
      </label>
      <app-review-preconfigured-table
        [items]="structure"
        [type]="'review'"
      ></app-review-preconfigured-table>
    </div>

    <br />
    <app-sticky-element></app-sticky-element>
  </div>
</div>

<div *ngIf="camosConfiguratorComponent.requestQuote">
  <app-request-quote-selected
    [country]="appComponent._configuratorSetup.country"
    [ReviewList]="structure"
  ></app-request-quote-selected>
</div>
