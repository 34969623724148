const partnerIDs = new Map<string, string>([
  // [prod id, qual id]
  /*
    ['2288840', '2254307'], // [xxx] Distrelec GmbH
    ['2288839', '2254306'], // [xxx] Distrelec Italia S.r.l. Unit of Distrelec Group AG
    ['2288836', '2254303'], // [xxx] ELFA Distrelec SIA
    ['2288830', '2254309'], // [xxx] Elfa Distrelec Sp. z o.o.
    ['2264441', '2252506'], // ae elsinger electronic handel gmbh
    ['2264443', '2252490'], // AFC INGENIEROS S.A.
    ['2264444', '2252492'], // ALLICE Messtechnik GmbH
    ['2264446', '2252472'], // Ampercom S.r.l.
    ['2264448', '2252491'], // ARC Brasov SRL
    ['2264449', '2252122'], // Batronix GmbH & Co. KG
    ['2264480', '2252493'], // Batter Fly S.r.l.
    ['2264484', '2252494'], // Belmet MI d.o.o.
    ['2264486', '2252495'], // BluEmi s.r.o.
    ['2264487', '2252471'], // Boreo Oyj/Yleiselektroniikka c/o Barona
    ['2264500', '2252496'], // Conrad Electronic SE
    ['2264502', '2252416'], // Continental Resources Inc
    ['2264503', '2252502'], // dataTec AG
    ['2264506', '2252473'], // deg Messtechnik GmbH
    ['2264507', '2252503'], // DISTRAME SA
    ['2288838', '2254305'], // Distrelec B.V.
    ['2288837', '2254304'], // Distrelec Deutschland GmbH
    ['2288835', '2254302'], // Distrelec Schweiz AG
    ['2264508', '2252504'], // DISTRON S.L.
    ['2288841', '2254308'], // Elfa Distrelec AB
    ['2264520', '2252505'], // Elpav Instruments S.n.c.
    ['2264526', '2252507'], // Imex Systems Limited
    ['2264527', '2252509'], // Inkotel System LLC
    ['2264528', '2252510'], // Instrumentcenter Sweden AB
    ['2264529', '2252508'], // L'IMPULSION SAS
    ['2264552', '2252512'], // MB Electronique SAS
    ['2264553', '2252513'], // MEDI-Complet s.r.o.
    ['2264554', '2252514'], // Mikro Princ d.o.o.
    ['2264555', '2252515'], // NDN Z. Daniluk
    ['2264556', '2252516'], // Netfon Services Ltd
    ['2288799', '2252415'], // Premier Farnell UK Limited
    ['2264557', '2252517'], // Radio Systems Global Kft.
    ['2264558', '2252518'], // Reichelt-Elektronik GmbH & Co. KG
    ['2264559', '2252511'], // RPC "Kharkov - Pribor" Ltd.
    ['2288832', '2254158'], // RS Amidata
    ['2288833', '2254159'], // RS Components GmbH
    ['2288831', '2254157'], // RS Components Italy
    ['2288834', '2254301'], // RS Components SAS
    ['2288649', '2254300'], // RS Components UK
    ['2264571', '2252521'], // Sencom Systems Ltd.
    ['2264949', '2252764'], // SOS electronic s.r.o.
    ['2264573', '2252764'], // SOS electronic s.r.o.
    ['2264590', '2252527'], // SPF VD Mais Pvt. Co.
    ['2264570', '2252519'], // S-TMM Sistemi d.o.o.
    ['2264577', '2252524'], // TESPOL Sp. z o.o.
    ['2264578', '2252525'], // TESTOON SAS
    ['2264579', '2252526'], // Thurlby Thandar Instruments Ltd
    ['2264575', '2252522'], // TR instruments spol. s r.o.
    ['2264576', '2252523'], // TT&MS B.V.
    ['2352540', '2257199'], // TEquipment
    ['2352545', '2257212'], // TestEquity Rental LLC
    ['2350661', '2257193'], // ConRes Rental
    ['2352337', '2257196'], // Electro Rent
    ['2352338', '2257197'], // Electro Rent Rental
    ['2352339', '2257198'], // Newark
    ['2352542', '2257211'], // Techni-Tool
    ['2352543', '2257195'], // TestEquity LLC
    ['2352546', '2257194'], // Testforce Systems
    ['2352541', '2257210']  // TRS-RenTelco Rental
*/
]);

export { partnerIDs };
