<div *ngIf="!changeConfig" style="display: inline">
  <div *ngIf="loading" style="display: inline-block">
    <div style="height: 13px; width: 16px; display: inline-block">
      <ngx-spinner
        style="position: fixed; height: 16px; width: 16px"
        name="validationSpinner"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#009bea"
        [fullScreen]="false"
        size="small"
      ></ngx-spinner>
    </div>
    <span class="text" style="color: #009bea">Loading</span>
  </div>
  <div *ngIf="!loading" style="display: inline-block">
    <div
      *ngIf="valid"
      class="rsi rsi-check"
      style="font-size: 16px; margin-right: 20px; color: green"
    >
      <span class="text" style="color: green">Valid</span>
    </div>
    <div
      *ngIf="!valid"
      class="rsi rsi-close"
      style="font-size: 16px; margin-right: 20px; color: red"
    >
      <span class="text" style="color: red">Invalid</span>
    </div>
  </div>
  <br />
  <a
    class="button-tertiary"
    (click)="changeConfiguration()"
    style="cursor: pointer"
    [style.opacity]="loading ? 0.5 : 1"
    >Change configuration</a
  >
</div>
<div *ngIf="changeConfig">
  <div class="tooltip" #tooltip>
    <span class="tooltiptext">
      <!-- <tap-webcomponent></tap-webcomponent> -->
    </span>
  </div>
</div>
