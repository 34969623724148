import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';
import { UIState } from '../store/ui/ui.state';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamosService } from '../services/camos.service';
import { AppComponent } from '../app.component';
import i18next from 'i18next';
import { DistributionPartnerState } from '../store/distributionpartner/distributionpartner.state';
import { Distributor } from '../store/distributionpartner/distributionpartner.model';
import { LoadConfig } from '../store/ui/ui.actions';

@Component({
  selector: 'app-camos-configurator',
  templateUrl: './camos-configurator.component.html',
  styleUrls: ['./camos-configurator.component.scss']
})
export class CamosConfiguratorComponent implements OnInit {
  @Input() openCode: string;
  @Input() leadMode: string;
  @Input() configId: string;
  @Input() bundleMode: string;
  @Input() msku: string;
  @Input() malias: string;
  @Input() uis: string[];
  @ViewChild('camosWrapper') camosWrapper: ElementRef;

  get loadCompFunction() {
    return this.loadComponent.bind(this);
  }

  get PartnerFunction() {
    return this.goToPartnerPage.bind(this);
  }
  public manual: boolean = false;
  public pre: boolean = false;
  public code: boolean = false;
  public review: boolean = false;
  public error: boolean = false;
  public requestQuote: boolean = false;
  public leadCreated: boolean = false;
  public notification: boolean = false;
  public testWebsite: boolean = false;
  public partnerPage: boolean = false;
  public promotion: boolean = false;
  public promotionPage: boolean = false;
  public hideProgressBar: boolean = false;
  public isInternational: boolean = false;
  public selectedCountry: string = '';

  public opendistributionPartner: boolean = false;
  public distributionPartner: boolean = false;
  public opensaveConfig: boolean = false;
  public iNeedHelp: boolean = false;
  public woConfiguration: boolean = false;
  public formWithoutPartner: boolean = false;
  public promotionId: string = '';
  public promotionPrice: string = '';
  public opacityValue: number = 0;
  public ifTranstiton: boolean = true;
  public componentWidth: number;
  public selectionError: boolean = false;
  public lastCountryValue: string = undefined;
  public camosWrapperWidth: number;
  public camosWrapperPosLeft: number;

  @Select(DistributionPartnerState.distributors) distributors$: Observable<
    Distributor[]
  >;
  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;
  currentNaviNode: number = 0;
  disable = false;

  private testObject = [];
  public basePromotions: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    public store: Store,
    public camosService: CamosService,
    public appComponent: AppComponent
  ) {}

  changeOpacity(): void {
    this.ifTranstiton = false;
    this.opacityValue = 0;

    setTimeout(() => {
      this.ifTranstiton = true;
      this.opacityValue = 1;
    }, 200);
  }

  ngOnInit(): void {
    this.appComponent.sessionError.subscribe(async (value) => {
      if (value == true) {
        await this.goToPartnerPage();
        this.hideProgressBar = true;
      }
    });
    this.testObject = [
      {
        id: 'manual-configurator',
        title: i18next.t('startPage.manual.header'),
        list: i18next.t('startPage.manual.body'),
        img: 'Configurator-Config.svg'
      },
      // {
      //   "id": "pre-configuration",
      //   "title": i18next.t('startPage.pre.header'),
      //   "list": i18next.t('startPage.pre.body'),
      //   "img": "Configurator-PreConfig.svg"
      // },
      // {
      //   "id": "promotion-page",
      //   "title": i18next.t('startPage.promotions.header'),
      //   "list": i18next.t('startPage.promotions.body'),
      //   "img": "Configurator-Promotion.svg"
      // },
      {
        id: 'partner-page',
        title: i18next.t('startPage.withoutConfig.header'),
        list: i18next.t('startPage.withoutConfig.body'),
        img: 'Configurator-NoConfig.svg'
      }
    ];

    if (!this.openCode && this.openCode.length == 0) {
      if (this.configId && this.configId.length > 0) {
        localStorage.setItem('camosConfigurationID', this.configId);
      }
      this.loadComponent('code-configuration');
    }

    if (this.leadMode === 'true' && this.configId) {
      localStorage.setItem('camosConfigurationID', this.configId);
      this.loadComponent('code-configuration');
    }
    if (
      this.bundleMode === 'true' &&
      this.msku &&
      this.malias &&
      this.appComponent._configuratorSetup.country.toUpperCase() !== 'COM'
    ) {
      this.goToPartnerPage();
    }
    if (this.uis !== undefined && this.uis.includes('PROMO')) {
      this.promotion = true;
    } else {
      this.testObject.forEach((element, index) => {
        if (element.id == 'promotion-page') {
          this.testObject.splice(index, 1);
        }
      });
    }
    if (
      this.appComponent._configuratorSetup.partnerId &&
      this.appComponent._configuratorSetup.partnerId.length > 0
    ) {
      this.testObject.forEach((element, index) => {
        if (element.id == 'partner-page') {
          this.testObject.splice(index, 1);
        }
      });
    }
    if (
      this.appComponent._configuratorSetup.partnerId &&
      this.appComponent._configuratorSetup.partnerId.length > 0 &&
      this.testObject.length == 1 &&
      (!this.appComponent._configuratorSetup.configId ||
        this.appComponent._configuratorSetup.configId.length == 0)
    ) {
      this.loadComponent('manual-configurator');
    }
    if (
      this.appComponent._configuratorSetup.country !== undefined &&
      this.appComponent._configuratorSetup.country.toUpperCase() === 'COM'
    ) {
      this.isInternational = true;
      this.partnerPage = false;
    }
  }

  ngAfterViewInit() {
    this.camosWrapperWidth = this.camosWrapper.nativeElement.clientWidth;
    this.camosWrapperPosLeft =
      this.camosWrapper.nativeElement.getBoundingClientRect().left;

    let maxHeight = 0;
    let headlines = document.getElementsByClassName('headline');
    setTimeout(() => {
      this.opacityValue = 1;
    }, 500);
    for (let i = 0; i < headlines.length; i++) {
      if (headlines.item(i).clientHeight > maxHeight) {
        maxHeight = headlines.item(i).clientHeight;
      }
    }
    for (let i = 0; i < headlines.length; i++) {
      headlines.item(i).setAttribute('style', 'height: ' + maxHeight + 'px');
    }

    const camosWrapper = document.querySelector(
      '.camos-wrapper'
    ) as HTMLElement;
    if (camosWrapper) {
      this.componentWidth = camosWrapper.offsetWidth;
    }
    window.addEventListener('resize', () => {
      if (!camosWrapper) return;

      this.componentWidth = camosWrapper.offsetWidth;
    });
    this.goToTop();
  }

  dataIsLoaded() {
    this.spinner.show('spinnerManual');
    this.currentNaviNode$.subscribe((num) => {
      if (num != undefined) {
        // this.disable = false;
        this.spinner.hide('spinnerManual');
      }
      this.currentNaviNode = num;
    });
  }

  public async loadComponent(componentName: string) {
    this.basePromotions = false;
    await this.changeOpacity();
    switch (componentName) {
      case 'manual-configurator':
        this.manual = true;
        this.pre = false;
        this.code = false;
        this.review = false;
        this.error = false;
        this.hideProgressBar = false;
        this.promotionPage = false;
        this.partnerPage = false;
        this.requestQuote = false;
        this.promotionId = '';
        this.promotionPrice = '';
        this.dataIsLoaded();
        break;
      case 'pre-configuration':
        this.manual = false;
        this.pre = true;
        this.code = false;
        this.review = false;
        this.error = false;
        this.hideProgressBar = false;
        this.promotionPage = false;
        // this.dataIsLoaded();
        break;
      case 'code-configuration':
        this.manual = false;
        this.pre = false;
        this.code = true;
        this.review = false;
        this.error = false;
        this.hideProgressBar = false;
        this.promotionPage = false;
        break;
      case 'review':
        this.manual = false;
        this.pre = false;
        this.code = false;
        this.review = true;
        this.error = false;
        this.hideProgressBar = false;
        this.promotionPage = false;
        this.requestQuote = false;
        this.partnerPage = false;
        break;
      case 'error':
        this.manual = false;
        this.pre = false;
        this.code = false;
        this.review = false;
        this.error = true;
        this.hideProgressBar = false;
        this.promotionPage = false;
        break;
      case 'promotion-page':
        this.manual = false;
        this.pre = false;
        this.code = false;
        this.review = false;
        this.error = false;
        this.promotionPage = true;
        this.hideProgressBar = true;
        break;
      default:
        break;
    }
    this.dataIsLoaded();
    this.goToTop();
  }

  goToConfigType() {
    this.changeOpacity();
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.error = false;
    this.hideProgressBar = false;
    this.promotionPage = false;
    this.goToTop();
  }

  async goBackToConfigType() {
    await this.changeOpacity();
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = false;
    this.requestQuote = false;
    this.hideProgressBar = false;
    this.promotionPage = false;

    this.distributionPartner = false;
    this.opensaveConfig = false;
    this.opendistributionPartner = false;
    this.iNeedHelp = false;
    this.woConfiguration = false;
    this.goToTop();
  }

  public goToRequestQuote() {
    this.requestQuote = true;
    this.goToTop();
  }

  public goBackRequestQuote() {
    this.requestQuote = false;
    this.goToTop();
  }

  public leadCreation(bool: boolean) {
    this.leadCreated = bool;
  }

  public openNotification(bool: boolean) {
    this.notification = bool;
  }

  // public openTestWebsite() {
  //   this.testWebsite = true;
  // }

  // public closeTestWebsite() {
  //   this.testWebsite = false;
  // }

  async goToPartnerPage(iNeedHelp = false, woConfiguration = false) {
    await this.changeOpacity();
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = true;
    this.requestQuote = true;
    this.hideProgressBar = false;
    this.promotionPage = false;

    this.distributionPartner = true;
    this.opensaveConfig = false;
    this.opendistributionPartner = false;
    this.iNeedHelp = iNeedHelp;
    this.woConfiguration = woConfiguration;
    this.goToTop();
  }

  promotionGoToPartnerPage(promotionId: string, promotionPrice: string) {
    if (this.manual) {
      this.basePromotions = true;
    }
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = true;
    this.requestQuote = true;
    this.promotionPage = false;

    this.distributionPartner = true;
    this.opensaveConfig = false;
    this.opendistributionPartner = false;
    this.iNeedHelp = false;
    this.woConfiguration = false;
    this.promotionId = promotionId;
    this.promotionPrice = promotionPrice;
    this.goToTop();
  }

  promotionGoToPartnerPageWithoutPartner(
    promotionId: string,
    promotionPrice: string
  ) {
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = true;
    this.requestQuote = true;
    this.promotionPage = false;

    this.distributionPartner = false;
    this.opensaveConfig = false;
    this.opendistributionPartner = true;
    this.iNeedHelp = false;
    this.woConfiguration = false;
    this.promotionId = promotionId;
    this.promotionPrice = promotionPrice;
    this.goToTop();
  }

  openConfigurationCode() {
    this.manual = false;
    this.pre = false;
    this.code = true;
    this.review = false;
    this.partnerPage = false;
    this.error = false;
    this.hideProgressBar = false;
    this.promotionPage = false;
    this.goToTop();
  }

  showErrorPage() {
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = false;
    this.requestQuote = false;
    this.error = true;
    this.hideProgressBar = false;
    this.promotionPage = false;
    this.goToTop();
  }

  showMainPage() {
    this.manual = false;
    this.pre = false;
    this.code = false;
    this.review = false;
    this.partnerPage = false;
    this.requestQuote = false;
    this.error = false;
    this.hideProgressBar = false;
    this.promotionPage = false;
    this.distributionPartner = false;
    this.goToTop();
  }

  async setSelectedCountry(
    countryValue,
    loadConfiguration?: boolean,
    loadPreviouslyCountry?: boolean
  ) {
    this.spinner.show('spinnerInternational');
    await this.showMainPage();
    this.spinner.show('countrySpinner');
    let countryAllowed = await this.camosService.checkCountry(
      this.appComponent.getProduct(),
      countryValue
    );
    let objCopy = { ...this.appComponent._configuratorSetup };
    if (loadConfiguration) this.lastCountryValue = objCopy.country;
    objCopy.country = countryValue;
    this.appComponent._configuratorSetup = objCopy;
    const review = this.store.selectSnapshot((state) => state.review);
    if (countryAllowed) {
      if (JSON.stringify(review) !== '{}' && loadConfiguration) {
        const configCode = localStorage.getItem('camosConfigurationID');
        await this.appComponent.startSession();
        const resp = await this.camosService.loadConfiguration(configCode);
        console.log(resp);
        if (resp['load']) {
          console.log(configCode, countryValue, this.appComponent.language);
          await firstValueFrom(
            this.store.dispatch(
              new LoadConfig(
                configCode,
                countryValue,
                this.appComponent.language
              )
            )
          );
          setTimeout(() => {
            this.goToPartnerPage();
            this.spinner.hide('countrySpinner');
          });
        }
      } else {
        await this.appComponent.startSession();
        if (loadConfiguration) {
          this.goBackToConfigType();
          setTimeout(() => {
            if (loadPreviouslyCountry) {
              this.goToPartnerPage(false, true);
            } else {
              this.goToPartnerPage();
            }
          });
        }
        this.spinner.hide('countrySpinner');
      }
    } else {
      //* TODO
      await this.openWithoutPartnerForm();
      this.spinner.hide('countrySpinner');
    }
    this.isInternational = false;
    this.selectedCountry = countryValue;
    if (
      countryAllowed &&
      this.bundleMode === 'true' &&
      this.msku &&
      this.malias &&
      this.appComponent._configuratorSetup.country.toUpperCase() !== 'COM'
    ) {
      this.goToPartnerPage();
    }
    // if(loadConfiguration && this.lastCountryValue != undefined) this.lastCountryValue = undefined;
    this.spinner.hide('spinnerInternational');
  }

  async openWithoutPartnerForm() {
    this.spinner.show('spinnerInternational');
    await this.appComponent.startInternationalSession();
    this.isInternational = false;
    this.formWithoutPartner = true;
    this.spinner.hide('spinnerInternational');
  }

  goToTop() {
    setTimeout(() => {
      try {
        let top =
          window.scrollY +
          document
            .getElementsByTagName('tap-webcomponent')[0]
            .getBoundingClientRect().top;
        if (top >= 10) {
          top = top - 10;
        }
        if (top) {
          // setTimeout(() => window.scrollTo(0, top), 100);
          window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth'
          });
        }
      } catch (error) {}
    }, 300);
  }

  public numberWithCommas(x) {
    if (x) {
      if (!x.includes('price on request')) {
        let number = Number.parseFloat(x.split(' ')[0].replace(',', '.'));
        let currency = x == 0 ? '' : x.split(' ')[1];
        let n = `${Math.round(number)} ${currency}`;
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    }
    return 'price on request';
  }
}
