<!--<div *ngIf="!testWebsite">-->
<div *ngIf="isInternational">
  <ngx-spinner
    name="spinnerInternational"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, 0)"
    color="#009bea"
    [fullScreen]="false"
  ></ngx-spinner>
  <!-- <app-country-selector></app-country-selector> -->
  <app-country-selector-menu [comSelector]="true"></app-country-selector-menu>
</div>

<ngx-spinner
  name="countrySpinner"
  type="ball-clip-rotate"
  bdColor="rgba(255, 255, 255, 0.75)"
  color="#009bea"
  [fullScreen]="false"
  class="countrySpinner"
></ngx-spinner>

<div *ngIf="!isInternational" style="margin: 10px">
  <app-stepper
    *ngIf="bundleMode !== 'true' && !promotionPage && !hideProgressBar"
    (partnerPageEventEmitter)="goToPartnerPage(true)"
    [leadMode]="leadMode"
  ></app-stepper>
  <div *ngIf="bundleMode == 'true'" style="height: 15px"></div>
  <app-heading
    style="text-align: center"
    *ngIf="
      !code &&
      !review &&
      !partnerPage &&
      !error &&
      !promotionPage &&
      !formWithoutPartner
    "
  ></app-heading>
</div>

<div
  class="camos-wrapper"
  [style.transition]="ifTranstiton ? 'all 300ms' : 'all 0ms'"
  [style.opacity]="opacityValue"
  #camosWrapper
>
  <div
    *ngIf="
      !manual &&
      !pre &&
      !code &&
      !review &&
      !partnerPage &&
      !error &&
      !isInternational &&
      !promotionPage &&
      !formWithoutPartner
    "
    class="container"
    style="text-align: center"
  >
    <app-slider
      style="width: 100%"
      [type]="'homepage'"
      [sliderContent]="testObject"
      [PartnerFunction]="PartnerFunction"
      [loadFunction]="loadCompFunction"
    ></app-slider>
  </div>

  <div
    *ngIf="
      !manual &&
      !pre &&
      !code &&
      !review &&
      !partnerPage &&
      !error &&
      !isInternational &&
      !promotionPage &&
      !formWithoutPartner
    "
    class="module-card-selector"
    style="width: 100%"
  >
    <div class="divider-or">
      <span></span><span>{{ 'requestQuote.or' | i18next }}</span>
    </div>
    <div class="button-wrapper">
      <a
        class="button tap-configuration-button"
        (click)="loadComponent('code-configuration')"
        style="display: flex"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="#009BEA"
            class="bi bi-gear"
            viewBox="0 0 16 16"
            style="margin-top: 2px"
          >
            <path
              d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
            />
            <path
              d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
            />
          </svg>
        </span>
        <p style="margin: 0 0 0 10px; font-size: 18px">
          {{ 'startPage.configCode' | i18next }}
        </p>
      </a>
    </div>
  </div>

  <div *ngIf="manual">
    <ngx-spinner
      name="spinnerManual"
      type="ball-clip-rotate"
      bdColor="rgba(255, 255, 255, 0.75)"
      color="#009bea"
      [fullScreen]="false"
    ></ngx-spinner>
    <app-cui-navi-nodes></app-cui-navi-nodes>
    <app-camos-widgets></app-camos-widgets>
    <app-sticky-element></app-sticky-element>
    <!-- <app-cui-next-prev-nav></app-cui-next-prev-nav> -->
  </div>

  <div *ngIf="code" style="text-align: center">
    <app-configuration-code [startSession]="openCode"></app-configuration-code>
  </div>

  <div *ngIf="review">
    <app-review></app-review>
  </div>

  <div *ngIf="partnerPage">
    <app-request-quote-selected
      [distributionPartner]="distributionPartner"
      [opensaveConfig]="opensaveConfig"
      [opendistributionPartner]="opendistributionPartner"
      [iNeedHelp]="iNeedHelp"
      [woConfiguration]="woConfiguration"
      [country]="appComponent._configuratorSetup.country"
    ></app-request-quote-selected>
  </div>

  <div *ngIf="formWithoutPartner">
    <app-request-quote-selected
      [distributionPartner]="false"
      [opensaveConfig]="false"
      [opendistributionPartner]="true"
      [iNeedHelp]="false"
      [woConfiguration]="false"
      [country]="appComponent._configuratorSetup.country"
    ></app-request-quote-selected>
  </div>

  <div *ngIf="promotionPage">
    <div class="headline-container">
      <!-- <h3>{{ 'startPage.promotions.headline' | i18next }}</h3> -->
    </div>
    <!--<p>Promotion valid until August 31, 2021</p>-->
    <app-camos-widgets [promotion]="true"></app-camos-widgets>
    <a
      class="button-tertiary"
      (click)="goToConfigType()"
      style="cursor: pointer; margin: 20px"
      >{{ 'general.backToConfiguration' | i18next }}</a
    >
  </div>

  <div *ngIf="error">
    <app-errors-view></app-errors-view>
  </div>
  <div *ngIf="pre">
    <ngx-spinner
      name="spinnerManual"
      type="ball-clip-rotate"
      bdColor="rgba(0, 0, 0, 0)"
      color="#009bea"
      [fullScreen]="false"
    ></ngx-spinner>

    <div class="wrapper" style="margin: 0px 50px">
      <app-preconfigured-bundles></app-preconfigured-bundles>
      <a
        class="button-tertiary"
        (click)="goToConfigType()"
        style="cursor: pointer"
        >{{ 'general.backToConfiguration' | i18next }}</a
      >
    </div>
  </div>

  <div *ngIf="notification">
    <app-conflict-notification
      [add]="true"
      [remove]="false"
    ></app-conflict-notification>
  </div>
  <div *ngIf="selectionError">
    <app-error-notification></app-error-notification>
  </div>
</div>
