import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { PriceService } from 'src/app/services/price.service';
// import { SalesTextService } from 'src/app/services/sales-text.service';
import { SelectItemState } from 'src/app/store/ui/ui.actions';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from 'src/app/app.component';
import { CamosService } from 'src/app/services/camos.service';

@Component({
  selector: 'app-cui-comp-multi-table',
  templateUrl: './cui-comp-multi-table.component.html',
  styleUrls: ['./cui-comp-multi-table.component.scss']
})
export class CuiCompMultiTableComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  timer: any;
  salesText;

  constructor(
    private store: Store,
    // private salesTextSvc: SalesTextService,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public priceService: PriceService,
    private spinner: NgxSpinnerService,
    private appComponent: AppComponent,
    private camosService: CamosService
  ) {}

  ngOnInit() {
    this.widget.values.forEach((vals) => {
      if (vals.validity.type === 'Must') {
        this.selectItem(vals.name);
      }
    });
    // await this.salesTextSvc.preloadSalesTexts(this.widget.values.map(value => value.name));
  }

  async selectItem(name: string) {
    const counterElement: HTMLElement = document.getElementById(`plus_${name}`);
    if (!counterElement) return;

    counterElement.parentElement.style.opacity = '0';
    this.spinner.show('sticky-spinner');
    this.spinner.show(`spinner-${name}`);
    document.getElementById('priceBox').style.color = 'lightgray';

    const nextState = await this.camosService.getItemForWidget(
      name,
      this.widget.id,
      1,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(new SelectItemState(nextState, name, this.widget.id));

    this.spinner.hide(`spinner-${name}`);
    if (this.appComponent.isPrice) {
      const price = await this.priceService.setPriceTotal();
      this.priceService.updatePriceTotal(price);
    }
    counterElement.parentElement.style.opacity = '1';
    document.getElementById('priceBox').style.color = '#009bea';
    this.spinner.hide('sticky-spinner');
    this.spinner.hide(`spinner-${name}`);
  }

  addQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    (document.getElementById(id) as any).value++;
    // clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  changeQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  subQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    if ((document.getElementById(id) as any).value !== '0') {
      (document.getElementById(id) as any).value--;
    }
    clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  async safeQuantity(
    name: string,
    id: number,
    quantity: number,
    previousQuantity: number
  ) {
    const counterElement: HTMLElement = document.getElementById(
      `select_${name}`
    );
    if (!counterElement) return;

    counterElement.parentElement.style.opacity = '0';
    document.getElementById('priceBox').style.color = 'lightgray';
    this.spinner.show('sticky-spinner');
    this.spinner.show(`spinner-${name}`);

    const nextState = await this.camosService.getItemForWidget(
      name,
      id,
      quantity,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(
      new SelectItemState(nextState, name, id, quantity, previousQuantity)
    );

    const price = await this.priceService.setPriceTotal();
    this.priceService.updatePriceTotal(price);

    document.getElementById('priceBox').style.color = '#009bea';
    counterElement.parentElement.style.opacity = '1';
    this.spinner.hide(`spinner-${name}`);
    this.spinner.hide('sticky-spinner');
  }

  calculateTableGroupRowSize(materialId: string) {
    var tableGroupRow = document.getElementById(
      'table-group-row-' + materialId
    );
    if (tableGroupRow) {
      var tableGroupRowHeight = tableGroupRow.offsetHeight;
      var tableGroupRowWidth = tableGroupRow.offsetWidth;
      var salesTextElement = document.getElementById(
        'tooltiptext_' + materialId
      );
      if (salesTextElement != undefined) {
        salesTextElement.style.setProperty(
          '--table-group-row-height',
          tableGroupRowHeight + 'px'
        );
        /*
        salesTextElement.style.setProperty(
          "--table-group-row-width",
          tableGroupRowWidth + "px"
        );
        */
      }
    }
  }
}
