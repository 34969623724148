<div style="margin-bottom: 70px">
  <ngx-spinner
    name="spinner"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, 0)"
    color="#009bea"
    [fullScreen]="false"
  ></ngx-spinner>

  <div *ngIf="!partnerPage && !formWithoutPartner && !error">
    <app-camos-widgets [promotion]="true"></app-camos-widgets>
  </div>

  <div *ngIf="partnerPage">
    <app-request-quote-selected
      [country]="promotionsComponent._promotionSetup.country"
      [promo]="true"
      [distributionPartner]="distributionPartner"
      [opensaveConfig]="opensaveConfig"
      [opendistributionPartner]="opendistributionPartner"
      [iNeedHelp]="false"
      [woConfiguration]="false"
    ></app-request-quote-selected>
  </div>

  <div *ngIf="formWithoutPartner">
    <app-request-quote-selected
      [country]="appComponent._configuratorSetup.country"
      [promo]="true"
      [distributionPartner]="false"
      [opensaveConfig]="false"
      [opendistributionPartner]="true"
      [iNeedHelp]="false"
      [woConfiguration]="false"
    ></app-request-quote-selected>
  </div>

  <div *ngIf="error">
    <app-errors-view></app-errors-view>
  </div>
</div>
