import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Input,
  ViewChild
} from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { NaviNode } from 'src/app/store/ui/ui.model';
import { UIState } from 'src/app/store/ui/ui.state';

@Component({
  selector: 'app-cui-navi-nodes',
  templateUrl: './cui-navi-nodes.component.html',
  styleUrls: ['./cui-navi-nodes.component.scss']
})
export class CuiNaviNodesComponent implements OnInit, AfterViewInit {
  @Select(UIState.navinodes) navinodes$: Observable<NaviNode[]>;

  @ViewChild('categories') categoriesContainer: ElementRef<HTMLDivElement>;
  naviNodes: NaviNode[];
  public transformValue: number = 0;
  public maxTransformValue: number;
  public naviNodesCount: number;
  public activeNaviNodeIndex: number;
  public isSlider: boolean;
  public displayedNaviNodes: number = 0;
  public displayedWidth: number = 0;
  public transformRest: number;
  public additionalTransform: number;

  @Input() nodes;

  //* Slide
  private startPosition: number;
  private endPosition: number;

  constructor(public camosConfigurator: CamosConfiguratorComponent) {}

  ngOnInit(): void {
    this.navinodes$.subscribe((_naviNodes) => (this.naviNodes = _naviNodes));
    this.naviNodesCount = 0;
    if (this.naviNodes) {
      this.naviNodesCount = this.naviNodes.length;
    }
    if (this.nodes) {
      this.naviNodes = this.nodes;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.activeNaviNodeIndex = 0;
      this.maxTranformValueCalculate();
      window.addEventListener(
        'resize',
        this.maxTranformValueCalculate.bind(this)
      );
    });
  }

  public setActiveOnStart() {
    if (!this.isSlider) return;
    let counterValue: number = 0;
    setTimeout(() => {
      Array.from(this.categoriesContainer.nativeElement.children).forEach(
        (singleElement, index) => {
          if (
            singleElement.firstElementChild.firstElementChild.classList.contains(
              'active'
            )
          ) {
            this.transformValue = -(counterValue - 50);
            this.maxTransformValue > this.transformValue
              ? (this.transformValue = this.maxTransformValue)
              : this.transformValue;
            0 < this.transformValue
              ? (this.transformValue = 0)
              : this.transformValue;
          } else {
            counterValue += (singleElement as HTMLElement).offsetWidth;
          }
        }
      );
    }, 1000);
  }

  private testFunction(event: any) {
    this.startPosition = event.clientX;
  }

  private testFunction1(event: any) {
    this.endPosition = event.clientX;
    // console.log(this.startPosition - this.endPosition);
    this.transformValue += this.endPosition - this.startPosition;
    // alert(`${this.transformValue} "vs" ${this.maxTransformValue}`);
    this.transformValue > 0
      ? (this.transformValue = 0)
      : (this.transformValue = this.transformValue);
    this.transformValue < this.maxTransformValue
      ? (this.transformValue = this.maxTransformValue)
      : this.transformValue;
  }

  public maxTranformValueCalculate() {
    this.displayedWidth = 0;
    Array.from(this.categoriesContainer.nativeElement.children).forEach(
      (singleElement) => {
        this.displayedWidth += (singleElement as HTMLElement).offsetWidth;
      }
    );

    this.camosConfigurator.componentWidth < this.displayedWidth
      ? (this.isSlider = true)
      : (this.isSlider = false);
    this.transformValue = 0;
    this.maxTransformValue =
      this.camosConfigurator.componentWidth - this.displayedWidth;
    this.transformRest =
      this.camosConfigurator.componentWidth - this.displayedWidth;
  }

  public changeSlide(direction: string) {
    let transformValue = (
      this.categoriesContainer.nativeElement.children[
        this.activeNaviNodeIndex
      ] as HTMLElement
    ).offsetWidth;
    if (this.transformRest + transformValue > -160 && direction == 'next') {
      this.additionalTransform = -(transformValue + this.transformRest);
      this.transformValue = +this.maxTransformValue;
      transformValue = 0;
    } else {
      this.transformRest += transformValue;
    }

    if (direction == 'prev' && this.additionalTransform > 0) {
      this.transformValue += this.additionalTransform;
      this.additionalTransform = 0;
      this.transformRest =
        this.camosConfigurator.componentWidth - this.displayedWidth;
    }

    this.transformValue =
      direction == 'next'
        ? this.transformValue - transformValue
        : this.transformValue + transformValue;
    direction == 'next'
      ? this.activeNaviNodeIndex++
      : this.activeNaviNodeIndex--;
    this.activeNaviNodeIndex < 0
      ? (this.activeNaviNodeIndex = 0)
      : this.activeNaviNodeIndex;
    this.transformValue > 0 ? (this.transformValue = 0) : this.transformValue;
  }
}
