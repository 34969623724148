export class DistributorInfos {
  distributors: Distributor[];
  selectedDistributor: Distributor;
}

export class Distributor {
  assigned: {
    country: {
      _cdata: string;
    };
  };
  city: {
    _cdata: string;
  };
  companyname: {
    _cdata: string;
  };
  country: {
    _cdata: string;
  };
  deeplinks: {
    link: {
      _cdata: string;
    };
  };
  email: {
    _cdata: string;
  };
  fax: {
    _cdata: string;
  };
  identifier: {
    _cdata: string;
  };
  iframekey: {
    _cdata: string;
  };
  logo: {
    _cdata: string;
  };
  phone: {
    _cdata: string;
  };
  street: {
    _cdata: string;
  };
  streetno: {};
  web: {
    _cdata: string;
  };
  zip: {
    _cdata: string;
  };
  debitor_id: {
    _cdata: string;
  };
  c4c_account_id: {
    _cdata: string;
  };
  partner_id: {
    _cdata: string;
  };
}
