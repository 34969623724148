import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-input-single',
  templateUrl: './checkbox-input-single.component.html',
  styleUrls: ['./checkbox-input-single.component.scss']
})
export class CheckboxInputSingleComponent implements OnInit {
  @Input() value: number;
  @Input() enabled: number | null;
  @Input() inputType: 'select' | 'datepicker';
  @Input() isChecked: boolean;
  @Input() uuidNum: number;
  @Input() nameInput: number;
  @Output() emitEnabled = new EventEmitter<number>();

  datePick: boolean = false;
  datePickerChange(event) {
    this.datePick = event.target.value ? true : false;
  }
  setEnabled(value: number) {
    this.emitEnabled.emit(value);
  }

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.isChecked) this.setEnabled(this.value);
    });
  }
}
