import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdobeAnalyticsManager } from 'src/app/adobe/adobe-analytics-manager';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { ReviewComponent } from 'src/app/review/review.component';
import { SelectNavinode } from 'src/app/store/ui/ui.actions';
import { NaviNode, Widget } from 'src/app/store/ui/ui.model';
import { UIState } from 'src/app/store/ui/ui.state';
import { CamosService } from '../../services/camos.service';
import { PriceService } from 'src/app/services/price.service';
import { AppComponent } from 'src/app/app.component';
import { RequestQuoteSelectedComponent } from 'src/app/request-quote-selected/request-quote-selected.component';
import { CamosPromotionsComponent } from 'src/app/promotion/camos-promotions/camos-promotions.component';

@Component({
  selector: 'app-sticky-element',
  templateUrl: './sticky-element.component.html',
  styleUrls: ['./sticky-element.component.scss']
})
export class StickyElementComponent implements OnInit, AfterViewInit {
  @Component({
    providers: [PriceService]
  })
  @Select(UIState.nextNaviNode)
  nextNavNode$: Observable<NaviNode>;
  nextNavNode: NaviNode;

  @Select(UIState.prevNaviNode) prevNavNode$: Observable<NaviNode>;
  prevNavNode: NaviNode;

  @Select(UIState.widgets) widgets$: Observable<Widget[]>;
  widgets: Widget[];

  @Select(UIState.currentNaviNodeIndex) currentNaviNode$: Observable<number>;

  review = false;
  disabled = true;

  constructor(
    private store: Store,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    public camosPromotionsComponent: CamosPromotionsComponent,
    public AdobeAnalyticsManager: AdobeAnalyticsManager,
    public camos: CamosService,
    public reviewComponent: ReviewComponent,
    public priceService: PriceService,
    public appComponent: AppComponent,
    public requestQuoteSelectedComponent: RequestQuoteSelectedComponent
  ) {}

  public priceTotal: string = '0';
  public structure;
  public netPrice = 0;
  public taxPrice = 0;
  public totalPrice = '';
  public loading = false;
  public leadMode = false;

  ngOnInit() {
    this.currentNaviNode$.subscribe((num) => {
      if (num != undefined) {
        this.review = true;
      }
    });
    this.disabled = true;
    this.nextNavNode$.subscribe((next) => (this.nextNavNode = next));
    this.prevNavNode$.subscribe((prev) => (this.prevNavNode = prev));
    this.widgets$.subscribe((_widgets) => {
      this.widgets = _widgets;
      this.disabled = true;
      let basemodel = false;
      if (this.widgets && this.widgets.length) {
        for (let i = 0; i < this.widgets.length; i++) {
          if (this.widgets[i].widgets && this.widgets[i].widgets.length) {
            for (let j = 0; j < this.widgets[i].widgets.length; j++) {
              if (this.widgets[i].widgets[j].xtIsBaseList) {
                basemodel = true;
              }
            }
          } else {
            if (this.widgets[i].xtIsBaseList) {
              basemodel = true;
            }
          }
        }
      }
      if (this.widgets && this.widgets.length > 0 && basemodel) {
        this.widgets.forEach((widget) => {
          if (widget.values && widget.values.length > 0) {
            widget.values.forEach((value) => {
              if (value.isSelected && value.name) {
                if (this.disabled && document.getElementById('noHover')) {
                  let button = document.getElementById('noHover');
                  button.style.animation = 'pulse 1.5s 2';
                }
                this.disabled = false;
              }
            });
          }
        });
      } else {
        this.disabled = false;
      }
    });
    if (!this.requestQuoteSelectedComponent.promo) {
      setTimeout(() => {
        this.logStructure();
      }, 1000);
    }
  }

  ngAfterViewInit() {
    if (this.appComponent._configuratorSetup.priceDisclaimer)
      this.modalContent.body =
        'Price includes options that can be reviewed in further configuration steps';
    if (this.appComponent.bussinesData)
      this.modalContent.additionalBody =
        this.appComponent.bussinesData?.priceDisclaimerText?._text;
  }

  async loadPrevouislyCountry() {
    await this.camosConfiguratorComponent.setSelectedCountry(
      this.camosConfiguratorComponent.lastCountryValue,
      true,
      true
    );
    this.camosConfiguratorComponent.lastCountryValue = undefined;
  }

  //* PriceDisclaimer Modal

  public showModal: boolean = false;

  private modalContent: any = {
    // title: i18next.t('priceDisclaimer.header'),
    // body: i18next.t('priceDisclaimer.description')
    title: 'Price information'
  };

  public openPriceDisclaimerModal() {
    this.showModal = true;
  }

  closePriceDisclaimerModal = (): void => {
    this.showModal = false;
  };

  //* PriceDisclaimer Modal *END*

  public async logStructure() {
    while (this.store.snapshot().lead.configuratorSetup === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    // this.structure = await this.camos.getReviewStructure(
    //   this.store.snapshot().lead.configuratorSetup.country
    // );
    this.calculateTotalPrice();
  }

  private calculateTotalPrice() {
    this.priceService.setPriceTotal();
  }

  public next() {
    if (!this.disabled) {
      if (this.widgets[0].type == 'ConfigboxList') {
        this.AdobeAnalyticsManager.trackConfiguration();
      }
      // setTimeout(() => {
      //   this.logStructure();
      // }, 1000);
      this.camosConfiguratorComponent.changeOpacity();
      this.store.dispatch(new SelectNavinode(this.nextNavNode.id));
      this.camosConfiguratorComponent.goToTop();
    }
  }

  public prev() {
    this.camosConfiguratorComponent.changeOpacity();
    this.store.dispatch(new SelectNavinode(this.prevNavNode.id));
    this.camosConfiguratorComponent.goToTop();
  }

  public async addToBasket() {
    while (this.store.snapshot().lead.configuratorSetup === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    this.structure = await this.camos.getPartnerStructure(
      this.store.snapshot().lead.configuratorSetup.country
    );
    let resp = await this.camos.saveConfiguration();
    let product = {
      name: this.structure.name,
      values: this.structure.values,
      camosConfigurationID: (resp as any).configUUID
    };

    console.log(product);

    // const leadWindow = window.opener;
    window.postMessage(product, window.location.origin);
  }
}
