import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { CamosService } from '../../services/camos.service';
import { PromotionComponent } from '../promotion.component';
import { PromotionUIState } from 'src/app/store/promotionUi/promotionUi.state';
import { UIModel } from 'src/app/store/ui/ui.model';
import { Observable } from 'rxjs';
import { SetDistributorInfos } from 'src/app/store/distributionpartner/distributionpartner.actions';
import { AdobeAnalyticsManager } from 'src/app/adobe/adobe-analytics-manager';

@Component({
  selector: 'app-camos-promotions',
  templateUrl: './camos-promotions.component.html',
  styleUrls: ['./camos-promotions.component.scss']
})
export class CamosPromotionsComponent implements OnInit {
  public error: boolean = false;
  public requestQuote: boolean = false;
  public leadCreated: boolean = false;
  public partnerPage: boolean = false;
  public promotion: boolean = true;
  public promotionPage: boolean = false;
  public hideProgressBar: boolean = false;

  public opendistributionPartner: boolean = false;
  public distributionPartner: boolean = false;
  public opensaveConfig: boolean = false;
  public formWithoutPartner: boolean = false;
  public promotionId: string = '';
  public promotionPrice: string = '';

  @Select(PromotionUIState.start) promotionUI$: Observable<UIModel>;

  constructor(
    private spinner: NgxSpinnerService,
    public store: Store,
    public camosService: CamosService,
    private promotionsComponent: PromotionComponent,
    private adobeAnalyticsManager: AdobeAnalyticsManager
  ) {}

  ngOnInit(): void {
    this.spinner.show('spinner');

    this.promotionUI$.subscribe((ui) => {
      if (ui) {
        if (ui.category != undefined) {
          this.spinner.hide('spinner');
        }
      }
    });
  }

  ngAfterViewInit() {
    let maxHeight = 0;
    let headlines = document.getElementsByClassName('headline');
    for (let i = 0; i < headlines.length; i++) {
      if (headlines.item(i).clientHeight > maxHeight) {
        maxHeight = headlines.item(i).clientHeight;
      }
    }
    for (let i = 0; i < headlines.length; i++) {
      headlines.item(i).setAttribute('style', 'height: ' + maxHeight + 'px');
    }
  }

  public loadComponent(componentName: string) {
    switch (componentName) {
      case 'error':
        this.error = true;
        this.promotionPage = false;
        break;
      case 'promotion-page':
        this.error = false;
        this.promotionPage = true;
        this.hideProgressBar = true;
        break;
      default:
        break;
    }
    this.goToTop();
  }

  public goToRequestQuote() {
    this.requestQuote = true;
    this.goToTop();
  }

  public goBackRequestQuote() {
    this.requestQuote = false;
    this.goToTop();
  }

  public leadCreation(bool: boolean) {
    this.leadCreated = bool;
  }

  goToPartnerPage() {
    this.partnerPage = true;
    this.requestQuote = true;
    this.promotionPage = false;

    this.distributionPartner = true;
    this.opensaveConfig = false;
    this.opendistributionPartner = false;
    this.goToTop();
  }

  promotionGoToPartnerPage(promotionId: string, promotionPrice: string) {
    this.spinner.show('spinner');
    this.partnerPage = true;
    this.requestQuote = true;
    this.promotionPage = false;

    this.distributionPartner = true;
    this.opensaveConfig = false;
    this.opendistributionPartner = false;
    this.promotionId = promotionId;
    this.promotionPrice = promotionPrice;
    this.adobeAnalyticsManager.trackOpenConfigurator(promotionId);

    this.spinner.hide('spinner');

    this.goToTop();
  }

  async promotionGoToPartnerPageWithoutPartner(
    promotionId: string,
    promotionPrice: string
  ) {
    this.spinner.show('spinner');
    this.partnerPage = true;
    this.requestQuote = true;
    this.promotionPage = false;

    this.distributionPartner = false;
    this.opensaveConfig = false;
    this.opendistributionPartner = true;
    this.promotionId = promotionId;
    this.adobeAnalyticsManager.trackOpenConfigurator(promotionId);
    this.promotionPrice = promotionPrice;

    await this.store.dispatch(
      new SetDistributorInfos({
        distributors: [],
        selectedDistributor: null
      })
    );

    this.spinner.hide('spinner');

    this.goToTop();
  }

  openConfigurationCode() {
    this.partnerPage = false;
    this.error = false;
    this.promotionPage = false;
    this.goToTop();
  }

  showErrorPage() {
    this.partnerPage = false;
    this.requestQuote = false;
    this.error = true;
    this.promotionPage = false;
    this.goToTop();
  }

  goToTop() {
    try {
      let top = (document.getElementsByTagName('app-tap-promotions')[0] as any)
        .offsetTop;
      if (top) {
        // setTimeout(() => window.scrollTo(0, top), 100);
      }
    } catch (error) {
      // this error is no problem
      // console.log(error);
    }
  }
}
