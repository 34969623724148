<div
  class="card-container"
  [ngStyle]="{ height: type == 'homepage' ? '100%' : '90%' }"
>
  <ngx-spinner
    name="spinner32"
    [fullScreen]="false"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, 0.1)"
    color="#009bea"
  ></ngx-spinner>

  <a (click)="onClick(data.id)" style="cursor: pointer">
    <img
      *ngIf="type == 'homepage'"
      src="https://cdn.rohde-schwarz.com/pws/general/icons_1/svg_1/{{
        data.img
      }}"
      width="100"
      height="70"
      alt=""
      srcset=""
    />
    <h3>
      {{ data.title }}
    </h3>
  </a>
  <ul *ngIf="type == 'preconfigured'">
    <li *ngFor="let element of data.list">{{ element }}</li>
  </ul>
  <p *ngIf="type == 'homepage'" class="description-p">
    {{ data.list }}
  </p>
  <div *ngIf="type == 'homepage'" class="button">
    <a class="button-tertiary" (click)="onClick(data.id)">{{
      'general.continue' | i18next
    }}</a>
  </div>
  <div *ngIf="type == 'preconfigured'" class="button">
    <a class="button-tertiary">I want to measure this</a>
  </div>
</div>
<p style="margin: 5px 0px; text-align: center" *ngIf="type == 'preconfigured'">
  Price starting at 45.000 €
</p>
