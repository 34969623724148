import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { CamosService } from 'src/app/services/camos.service';
import { SelectItemState } from 'src/app/store/ui/ui.actions';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';

@Component({
  selector: 'app-cui-comp-multi-combobox',
  templateUrl: './cui-comp-multi-combobox.component.html',
  styleUrls: ['./cui-comp-multi-combobox.component.scss']
})
export class CuiCompMultiComboboxComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  timer: any;
  conflict = false;

  constructor(
    private store: Store,
    public camosConfiguratorComponent: CamosConfiguratorComponent,
    private camosService: CamosService
  ) {}

  ngOnInit(): void {
    this.widget.values.forEach((vals) => {
      if (vals.validity.type === 'Must') {
        this.selectItem(vals.name);
      }
    });
  }

  async selectItem(name: string) {
    // this.store.dispatch(new SelectItem(name, this.widget.id));
    const nextState = await this.camosService.getItemForWidget(
      name,
      this.widget.id,
      1,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(new SelectItemState(nextState, name, this.widget.id));
  }

  addQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    (document.getElementById(id) as any).value++;
    // clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  changeQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    // clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  subQuantity(name: string, quantity: number): void {
    let id = 'select_' + name;

    if ((document.getElementById(id) as any).value !== '0') {
      (document.getElementById(id) as any).value--;
    }
    // clearTimeout(this.timer);
    this.safeQuantity(
      name,
      this.widget.id,
      (document.getElementById(id) as any).value,
      quantity
    );
  }

  async safeQuantity(
    name: string,
    id: number,
    quantity: number,
    previousQuantity: number
  ) {
    // this.store.dispatch(new SelectItem(name, this.widget.id, quantity, previousQuantity));
    const nextState = await this.camosService.getItemForWidget(
      name,
      this.widget.id,
      quantity,
      this.store.snapshot().lead.configuratorSetup.country
    );
    this.store.dispatch(
      new SelectItemState(
        nextState,
        name,
        this.widget.id,
        quantity,
        previousQuantity
      )
    );
  }
}
