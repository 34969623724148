<div class="carousel">
  <div
    class="carousel-inner-container"
    [style.transform]="'translateX(' + transformValue + 'px)'"
    (window:resize)="onResize()"
    #slider
  >
    <div *ngFor="let element of sliderContent" class="carousel-element">
      <app-card-element
        [type]="type"
        [data]="element"
        [myMethodFunc]="myMethodFunc"
      ></app-card-element>
    </div>
  </div>
  <div class="controls-container">
    <button
      (click)="changeSlide('prev')"
      class="carousel_button slick-prev card-arrow-prev slick-arrow"
    ></button>
    <ul class="card-dots">
      <li
        *ngFor="let element of sliderContent; let i = index"
        [ngClass]="activeIndex == i ? 'active' : ''"
        (click)="changeSlideDots(i)"
        class=""
        [attr.data-index]="i"
      >
        <span></span>
      </li>
    </ul>
    <button
      (click)="changeSlide('next')"
      class="carousel_button slick-next card-arrow-next slick-arrow"
    ></button>
  </div>
</div>
