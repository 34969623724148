import { Component } from '@angular/core';

@Component({
  templateUrl: './promotions.component.html'
})
export class PromotionsComponent {
  constructor() {}

  public getCountry(): string {
    let htmls = document.getElementsByTagName('html');
    for (let i = 0; i < htmls.length; i++) {
      if (htmls[i].lang) {
        let country = htmls[i].lang;
        let lang = htmls[i].lang.split('-')[1];
        if (lang) {
            if(isNaN(Number(lang))){
              return lang;
            }
        }
        switch(country){
          case 'ko':
            return 'kr';
          case 'ja':
            return 'jp';
          default:
            return 'com'; 
        }
      }
    }
  }
}
