<!-- <h1>Request a quote for your R&S®RTM3000 configuration</h1> -->
<div class="wrapper">
  <div class="">
    <h2 style="font-size: 20px">{{ 'widgets.requestAQuote' | i18next }}</h2>
    <p>
      {{ 'requestQuote.description' | i18next }}
    </p>
    <a
      _ngcontent-sve-c72=""
      class="button-primary"
      (click)="requestComponent.requestQuote()"
      style="width: 100%"
    >
      {{ 'widgets.requestAQuote' | i18next }}
    </a>
  </div>
  <div class="" style="display: flex; justify-content: center">
    <span class="or-divider">or</span>
  </div>
  <div class="">
    <h2 style="font-size: 20px">{{ 'requestQuote.save' | i18next }}</h2>
    <p>
      {{ 'requestQuote.configIDDescription' | i18next }}
    </p>
    <a
      class="button-secondary"
      style="width: 100%"
      (click)="requestComponent.saveConfiguration()"
      >{{ 'requestQuote.saveButton' | i18next }}</a
    >
  </div>
</div>
