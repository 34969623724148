import { Component, OnInit } from '@angular/core';
import { Widget } from 'src/app/store/ui/ui.model';
import { CuiWidget } from '../cui-widget';

@Component({
  selector: 'app-cui-static-subheadline',
  templateUrl: './cui-static-subheadline.component.html',
  styleUrls: ['./cui-static-subheadline.component.scss']
})
export class CuiStaticSubheadlineComponent implements OnInit, CuiWidget {
  widget: Widget;
  oneMust: boolean;
  promotion: boolean;
  constructor() {}

  ngOnInit(): void {}
}
