<div
  style="
    padding: 5px 50px;
    color: #219bea;
    box-shadow: 0px 1px 19px -4px #737373;
    background: white;
    bottom: 0px;
    position: sticky;
    position: -webkit-sticky;
    margin-top: 25px;
    z-index: 10;
  "
  id="sticky-element"
>
  <div class="main-wrap">
    <div class="flex-item-div" style="max-width: max-content">
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="prev()"
        *ngIf="
          prevNavNode &&
          !camosConfiguratorComponent.review &&
          !camosConfiguratorComponent.requestQuote &&
          !requestQuoteSelectedComponent.backToPromotionPage &&
          !camosConfiguratorComponent.lastCountryValue
        "
        >{{ 'navigation.back' | i18next }} {{ prevNavNode.naming }}</a
      >
      <a
        *ngIf="camosConfiguratorComponent.lastCountryValue"
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="loadPrevouislyCountry()"
      >
        Back to
        {{ camosConfiguratorComponent.lastCountryValue.toUpperCase() }} partners
      </a>
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="camosConfiguratorComponent.goToConfigType()"
        *ngIf="
          !prevNavNode &&
          !camosConfiguratorComponent.review &&
          !camosConfiguratorComponent.requestQuote &&
          !requestQuoteSelectedComponent.backToPromotionPage
        "
        >{{ 'general.backToConfiguration' | i18next }}</a
      >
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="reviewComponent.backToConfiguration()"
        *ngIf="
          camosConfiguratorComponent.review &&
          !camosConfiguratorComponent.requestQuote &&
          !camosConfiguratorComponent.lastCountryValue
        "
        >{{ 'review.back' | i18next }}</a
      >
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="camosConfiguratorComponent.loadComponent('review')"
        *ngIf="
          camosConfiguratorComponent.requestQuote &&
          !requestQuoteSelectedComponent.backToPromotionPage &&
          requestQuoteSelectedComponent.isInternational &&
          !camosConfiguratorComponent.isInternational &&
          requestQuoteSelectedComponent.isLoaded &&
          !requestQuoteSelectedComponent.iNeedHelp &&
          !requestQuoteSelectedComponent.woConfiguration &&
          !camosConfiguratorComponent.basePromotions &&
          !camosConfiguratorComponent.lastCountryValue
        "
        >{{ 'requestQuote.back' | i18next }}</a
      >
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="
          camosConfiguratorComponent.loadComponent('manual-configurator')
        "
        *ngIf="camosConfiguratorComponent.basePromotions"
        >{{ 'general.back' | i18next }}</a
      >
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="requestQuoteSelectedComponent.goToConfigType()"
        *ngIf="
          camosConfiguratorComponent.requestQuote &&
          (requestQuoteSelectedComponent.iNeedHelp ||
            requestQuoteSelectedComponent.woConfiguration) &&
          !requestQuoteSelectedComponent.backToPromotionPage
        "
        >{{ 'general.backToConfiguration' | i18next }}</a
      >
      <a
        _ngcontent-idc-c62=""
        class="button-tertiary ng-star-inserted backTo-btn"
        (click)="requestQuoteSelectedComponent.goToPromotionPage()"
        *ngIf="
          requestQuoteSelectedComponent.backToPromotionPage &&
            !appComponent.sessionError | async
        "
        >{{ 'distributionPartner.backPromo' | i18next }}</a
      >
    </div>
    <div class="flex-item-div price-item">
      <div
        class="price-item-child"
        id="priceBox"
        *ngIf="!requestQuoteSelectedComponent.backToPromotionPage"
      >
        <span class="totalSpan"> {{ 'general.total' | i18next }}: </span>
        <span class="totalValueSpan" *ngIf="!appComponent.isPrice">
          On request
        </span>
        <span class="totalValueSpan" *ngIf="appComponent.isPrice">
          <ngx-spinner
            name="sticky-spinner"
            type="ball-clip-rotate"
            bdColor="rgba(0, 0, 0, 0)"
            color="#009bea"
            [fullScreen]="false"
            size="medium"
          >
          </ngx-spinner>
          {{ camosConfiguratorComponent.numberWithCommas(priceService.price) }}
          <div
            *ngIf="
              (appComponent._configuratorSetup.priceDisclaimer ||
                appComponent.bussinesData?.priceDisclaimerText) &&
              appComponent.isPrice
            "
            class="rsi rsi-info-circle price-info-icon"
            (click)="openPriceDisclaimerModal()"
          ></div>
        </span>
      </div>
      <div
        class="price-item-child"
        id="priceBox"
        *ngIf="requestQuoteSelectedComponent.backToPromotionPage"
      >
        <span class="totalSpan"> Total: </span>
        <span
          class="totalValueSpan"
          *ngIf="!requestQuoteSelectedComponent.promo"
        >
          <ngx-spinner
            name="sticky-spinner"
            type="ball-clip-rotate"
            bdColor="rgba(0, 0, 0, 0)"
            color="#009bea"
            [fullScreen]="false"
            size="medium"
          >
          </ngx-spinner>
          {{
            camosConfiguratorComponent.numberWithCommas(
              camosConfiguratorComponent.promotionPrice
            )
          }}
        </span>
        <span
          class="totalValueSpan"
          *ngIf="requestQuoteSelectedComponent.promo"
        >
          <ngx-spinner
            name="sticky-spinner"
            type="ball-clip-rotate"
            bdColor="rgba(0, 0, 0, 0)"
            color="#009bea"
            [fullScreen]="false"
            size="medium"
          >
          </ngx-spinner>
          {{
            camosConfiguratorComponent.numberWithCommas(
              camosPromotionsComponent.promotionPrice
            )
          }}
        </span>
      </div>
      <!-- <div *ngIf="appComponent._configuratorSetup.priceDisclaimer && appComponent.isPrice" class="price-info" >
          <p>Price includes options that can be reviewed in further configuration steps.</p>
      </div> -->
    </div>
    <div class="flex-item-div">
      <a
        class="button-primary"
        *ngIf="
          nextNavNode &&
          review &&
          !camosConfiguratorComponent.review &&
          !camosConfiguratorComponent.requestQuote
        "
        (click)="next()"
        [id]="disabled ? 'noHover' : 'hover'"
        [style.opacity]="disabled ? 0.3 : 1"
        [style.cursor]="disabled ? 'not-allowed' : 'pointer'"
        >{{ 'navigation.continue' | i18next }} {{ nextNavNode.naming }}</a
      >
      <a
        class="button-primary"
        *ngIf="
          !nextNavNode &&
          review &&
          !camosConfiguratorComponent.review &&
          !camosConfiguratorComponent.requestQuote
        "
        (click)="camosConfiguratorComponent.loadComponent('review')"
        >{{ 'navigation.review' | i18next }}</a
      >
      <a
        class="button-primary"
        *ngIf="
          camosConfiguratorComponent.review &&
          !appComponent._configuratorSetup.partnerId &&
          !camosConfiguratorComponent.requestQuote
        "
        [style]="
          loading
            ? 'pointer-events: none; opacity:0.5; float: right;'
            : 'float: right;'
        "
        (click)="reviewComponent.continueButton()"
        >{{ 'general.continue' | i18next }}</a
      >
      <a
        class="button-primary"
        *ngIf="
          camosConfiguratorComponent.review &&
          appComponent._configuratorSetup.partnerId &&
          !camosConfiguratorComponent.requestQuote
        "
        (click)="addToBasket()"
        >{{ 'partnerStoreIntegration.basket' | i18next }}</a
      >
      <span
        *ngIf="
          camosConfiguratorComponent.requestQuote ||
          requestQuoteSelectedComponent.backToPromotionPage
        "
        class="dist-partner-continue"
        >{{ 'distributionPartner.continue' | i18next }}</span
      >
    </div>
  </div>

  <app-erorr-modal
    *ngIf="showModal"
    [modalContent]="modalContent"
    [closeFunction]="closePriceDisclaimerModal"
  ></app-erorr-modal>
</div>
