import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navi-node-headline',
  templateUrl: './navi-node-headline.component.html',
  styleUrls: ['./navi-node-headline.component.scss']
})
export class NaviNodeHeadlineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log('rendered');
  }
}
