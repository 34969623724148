<div
  [class]="
    camosConfiguratorComponent.leadCreated
      ? 'module tap-progress-bar step-end'
      : camosConfiguratorComponent.requestQuote &&
          !camosConfiguratorComponent.leadCreated
        ? 'module tap-progress-bar step-4'
        : camosConfiguratorComponent.review &&
            !camosConfiguratorComponent.requestQuote
          ? 'module tap-progress-bar step-3'
          : currentNaviNode >= 1 && !camosConfiguratorComponent.review
            ? 'module tap-progress-bar step-2'
            : currentNaviNode == 0
              ? 'module tap-progress-bar step-1'
              : 'module tap-progress-bar step-0'
  "
>
  <div class="wrapper">
    <div class="content" style="display: inline-block; width: 80%">
      <div class="bar"></div>
      <div class="steps">
        <div class="step">
          <div class="circle"><span>1</span></div>
          <div class="label">{{ 'navigation.stepper.one' | i18next }}</div>
        </div>
        <div class="step">
          <div class="circle"><span>2</span></div>
          <div class="label">{{ 'navigation.stepper.two' | i18next }}</div>
        </div>
        <div class="step">
          <div class="circle"><span>3</span></div>
          <div class="label">{{ 'navigation.stepper.three' | i18next }}</div>
        </div>
        <div
          class="step"
          *ngIf="
            !camosConfiguratorComponent.leadCreated &&
            !(
              this.appComponent._configuratorSetup.partnerId &&
              this.appComponent._configuratorSetup.partnerId.length > 0
            )
          "
        >
          <div class="circle"><span>4</span></div>
          <div *ngIf="!leadMode" class="label">
            {{ 'widgets.requestAQuote' | i18next }}
          </div>
          <div *ngIf="leadMode" class="label">
            {{ 'navigation.stepper.four' | i18next }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        !camosConfiguratorComponent.leadCreated &&
        !(
          this.appComponent._configuratorSetup.partnerId &&
          this.appComponent._configuratorSetup.partnerId.length > 0
        )
      "
      class="tooltip"
      style="margin-left: 50px; margin-right: 20px; border-radius: 2em"
    >
      <a
        class="button-secondary tap-badge-btn"
        (click)="goToPartnerPage()"
        [id]="disable ? 'disabled' : 'normal'"
      >
        <div class="rsi rsi-info-circle"></div>
        {{ 'navigation.stepper.iNeedHelp' | i18next }}
      </a>
      <span class="tooltiptext">
        {{ 'navigation.stepper.tooltip' | i18next }}
      </span>
    </div>
  </div>
</div>
