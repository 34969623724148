import { Component } from '@angular/core';
import { PromotionsComponent } from '../promotions.component';
import i18next from 'i18next';

@Component({
  templateUrl: './spectrumAnalyzerSignalGenerators.component.html'
})
export class SpectrumAnalyzerSignalGeneratorsComponent {
  public country = '';
  public language = 'en';

  constructor(private promotionsComponent: PromotionsComponent) {}

  async ngOnInit() {
    this.country = this.promotionsComponent.getCountry();
    let htmls = document.getElementsByTagName('html');
    for (let i = 0; i < htmls.length; i++) {
      if (htmls[i] && htmls[i].getAttribute('xml:lang')) {
        this.language = htmls[i].getAttribute('xml:lang');
      }
    }
    if (i18next.languages.includes(this.language.toLowerCase())) {
      await i18next.changeLanguage(this.language.toLowerCase());
    }
  }
}
