import { Component, Input } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CamosConfiguratorComponent } from 'src/app/camos-configurator/camos-configurator.component';
import { FilteredStructure } from 'src/app/models/review.model';

@Component({
  selector: 'app-review-table-body',
  templateUrl: './review-table-body.component.html',
  styleUrls: ['./review-table-body.component.scss']
})
export class ReviewTableBodyComponent {
  @Input() public item: FilteredStructure;

  public tableHeight = document.getElementsByClassName('accordion-table');
  public AccordionHeight: string = 'auto';

  constructor(
    public appComponent: AppComponent,
    public camosConfiguratorComponent: CamosConfiguratorComponent
  ) {}
}
