import { DistributionPartnerService } from './request-quote-selected/services/distribution-partner.service';
import { SetDistributorInfos } from './store/distributionpartner/distributionpartner.actions';
import { AdobeAnalyticsManager } from './adobe/adobe-analytics-manager';
import { Component, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { StartSession, StopSession } from './store/ui/ui.actions';
import { Start } from './store/promotionUi/promotionUi.actions';
import { ConfiguratorSetup } from './store/lead/lead.model';
import { environment } from 'src/environments/environment';
import { bussinesData } from './models/bussinesData.model';
import { CamosService } from './services/camos.service';
import { SetFields } from './store/lead/lead.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngxs/store';
import i18next from 'i18next';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CamosConfiguratorComponent } from './camos-configurator/camos-configurator.component';
// import { environment } from '../environments/environment';
// import { EnvService } from './env.service';

@Component({
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  configSetupCalled = false;

  @ViewChild(CamosConfiguratorComponent)
  camosChildComponent: CamosConfiguratorComponent;

  //* Price Input
  public isPrice: boolean;
  public bussinesData: bussinesData;
  public sessionError: BehaviorSubject<boolean>;

  @Input('configurator-setup')
  get configuratorSetup() {
    return JSON.stringify(this._configuratorSetup);
  }
  set configuratorSetup(_configuratorSetupString: string) {
    try {
      this.configSetupCalled = true;
      console.log('Input: ' + _configuratorSetupString);
      if (JSON.parse(_configuratorSetupString).displayPrice === false) {
        this.isPrice = false;
      } else {
        JSON.parse(_configuratorSetupString).price !== undefined
          ? (this.isPrice = JSON.parse(_configuratorSetupString)?.price)
          : (this.isPrice = true);
      }
      // console.log("isPrice",this.isPrice);
      this._configuratorSetup.country = JSON.parse(
        _configuratorSetupString
      ).country;
      this._configuratorSetup.m4c_campaignId = JSON.parse(
        _configuratorSetupString
      ).m4c_campaignId;
      this._configuratorSetup.m4c_ioi = JSON.parse(
        _configuratorSetupString
      ).m4c_ioi;
      this._configuratorSetup.m4c_interactionType = JSON.parse(
        _configuratorSetupString
      ).m4c_interactionType;
      this._configuratorSetup.product = JSON.parse(
        _configuratorSetupString
      ).product;
      this._configuratorSetup.priceDisclaimer = JSON.parse(
        _configuratorSetupString
      )?.priceDisclaimer;
      // console.log(this._configuratorSetup.priceDisclaimer);

      if (
        this._configuratorSetup &&
        _configuratorSetupString.includes('msku')
      ) {
        this._configuratorSetup.msku = JSON.parse(
          _configuratorSetupString
        ).msku;
      }

      if (!_configuratorSetupString) {
        throw new Error('_configuratorSetupString not set');
      }
      this._configuratorSetup = JSON.parse(_configuratorSetupString);
      for (const prop in this._configuratorSetup) {
      }
      // this.ngOnInit();
    } catch (err) {
      console.warn(err);
      console.warn('Cannot read configurator Setup Input');
    }
  }
  public _configuratorSetup: ConfiguratorSetup = {
    country: 'de',
    product: 'RTM3000',
    // msku: '1335.8794P22',
    // malias: 'R&S®RTM3002 + RTM-B222',
    m4c_campaignId: '2408',
    m4c_ioi: '325',
    m4c_interactionType: 'RFQ',
    default: true
  };

  private mutationObserver;
  public language = 'en';
  public check: boolean = true;
  public load: boolean = true;

  constructor(
    private store: Store,
    public distributionService: DistributionPartnerService,
    public _cd: ChangeDetectorRef,
    private adobeAnalyticsManager: AdobeAnalyticsManager,
    private camosService: CamosService,
    // private env: EnvService,
    private spinner: NgxSpinnerService
  ) {
    this.camosService.getStage().then((resp) => {
      environment.stage = resp.stage;
      // console.log('set Stage: ', env.stage)
    });
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
    }
    // this.spinner.show("spinner");
  }

  getAllIndexes(baseModel: String) {
    var indexes = [];
    for (let index = 0; index < baseModel.length; index++) {
      if (baseModel[index] === '.') {
        indexes.push(index);
      }
    }
    return indexes;
  }

  replaceK(str: String, index: number) {
    const replacement = 'K';
    const replaced =
      str.substring(0, index) + replacement + str.substring(index + 1);

    return replaced;
  }

  async ngOnInit() {
    this.sessionError = new BehaviorSubject(false);
    // console.log('test dev');
    // console.log(this._configuratorSetup.baseModel);
    // console.log(this.getAllIndexes(this._configuratorSetup.baseModel))
    if (
      this._configuratorSetup.baseModel &&
      !this._configuratorSetup.baseModel.includes('K')
    ) {
      const indexes = this.getAllIndexes(this._configuratorSetup.baseModel);
      this._configuratorSetup.baseModel = this.replaceK(
        this._configuratorSetup.baseModel,
        indexes[indexes.length - 1]
      );
    }
    let htmls = document.getElementsByTagName('html');
    for (let i = 0; i < htmls.length; i++) {
      if (htmls[i] && htmls[i].getAttribute('xml:lang')) {
        this.language = htmls[i].getAttribute('xml:lang');
      }
      this.camosService.language = this.language;
    }
    if (i18next.languages.includes(this.language.toLowerCase())) {
      await i18next.changeLanguage(this.language.toLowerCase());
    }
    /*
    if (this._configuratorSetup.default) {
      let cookie = this.getCookie('configurator-setup');
      this._configuratorSetup = JSON.parse(decodeURIComponent(cookie));
    }
    this.load = false;
    this.spinner.hide("spinner");
    */
    this.load = false;

    if (document.getElementById('tap-configurator')) {
      this.mutationObserver = new MutationObserver(this.callback.bind(this));
      this.mutationObserver.observe(
        document.getElementById('tap-configurator'),
        { attributes: true }
      );
    }
    window.onbeforeunload = (ev) => {
      if (this.camosService.session) {
        firstValueFrom(
          this.store.dispatch(new StopSession(this.camosService.session))
        ).then();
      }
    };
    const leadMode = this.getParam('leadMode');
    const configId = this.getParam('configId');
    const country = this.getParam('country');
    const bundleMode = this.getParam('bundleMode');
    const msku = this.getParam('msku');
    const malias = this.getParam('malias');
    const product = this.getParam('product');
    const ver = this.getParam('ver');
    const verproduct = this.getParam('verproduct');
    const environment = this.getParam('environment');
    const uis = this.getParam('uis');

    if (leadMode) {
      this._configuratorSetup.leadMode = leadMode;
      this._configuratorSetup.product = '';
    }
    if (configId) {
      this._configuratorSetup.configId = configId;
    }
    if (country) {
      this._configuratorSetup.country = country;
    }
    if (bundleMode) {
      this._configuratorSetup.bundleMode = bundleMode;
    }
    if (msku) {
      this._configuratorSetup.msku = msku;
    }
    if (malias) {
      this._configuratorSetup.malias = malias;
    }
    if (product) {
      this._configuratorSetup.product = product;
    }
    if (ver) {
      this._configuratorSetup.ver = ver;
    }
    if (verproduct) {
      this._configuratorSetup.verproduct = verproduct;
    }
    if (environment) {
      this._configuratorSetup.environment = environment;
    }
    if (uis) {
      this._configuratorSetup.uis = uis.split(',');
    }

    if (
      this._configuratorSetup.msku &&
      this._configuratorSetup.msku.length > 0 &&
      this._configuratorSetup.malias &&
      this._configuratorSetup.malias.length > 0
    ) {
      if (!country && !this.configSetupCalled) {
        this._configuratorSetup.country = this.getCountry();
      }
      this.camosService.session = undefined;
      this._configuratorSetup.bundleMode = 'true';
      let base = '';
      for (let i = 0; i < this._configuratorSetup.malias.length; i++) {
        if (isNaN(parseFloat(this._configuratorSetup.malias[i]))) {
          base += this._configuratorSetup.malias[i];
        } else {
          break;
        }
      }
      let models = this._configuratorSetup.malias.split('-');
      this._configuratorSetup.malias = 'R&S®';
      for (let i = 0; i < models.length; i++) {
        if (i != 0) {
          this._configuratorSetup.malias += ' + ' + base + '-';
        }
        this._configuratorSetup.malias += models[i];
      }
    }
    this.check = await this.camosService.check(this._configuratorSetup);
    // console.log(this.check);
    if (this.check) {
      await this.startSession();

      this.bussinesData = await this.camosService.getCountrySpecificData(
        this._configuratorSetup.country
      );
    }
  }

  private getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  public triggerAdobe(msku) {
    this.waitForWindow().then((resp) => {
      this.adobeAnalyticsManager.trackOpenConfigurator(msku);
    });
  }

  public async startSession() {
    try {
      // this.spinner.show('spinner32');
      if (
        this._configuratorSetup.bundleMode &&
        this._configuratorSetup.bundleMode.length > 0 &&
        this._configuratorSetup.country.toUpperCase() != 'COM'
      ) {
        this.store.dispatch(new SetFields(this._configuratorSetup));
        this.distributionService
          .getDistributionPartners(
            [this._configuratorSetup.product.toLowerCase()],
            this._configuratorSetup.country.toLocaleUpperCase()
          )
          .then((partners) => {
            this.store.dispatch(
              new SetDistributorInfos({
                distributors: partners,
                selectedDistributor: null
              })
            );
          })
          .catch((error) => {
            this.camosChildComponent.formWithoutPartner = true;
            setTimeout(() => {
              this.spinner.hide('spinner');
            }, 2000);
          });
        this._cd.detectChanges();
      } else {
        if (
          this._configuratorSetup.product &&
          this._configuratorSetup.country &&
          this._configuratorSetup.ver &&
          this._configuratorSetup.verproduct &&
          this._configuratorSetup.environment &&
          this._configuratorSetup.country.toUpperCase() != 'COM'
        ) {
          this._cd.detectChanges();
          this.adobeAnalyticsManager.sessionAttempt();
          await firstValueFrom(
            this.store.dispatch(
              new StartSession(
                this._configuratorSetup.country.toLocaleUpperCase(),
                this._configuratorSetup.product,
                '',
                this.language,
                this._configuratorSetup.baseModel,
                this._configuratorSetup.ver,
                this._configuratorSetup.verproduct,
                this._configuratorSetup.environment
              )
            )
          );
          this.store.dispatch(new Start());
          const sku = this.store.selectSnapshot(
            (state) =>
              state.ui.widgets[0].values.filter(
                (value) => value.name != null && value.naming != 'Ø'
              )[0]?.name
          );
          this.waitForWindow().then((resp) => {
            this.adobeAnalyticsManager.trackOpenConfigurator(sku);
          });
          this.store.dispatch(new SetFields(this._configuratorSetup));
          this.distributionService
            .getDistributionPartners(
              [this._configuratorSetup.product.toLowerCase()],
              this._configuratorSetup.country.toLocaleUpperCase()
            )
            .then((partners) => {
              this.store.dispatch(
                new SetDistributorInfos({
                  distributors: partners,
                  selectedDistributor: null
                })
              );
            })
            .catch((error) => {
              this.camosChildComponent.formWithoutPartner = true;
              setTimeout(() => {
                this.spinner.hide('spinner');
              }, 2000);
            });
        } else {
          if (this._configuratorSetup.country.toUpperCase() != 'COM') {
            if (
              this._configuratorSetup.product &&
              this._configuratorSetup.product.length > 0
            ) {
              this._cd.detectChanges();
              //let country = 'us'; // for testing
              this.adobeAnalyticsManager.sessionAttempt();
              this.distributionService
                .getDistributionPartners(
                  [this._configuratorSetup.product.toLowerCase()],
                  this._configuratorSetup.country.toLocaleUpperCase()
                )
                .then((partners) => {
                  this.store.dispatch(
                    new SetDistributorInfos({
                      distributors: partners,
                      selectedDistributor: null
                    })
                  );
                  this.spinner.hide('spinner');
                })
                .catch((error) => {
                  this.camosChildComponent.formWithoutPartner = true;
                  setTimeout(() => {
                    this.spinner.hide('spinner');
                  }, 2000);
                });
              this.store.dispatch(new SetFields(this._configuratorSetup));
              await firstValueFrom(
                this.store.dispatch(
                  new StartSession(
                    this._configuratorSetup.country.toLocaleUpperCase(),
                    this._configuratorSetup.product,
                    '',
                    this.language,
                    this._configuratorSetup.baseModel
                  )
                )
              );
              this.store.dispatch(new Start());
              const sku = this.store.selectSnapshot(
                (state) =>
                  state.ui.widgets[0].values.filter(
                    (value) => value.name != null && value.naming != 'Ø'
                  )[0].name
              );
              this.waitForWindow().then((resp) => {
                this.adobeAnalyticsManager.trackOpenConfigurator(sku);
              });
            } else {
              this._cd.detectChanges();
              this.store.dispatch(new SetFields(this._configuratorSetup));
            }
          }
        }
      }
      const getCamosInfo = await this.camosService.getCamosInfo();
      console.log(
        '%cCamos Config Info',
        'color: #007acc; font-weight:bold',
        getCamosInfo
      );
    } catch (error) {
      this.RequestQouteWithoutSession();
      console.log(
        '%cSession start error, camos API error',
        'color: #f01e2c; font-weight:bold',
        error
      );
    }
    this.spinner.hide('spinner32');
  }

  RequestQouteWithoutSession() {
    this.store.dispatch(new SetFields(this._configuratorSetup));
    this.distributionService
      .getDistributionPartners(
        [this._configuratorSetup.product.toLowerCase()],
        this._configuratorSetup.country.toLocaleUpperCase()
      )
      .then((partners) => {
        this.store.dispatch(
          new SetDistributorInfos({
            distributors: partners,
            selectedDistributor: null
          })
        );
        this.sessionError.next(true);
      })
      .catch((error) => {
        this.camosChildComponent.formWithoutPartner = true;
        setTimeout(() => {
          this.spinner.hide('spinner');
        }, 2000);
      });
  }

  public async startInternationalSession() {
    this._cd.detectChanges();
    if (
      this._configuratorSetup.product &&
      this._configuratorSetup.country &&
      this._configuratorSetup.ver &&
      this._configuratorSetup.verproduct &&
      this._configuratorSetup.environment
    ) {
      this.adobeAnalyticsManager.sessionAttempt();
      await firstValueFrom(
        this.store.dispatch(
          new StartSession(
            this._configuratorSetup.country.toLocaleUpperCase(),
            this._configuratorSetup.product,
            '',
            this.language,
            this._configuratorSetup.baseModel,
            this._configuratorSetup.ver,
            this._configuratorSetup.verproduct,
            this._configuratorSetup.environment
          )
        )
      );
    }
    if (
      this._configuratorSetup.product &&
      this._configuratorSetup.product.length > 0
    ) {
      this.adobeAnalyticsManager.sessionAttempt();
      await firstValueFrom(
        this.store.dispatch(
          new StartSession(
            this._configuratorSetup.country.toLocaleUpperCase(),
            this._configuratorSetup.product,
            '',
            this.language,
            this._configuratorSetup.baseModel
          )
        )
      );
    }
    this.store.dispatch(new Start());
    await this.store.dispatch(new SetFields(this._configuratorSetup));
    await this.store.dispatch(
      new SetDistributorInfos({
        distributors: [],
        selectedDistributor: null
      })
    );
  }

  ngAfterViewInit() {
    /*try {
      (document.getElementsByClassName('module module-inred-form highlight')[0] as any).style.display = 'none';
    } catch (error) {
      // not important
    }*/
    this.waitForWindow().then((resp) => {
      console.log('Digital Data is ready! ✅');
      this.adobeAnalyticsManager.init();

      this.adobeAnalyticsManager.getConfigData(this._configuratorSetup);

      if (this._configuratorSetup && this._configuratorSetup.msku) {
        this.triggerAdobe(this._configuratorSetup.msku);
      }
    });
  }

  private callback(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (
        mutation.attributeName === 'class' &&
        !mutation.target.classList.contains('module-web-component')
      ) {
        if (this.camosService.session) {
          firstValueFrom(
            this.store.dispatch(new StopSession(this.camosService.session))
          ).then();
        }
        this.mutationObserver.disconnect();
      }
    });
  }

  async waitForWindow() {
    while (window['digitalData'] === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  public getProduct() {
    return this._configuratorSetup.product;
  }

  private getParam(name) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(
      window.location.href
    );
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }

  private getCountry() {
    let urls = window.location.href.split('/');
    return urls[3];
  }

  private receiveMessage(event: any) {
    // if (event.origin !== "http://localhost:4200")
    //   return;
    // console.log('received event: ', event.data);
    localStorage.setItem('leadOrigin', event.data);
  }
}
