import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CamosConfiguratorComponent } from '../camos-configurator/camos-configurator.component';
import { RevertSelectItem } from '../store/ui/ui.actions';

@Component({
  selector: 'app-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.scss']
})
export class ErrorNotificationComponent implements OnInit {
  constructor(
    public store: Store,
    public camosComponent: CamosConfiguratorComponent
  ) {}

  ngOnInit(): void {}

  public reject() {
    this.store.dispatch(new RevertSelectItem());
    this.camosComponent.selectionError = false;
  }
}
