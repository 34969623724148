import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetDistributorInfos } from './distributionpartner.actions';
import { Distributor, DistributorInfos } from './distributionpartner.model';

@State<DistributorInfos>({
  name: 'distributionpartner'
})
@Injectable()
export class DistributionPartnerState {
  constructor() {}

  ngxsOnInit(ctx: StateContext<DistributorInfos>) {
    console.log('start distributor ngxs');
  }

  @Selector()
  public static distributor(products: DistributorInfos): Distributor {
    return products.selectedDistributor;
  }

  @Selector()
  public static distributors(products: DistributorInfos): Distributor[] {
    return products.distributors;
  }

  @Action(SetDistributorInfos)
  setDistributor(
    ctx: StateContext<DistributorInfos>,
    action: DistributorInfos
  ) {
    const nextState = action;
    console.log('next state: ', action);
    console.log('next state: ', nextState);
    ctx.setState(nextState);
  }
}
